import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { InputFieldComponent } from '../input-field/input-field.component';

type ErrorMapper = (e: ValidationErrors) => string;

const defaultErrorMapper: ErrorMapper = (e: ValidationErrors) => {
  if (e.required) {
    return 'form.required';
  }

  if (e.duplicate) {
    return 'form.duplicate';
  }

  return '';
};

@Component({
  selector: 'date-picker',
  standalone: true,
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    InputFieldComponent,
    TranslateModule,
    IconComponent,
  ],
})
export class DatePickerComponent {
  @Input() public label = '';
  @Input() public id = 'date-picker';
  @Input() public required = false;
  @Input() public placeholder: string = 'MM/DD/YYYY';
  @Input() public control = new FormControl('');
  @Input() public errorMapper: ErrorMapper = defaultErrorMapper;
  @Input() public minDate: Date;

  public getFirstError() {
    if (this.control.errors?.matDatepickerParse) {
      return 'form.date.invalidDate';
    }

    if (this.control.errors) {
      return this.errorMapper(this.control.errors);
    }

    return '';
  }

  public isInvalid() {
    return (this.control.invalid && this.control.touched) || (this.control.invalid && this.control.dirty);
  }

  public onClick(picker: MatDatepicker<unknown>) {
    picker.open();
  }
}
