import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { AuthActions } from '.';

export interface AuthState {
  loading: boolean;
  loggedIn: boolean;
}

export const initialAuthState: AuthState = {
  loading: false,
  loggedIn: false,
};

const reducer: ActionReducer<AuthState> = createReducer(
  initialAuthState,
  on(AuthActions.login, (state) => ({ ...state, loading: true })),
  on(AuthActions.loginSuccess, (state) => ({ ...state, loading: false, loggedIn: true })),
  on(AuthActions.logout, () => ({ ...initialAuthState })),
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}
