export const uniques = <T>(list: T[]) => [...new Set(list)];

export const uniquesBy = <T>(list: T[], key: keyof T) => [...new Map(list.map(item =>
  [item[key], item])).values()];

export function groupBy<T>(list: T[], getKey: (list: T) => string): Record<string, T[]> {
  return list.reduce((groups, item) => {
    const key = getKey(item);

    if (groups[key]) {
      return { ...groups, [key]: [...groups[key], item] };
    } else {
      return { ...groups, [key]: [item] };
    }
  }, {} as Record<string, T[]>);
}
