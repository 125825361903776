import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { andThen, isSuccess, mapFailure, onFailure, onSuccess } from '@core/models/remote-data/remote-data.model';
import { AssetModelStateService } from '@core/services/assetModel/asset-model-state.service';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { FileDropzoneComponent } from '@shared/components/file-dropzone/file-dropzone.component';
import { InputFieldComponent } from '@shared/components/input-field/input-field.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { ToastInlineComponent } from '@shared/components/toast/inline-toast.component';
import { SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { Subscription } from 'rxjs';
import { notNull } from '@core/maybe-model';
import { FileChunkUploadService } from '@core/services/file/file-chunk-upload.service';
import { AssetListStateService } from '../../services/asset-list-state.service';
import { BindFieldFormComponent } from '../import-data/bind-field-form/bind-field-form.component';
import { DataUploadFormComponent } from '../import-data/data-upload-form/data-upload-form.component';
import { ImportCompletedWarningsComponent } from '../import-data/import-completed-warnings/import-completed-warnings.component';
import { ImportPcmsValidationState, PCMSValidationState, ValidationState } from './import-pcms-validation-state';
import { ErrorCode, ImportPcmsService, PCMSDataValidationError } from './services/import-pcms.service';

export type ImportPcmsForm = {
  assetModelName: FormControl<string | null>;
  equipmentFile: FormControl<File | null>;
  cmlFile: FormControl<File | null>;
  circuitsFile: FormControl<File | null>;
  attachmentsZip: FormControl<File | null>;
};

@Component({
  selector: 'import-pcms',
  standalone: true,
  imports: [
    CommonModule,
    BindFieldFormComponent,
    DataUploadFormComponent,
    ImportCompletedWarningsComponent,
    ModalComponent,
    SpinnerComponent,
    SuccessRemoteDataPipe,
    TranslateModule,
    FileDropzoneComponent,
    InputFieldComponent,
    ToastInlineComponent,
    MatTooltipModule,
  ],
  templateUrl: './import-pcms.component.html',
  styleUrls: ['./import-pcms.component.scss'],
})
export class ImportPcmsComponent implements OnDestroy {
  @Input() public onClose: () => void = () => {};

  public importPcmsForm: FormGroup<ImportPcmsForm>;
  public hasModelDefined: boolean = false;
  public isUploading: boolean = false;
  public infoToast = Toast.info('pcmsImport.warningMessage');

  private modelStateSubscription: Subscription;

  public importPcmsValidationState: ImportPcmsValidationState = PCMSValidationState.initialState;

  constructor(
    private importPcmsService: ImportPcmsService,
    private importFileService: FileChunkUploadService,
    private modelStateService: AssetModelStateService,
    private assetStateService: AssetListStateService,
    private toastService: ToastService,
  ) {
    this.modelStateSubscription = this.modelStateService.state$.subscribe(model => {
      this.hasModelDefined = !!isSuccess(model);
      this.importPcmsForm = new FormGroup<ImportPcmsForm>({
        assetModelName: this.hasModelDefined ? new FormControl('') : new FormControl('', [Validators.required]),
        equipmentFile: new FormControl(null),
        cmlFile: new FormControl(null),
        circuitsFile: new FormControl(null),
        attachmentsZip: new FormControl(null),
      }, { validators: this.AtLeastOneFileValidation() });
    });
  }

  public ngOnDestroy() {
    this.modelStateSubscription.unsubscribe();
  }

  public get equipmentFileControl() {
    return this.importPcmsForm.get('equipmentFile') as FormControl<File | null>;
  }

  public get cmlFileControl() {
    return this.importPcmsForm.get('cmlFile') as FormControl<File | null>;
  }

  public get circuitsFileControl() {
    return this.importPcmsForm.get('circuitsFile') as FormControl<File | null>;
  }

  public get attachmentsZipControl() {
    return this.importPcmsForm.get('attachmentsZip') as FormControl<File | null>;
  }

  public get assetModelName() {
    return this.importPcmsForm.get('assetModelName') as FormControl;
  }

  public unprocessedTooltipEnable(validationState: ValidationState, formControl: FormControl<File | null>) {
    return validationState.state === 'unprocessed' && formControl.value != null;
  }

  public onEquipmentFileChange = (file: File | null) => {
    this.importPcmsForm.patchValue({ ...this.importPcmsForm.value, equipmentFile: file });
    this.importPcmsValidationState = PCMSValidationState.initialState;
  };

  public onCmlFileChange = (file: File | null) => {
    this.importPcmsForm.patchValue({ ...this.importPcmsForm.value, cmlFile: file });
    this.importPcmsValidationState = PCMSValidationState.initialState;
  };

  public onAttachmentPick(file: File | null) {
    this.importPcmsForm.patchValue({ ...this.importPcmsForm.value, attachmentsZip: file });
    this.importPcmsValidationState = PCMSValidationState.initialState;
  }

  public onCircuitFileChange(file: File|null) {
    this.importPcmsForm.patchValue({ ...this.importPcmsForm.value, circuitsFile: file });
    this.importPcmsValidationState = PCMSValidationState.initialState;
  }

  public ErrorCodeToText(errorCode?: ErrorCode) {
    return `pcmsImport.errors.${errorCode ?? 'unknown'}`;
  }

  public onImport = () => {
    this.isUploading = true;
    const payload = {
      equipmentsCSV: this.equipmentFileControl.value,
      cmlsCSV: this.cmlFileControl.value,
      circuitsCSV: this.circuitsFileControl.value,
      attachmentsZip: this.attachmentsZipControl.value?.name,
      modelName: this.assetModelName.value,
    };

    this.importFileService.uploadZipFile(this.attachmentsZipControl.value)
      .pipe(
        mapFailure((_) => ({ errorCode: ErrorCode.Unknown })),
        andThen(() => this.importPcmsService.importData(payload)),
        onFailure((error) => this.onError(error)),
        onSuccess(() => this.onSuccess()),
      ).subscribe(() => {
        this.isUploading = false;
      });
  };

  private onSuccess = () => {
    this.toastService.add(Toast.success('pcmsImport.success'));
    this.assetStateService.fetchAndUpdate();
    this.modelStateService.fetchAndUpdate();
    this.onClose();
  };

  private onError(error: PCMSDataValidationError) {
    if (error.file) {
      this.importPcmsValidationState = PCMSValidationState.markAsInvalid(error.file, error.errorCode);
    } else {
      this.toastService.add(Toast.error('pcmsImport.errors.Unknown'));
    }
  }

  private AtLeastOneFileValidation() {
    return (form: FormGroup) => {
      const IsCsvPresentWhenNoModel = (!this.hasModelDefined && form.get('equipmentFile')?.value === null);
      const AtLeastOneFile = [
        form.get('equipmentFile')?.value,
        form.get('cmlFile')?.value,
        form.get('circuitsFile')?.value,
        form.get('attachmentsZip')?.value,
      ].filter(notNull).length === 0;

      const error = IsCsvPresentWhenNoModel || AtLeastOneFile
        ? { invalid: true }
        : null;

      form.setErrors(error);

      return error;
    };
  }
}
