<div class="filters-container">
    <input type="search" [(ngModel)]="searchValue" (ngModelChange)="onSearchChange($event)" class="search-input" [placeholder]="'assetFilters.searchPlaceholder' | translate"/>
    <div>

        <button class="btn-secondary-no-border icon-button" (click)="toggleFilterModal()">
            <icon iconName="add"></icon>
            {{ "assetFilters.addFilters" | translate }}
        </button>
    
        <modal [inline]="true" [customSize]="{height: 30, width: 20}" [onClose]="onCancel.bind(this)"  *ngIf="addFieldFiltersModal">
            <label header>{{ "assetFilters.filterByKeyword" | translate}}</label>
            <div content class="content">
                <select-field *ngIf="options$ | async as options" 
                [control]="selectControlField" 
                [options]="options"
                [placeholder]="'assetFilters.selectField'" 
                />
                <input type="search"
                 [(ngModel)]="filterInputValue" 
                 (ngModelChange)="onFilterChange($event)" 
                 class="search-input filter-keyword-input"
                 [placeholder]="'assetFilters.filterPlaceholder' | translate"
                 (keydown)="onKeyDown($event)"
                  />
                  <div class="filter-keywords">
                    <div *ngFor="let keyword of filterKeywords" class="tag">
                        <span>{{keyword}}</span>
                        <icon iconName="close" size="xsmall" (click)="removeKeyword(keyword)"/>
                    </div>
                  </div>
            </div>

            <div footer class="theme-footer footer">
                <button  class="btn-text-only" (click)="onCancel()"> {{ "common.cancel" | translate }}</button>
                <button class="btn-primary" (click)="onSave()" [disabled]="filterKeywords.length === 0">{{ "assetFilters.filterButton" | translate }}</button>
            </div>
        </modal>
    </div>
    <div *ngFor="let field of fieldsFilters$ | async" class="tag">
        <span>{{field.fieldName}} ({{field.keywords.length}})</span>
        <icon iconName="close" size="xsmall" (click)="removeFieldFilter(field)" id="remove-filter"/>
    </div>
    <button class="btn-text-only clear-filter-btn" (click)="clearAllFieldsFilter()" *ngIf="hasActiveFieldFilters(fieldsFilters$) | async">
        {{ "assetFilters.clearFilters" | translate }}
    </button>
</div>

