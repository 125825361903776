import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { Toast } from '@core/services/toast/toast-service.service';

@Component({
  selector: 'inline-toast',
  standalone: true,
  template: `
    <div  class="toast" [ngClass]="toast.type">
      <icon [iconName]="iconName(toast)" size="medium"></icon>
      <span> {{ toast.message | translate }} </span>
    </div>
  `,
  styleUrls: ['./toast.component.scss'],
  imports: [CommonModule, IconComponent, TranslateModule],
})
export class ToastInlineComponent {
  @Input() public toast: Toast;

  public iconName(toast: Toast) {
    switch (toast.type) {
      case 'success':
        return 'check_circle';
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      default:
        return 'help';
    }
  }
}
