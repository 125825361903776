import { AssetForm } from '../../../asset-form/asset-form.model';
import { AssetModel } from '@core/models/assetModel/asset-model.model';
import { AssetCreation } from '@core/models/asset/asset.model';

export const toCreationRequest = (form: AssetForm, model: AssetModel): AssetCreation => {
  const equipmentId = form.find(e => e.controlName === model.equipmentId)?.control.value;
  const equipmentType = form.find(e => e.controlName === model.equipmentType)?.control.value;
  const unitName = form.find(e => e.controlName === model.unitName)?.control.value;

  return {
    assetModelId: model.id,
    equipmentId,
    equipmentType,
    unitName,
    customFields: AssetForm.toCustomField(form),
  };
};
