import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  private appInsights: ApplicationInsights;

  constructor() {}

  public init() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        disableTelemetry: environment.hmr,
      },
    });
    this.appInsights.loadAppInsights();
  };

  public logEvent(name: string, properties?: { [key: string]: unknown }) {
    this.appInsights.trackEvent({ name }, properties);
  }

  public logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception, severityLevel });
  }
}
