import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { ConfigService } from '@shared/services';
import { mapSuccess, RemoteData } from '@core/models/remote-data/remote-data.model';
import { Observable } from 'rxjs';
import { VtInspectionResult, VtInspectionResultResponse } from '../models/vt-inspection-result.model';
import { UttInspectionResult } from '../models/utt-inspection-result.model';
import { UttInspectionResultResponse } from './inspection-result.response';
import { toUttInspectionResult, toVtResultDomain } from './inspection-result.mapper';

@Injectable({
  providedIn: 'root',
})
export class InspectionResultService {
  private inspectionResultEndpoint =
    (inspectionTaskId: string, inspectionType: string) => `api/v1/inspections/results/${inspectionType}/${inspectionTaskId}`;

  constructor(private httpClient: HttpService, private config: ConfigService) { }

  public getVtInspectionResult(inspectionTaskId: string): Observable<RemoteData<VtInspectionResult>> {
    return this.httpClient.get<VtInspectionResultResponse>(
      { endpoint: `${this.config.apiUrl}/${this.inspectionResultEndpoint(inspectionTaskId, 'visual')}` })
      .pipe(mapSuccess(toVtResultDomain));
  }

  public getUttInspectionResult(inspectionTaskId: string): Observable<RemoteData<UttInspectionResult>> {
    return this.httpClient.get<UttInspectionResultResponse>(
      { endpoint: `${this.config.apiUrl}/${this.inspectionResultEndpoint(inspectionTaskId, 'utt')}` })
      .pipe(mapSuccess(toUttInspectionResult));
  }
}
