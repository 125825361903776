<div class="page-header" >
  <span *ngIf="equipmentId$ | async as equipmentId" class="header-text">
    <span class="breadcrumb active" (click)="this.goToAsset()">{{ equipmentId }}</span>
    <icon iconName="navigate_next" size="medium"></icon>
    <breadcrumb-select
    [options]="(sectionOptions | async) ?? []"
    [control]="sectionControl"
    [canBeEmpty]="true"
    [placeholder]="'assetPage.sections.placeholder'"
    (optionSelected)="this.sectionSelected.emit($event)"
    />
  </span>

</div>
<div class="page-navigation">
  <span *ngFor="let page of pages" class="navigation-link" [class.active]="isActive(page)" (click)="navigateTo(page)">
    {{ "assetPage." + page | translate }}
  </span>
</div>
