import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { InputFieldComponent } from '@shared/components/input-field/input-field.component';
import { FailureRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { Toast } from '@core/services/toast/toast-service.service';
import { ToastInlineComponent } from '@shared/components/toast/inline-toast.component';
import { UploadForm } from '../import-data.component';
import { FileDropzoneComponent } from '@shared/components/file-dropzone/file-dropzone.component';

@Component({
  selector: 'data-upload-form',
  standalone: true,
  templateUrl: './data-upload-form.component.html',
  styleUrls: ['./data-upload-form.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconComponent,
    InputFieldComponent,
    TranslateModule,
    FailureRemoteDataPipe,
    ToastInlineComponent, FileDropzoneComponent,
  ],
})
export class DataUploadFormComponent {
  @Input() public form: FormGroup<UploadForm>;

  @Input() public hasModelDefined = false;
  @Input() public toast: Toast | undefined;

  public infoToast = Toast.info('assetImport.warningMessage');

  public get assetModelName() {
    return this.form.get('assetModelName') as FormControl;
  }

  public get csvControl() {
    return this.form.get('file') as FormControl;
  }

  public get attachmentControl() {
    return this.form.get('attachment') as FormControl;
  }

  public get fileName() {
    if (!!this.csvControl.value) {
      return ((this.csvControl.value) as File).name;
    }
  }

  public onFilePick(file: File | null) {
    this.csvControl.patchValue(file);

    this.form.markAsDirty();
  }

  public onAttachmentPick(zip: File | null) {
    this.attachmentControl.patchValue(zip);
  }
}
