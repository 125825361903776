import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { onFailure, onSuccess } from '../core/models/remote-data/remote-data.model';
import { Toast, ToastService } from '../core/services/toast/toast-service.service';
import { InputFieldComponent } from '../shared/components/input-field/input-field.component';
import { ModalComponent } from '../shared/components/modal/modal.component';
import { SpinnerComponent } from '../shared/components/spinner/spinner.component';
import { ExportPCMSDataService } from './services/export-pcms-data.service';

@Component({
  selector: 'export-data',
  standalone: true,
  templateUrl: './export-pcms-data.component.html',
  styleUrls: ['./export-pcms-data.component.scss'],
  imports: [CommonModule, ModalComponent, InputFieldComponent, TranslateModule, SpinnerComponent],
})
export class ExportPCMSDataComponent {
  @Input() public onClose: () => void;
  public isLoading = false;
  public fileNameControl = new FormControl('', [Validators.required]);

  constructor(private exportDataService: ExportPCMSDataService, private toastService: ToastService) {}

  public export() {
    this.isLoading = true;
    this.exportDataService
      .exportData(this.fileNameControl.value ?? '')
      .pipe(
        onSuccess(() => {
          this.onClose();
        }),
        onFailure((e) => {
          this.toastService.add(Toast.error(`export.error.${e}`));
          this.onClose();
        }),
      )
      .subscribe();
  }
}
