import { InspectionType } from '../../models/inspections/inspection.model';

export type InspectionCreationRequest = {
  name: string,
  startDate: Date,
  endDate: Date
  inspectionTasks: InspectionTaskRequest[]
};

type InspectionRequestTypes = 'Utt' | 'Vt';

export type InspectionTaskRequest = {
  assetId: string,
  sectionId: string;
  inspectionTypes: InspectionType[]
};

export const Utt: InspectionRequestTypes = 'Utt';
export const Vt: InspectionRequestTypes = 'Vt';
