import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { andThen, Failure, Loading, RemoteData, Success } from '@core/models/remote-data/remote-data.model';
import { FileDownloadService } from '@core/services/file/file-download.service';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyStateMessageComponent } from '@shared/components/empty-state-message/empty-state-message.component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { IPdfDocumentLoad, PDFViewerComponent } from '@shared/components/pdf-viewer/pdf-viewer.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { FailureRemoteDataPipe, LoadingRemoteDataPipe, SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { distinctUntilChanged, map, Subscription, tap } from 'rxjs';
import { AttachmentsStateService } from '../services/attachments-state.service';

@Component({
  selector: 'drawing',
  templateUrl: './drawing.component.html',
  styleUrls: ['./drawing.component.scss'],
  standalone: true,
  imports: [
    PDFViewerComponent,
    CommonModule,
    SuccessRemoteDataPipe,
    LoadingRemoteDataPipe,
    SpinnerComponent,
    TranslateModule,
    FailureRemoteDataPipe,
    EmptyStateMessageComponent,
    PaginationComponent,
  ],
})
export class DrawingComponent implements OnDestroy, OnInit {
  public $pdfSrc: RemoteData<string> = Loading();

  public currentPage = 1;
  public pageCount = 1;

  private subscription: Subscription;

  constructor(private fileDownloadService: FileDownloadService, public attachmentStateService: AttachmentsStateService) {}

  public ngOnInit(): void {
    this.subscription = this.attachmentStateService.drawing$
      .pipe(
        tap(() => this.resetPaginationState()),
        distinctUntilChanged((prev, curr) => prev?.filePath === curr?.filePath),
        map(a => this.getDrawingPath(a?.filePath)),
        andThen(filePath => this.fileDownloadService.downloadFile(filePath ?? '')),
      )
      .subscribe(attachments => {
        this.$pdfSrc = attachments;
      });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onDocumentLoad = (value: IPdfDocumentLoad) => {
    this.pageCount = value.pageCount;
    this.currentPage = 1;
  };

  public onPageChange(pageIncrement: number) {
    this.currentPage = this.currentPage + pageIncrement;
  }

  private resetPaginationState() {
    this.currentPage = 1;
    this.pageCount = 1;
  }

  private getDrawingPath(filePath: string | undefined) {
    if (filePath === undefined) {
      return Failure('unknown');
    }

    return this.isPdfFile(filePath) ? Success(filePath): Failure('notAPdfFile');
  }

  private isPdfFile(fileName: string | undefined): boolean {
    return fileName?.includes('.pdf') ?? false;
  }
}
