import { Injectable } from '@angular/core';
import { Asset, AssetDetail } from '@core/models/asset/asset.model';
import { andThen, Loading, mapSuccess, RemoteData } from '@core/models/remote-data/remote-data.model';
import { AssetModelService } from '@core/services/assetModel/asset-model.service';
import { AssetsService } from '@core/services/assets/assets.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssetStateService {
  private assetSubject$ = new BehaviorSubject<RemoteData<Asset>>(Loading());
  public asset$ = this.assetSubject$.asObservable();

  constructor(private assetService: AssetsService, private assetModelService: AssetModelService) {}

  public fetchAndUpdate(id: string) {
    this.assetService
      .getById(id)
      .pipe(andThen((assetDetails) => this.mergeWithAssetModel(assetDetails)))
      .subscribe(data => {
        this.assetSubject$.next(data);
      });
  }

  private mergeWithAssetModel(assetDetails: AssetDetail): Observable<RemoteData<Asset>> {
    return this.assetModelService
      .getAssetModel()
      .pipe(mapSuccess(model => ({ model, ...assetDetails })));
  }
}
