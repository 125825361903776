import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { andThen, isSuccess, Loading, mapSuccess, onFailure, onSuccess, RemoteData, Success } from '@core/models/remote-data/remote-data.model';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationModalService } from '@shared/components/confirmation-modal/confirmation-modal.service';
import { EmptyStateMessageComponent } from '@shared/components/empty-state-message/empty-state-message.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { LoadingRemoteDataPipe, SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { startWith } from 'rxjs';
import { Action } from '../../../shared/components/action-menu/action.model';
import { InlineInputComponent, InlineInputState } from '../../../shared/components/inline-input/inline-input.component';
import { CMLComponent } from './components/cml/cml.component';
import { CML } from './models/cml.model';
import { CMLCreationError, CmlsService } from './services/cmls.service';

@Component({
  selector: 'ultrasonic-thickness',
  standalone: true,
  templateUrl: './ultrasonic-thickness.component.html',
  styleUrls: ['./ultrasonic-thickness.component.scss'],
  imports: [CommonModule,
    TranslateModule,
    IconComponent,
    SuccessRemoteDataPipe,
    SpinnerComponent,
    LoadingRemoteDataPipe,
    CMLComponent, EmptyStateMessageComponent, InlineInputComponent],
})
export class UltrasonicThicknessComponent implements OnInit, OnChanges {
  @Input() public assetId: string;
  @Input() public sectionId: string;

  public cmlsExpanded: { [index: number]: boolean } = {};
  public addCmlControl = new FormArray<FormControl<string | null>>([]);

  public inputStates: InlineInputState[] = [];
  public cmls: RemoteData<CML[]> = Loading();

  public actions: Action[] = [];

  @ViewChildren(CMLComponent) public cmlsCollapsibleComponents: QueryList<CMLComponent>;
  @ViewChild('importFTP') public importFTPInput: ElementRef;

  constructor(private cmlsService: CmlsService,
    private toastService: ToastService,
    private confirmationDialog: ConfirmationModalService,
  ) {}

  public ngOnChanges(_: SimpleChanges): void {
    this.getCMLs().subscribe(cmls => this.cmls = cmls);
  }

  public ngOnInit(): void {
    this.getCMLs().subscribe(cmls => this.cmls = cmls);
  }

  private getCMLs() {
    return this.cmlsService
      .getCMLs(this.assetId, this.sectionId)
      .pipe(startWith(Loading()))
      .pipe(onFailure(_ => this.toastService.add(Toast.error('ultrasonic.error.getCMLs'))))
      .pipe(mapSuccess(data => data.reverse()));
  }

  public onAddCML() {
    this.addCmlControl.push(new FormControl(''));
    this.inputStates = [...this.inputStates, 'idle'];
  }

  public onConfirmCML(value: string, index: number) {
    const cml = {
      location: value,
      inspectionPoints: [],
    };

    this.inputStates = this.inputStates.map((v, i) => i === index ? 'loading': v);

    this.cmlsService.createCML(this.assetId, this.sectionId, cml)
      .pipe(
        onFailure(e => this.handleCreationError(e, index)),
        andThen(_ => this.getCMLs()),
        onSuccess(cmls => {
          this.cmls = Success(cmls);
          this.onRemove(index);
        }),
      ).subscribe(() => {});
  }

  public isEmpty() {
    return isSuccess(this.cmls) && this.cmls.data.length === 0 && this.addCmlControl.length === 0;
  }

  public handleCreationError(e: CMLCreationError, index: number) {
    if (e === 'duplicateCML') {
      this.inputStates = this.inputStates.map((v, i) => i === index ? 'error': v);
    } else {
      this.toastService.add(Toast.error('ultrasonic.error.createCMLs'));
    }
  }

  public confirmDeleteCML(cmlId: string, location: string) {
    this.confirmationDialog.open({
      title: 'ultrasonic.deleteCMLConfirmation.title',
      message: 'ultrasonic.deleteCMLConfirmation.message',
      messageParameters: { cml: location },
      onConfirm: () => this.deleteCML(cmlId),
    });
  }

  public onRemove(index: number) {
    this.addCmlControl.removeAt(index);
    this.inputStates = this.inputStates.filter((_, i) => i !== index);
  }

  private deleteCML(cmlId: string) {
    this.confirmationDialog.loading();
    this.cmlsService.deleteCML(this.assetId, this.sectionId, cmlId)
      .pipe(andThen(() => this.getCMLs()))
      .pipe(onFailure(() => this.toastService.add(Toast.error('ultrasonic.error.deleteCML'))))
      .pipe(onSuccess(() => this.toastService.add(Toast.success('ultrasonic.deleteCMLSuccess'))))
      .subscribe((cmls) => {
        this.cmls = cmls;
        this.confirmationDialog.close();
      });
  }

  public expandAll() {
    this.cmlsCollapsibleComponents.forEach(c => c.cmlCollapsibleContainer.open());
  }

  public collapseAll() {
    this.cmlsCollapsibleComponents.forEach(c => c.cmlCollapsibleContainer.close());
  }

  public onExpandedOrCollapse(location: string, expanded: boolean) {
    this.cmlsExpanded[location] = expanded;
  }

  public isExpanded(location: string) {
    return !! this.cmlsExpanded[location];
  }

  public notEmpty() {
    return isSuccess(this.cmls) && this.cmls.data.length > 0;
  }
}
