import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'icon',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() public iconName: IconName;
  @Input() public size: IconSize = 'xsmall';

  public get classes() {
    return [this.size];
  }
}

type IconSize = 'xsmall'| 'small' | 'medium' | 'large'| 'xlarge' ;

export type IconName = 'delete'
| 'error'
| 'description'
| 'add'
| 'edit'
| 'download'
| 'help'
|'keyboard_double_arrow_left'
| 'upload_file'
| 'close'
| 'keyboard_double_arrow_right'
| 'warning'
| 'check_circle'
| 'attach_file'
| 'keyboard_double_arrow_down'
| 'more_vert'
| 'expand_more'
| 'expand_less'
| 'navigate_next'
| 'chevron_left'
| 'chevron_right'
| 'check'
| 'event'
|'info_outlined'
| 'storage'
| 'timeline'
| 'upload';
