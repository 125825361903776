<div class="actions">
    <button [disabled]="currentPage === 1" (click)="pageChange.emit(-1)" class="round-icon-button" id="btn-previous">
      <icon iconName="chevron_left" size="small"/>
    </button>
    <div>
      {{'drawing.pagination.page' | translate}}
      <strong>{{currentPage}}</strong>
      {{'drawing.pagination.of' | translate}}
      <strong>{{pageCount}}</strong>
    </div>
    <button [disabled]="currentPage === pageCount" (click)="pageChange.emit(1)" class="round-icon-button" id="btn-next">
      <icon iconName="chevron_right" size="small" />
    </button>
</div>