import { Pipe, PipeTransform } from '@angular/core';
import { DateExt, DateFormat } from '@core/date';

@Pipe({
  name: 'formatDateString',
  standalone: true,
})
export class FormatDateStringPipe implements PipeTransform {
  public transform(value: string, format?: DateFormat): string {
    return DateExt.formatUTCDateString(value, format);
  }
}

@Pipe({
  name: 'formatDate',
  standalone: true,
})
export class FormatDatePipe implements PipeTransform {
  public transform(value?: Date, format?: DateFormat): string {
    return value ? DateExt.formatDate(value, format) : '';
  }
}
