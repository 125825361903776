import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';

export interface FileProgress<T = unknown> {
  percentDone: number,
  body?: T,
  state: 'IN_PROGRESS' | 'COMPLETED' | 'FAILURE'
}

const IN_PROGRESS = (percentDone: number): FileProgress => ({ state: 'IN_PROGRESS', percentDone });
const COMPLETED = <T>(body?: T): FileProgress => ({ state: 'COMPLETED', percentDone: 100, body });
const FAILURE: FileProgress = { state: 'FAILURE', percentDone: 0 };

const isHttpResponse = <T>(event: HttpEvent<T>): event is HttpResponse<T> => event.type === HttpEventType.Response;

const isHttpProgressEvent = (event: HttpEvent<unknown>): event is HttpProgressEvent =>
  event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;

const calculateFileProgress = <T>(upload: FileProgress, event: HttpEvent<T>): FileProgress => {
  if (isHttpProgressEvent(event)) {
    return IN_PROGRESS(event.total ? Math.round((100 * event.loaded) / event.total): upload.percentDone);
  }

  if (isHttpResponse(event)) {
    return COMPLETED(event.body);
  }

  return upload;
};

export const FileProgress = {
  IN_PROGRESS,
  COMPLETED,
  FAILURE,
  calculateFileProgress,
};
