import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Filters } from '@core/models/filters.type';
import { Ordering } from '@core/models/ordering.type';
import { Paginated, PaginatedResponse, Pagination } from '@core/models/pagination.type';
import { ClientError, HttpService } from '@core/services/http/http.service';
import { Loading, mapFailure, mapSuccess, onFailure, RemoteData } from '@core/models/remote-data/remote-data.model';
import { ConfigService } from '@shared/services';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { Observable, startWith } from 'rxjs';
import { AssetMapper } from '../../mappers/assets.mapper';
import { AssetResponse } from '../../models/asset/assets.response';
import { AssetCreation, AssetDetail, AssetEdit, AssetListItem } from '@core/models/asset/asset.model';

export type AssetCreationError = 'DuplicateAsset' | 'Unknown';

type GetAssetsBody = {
  pagination: {
    limit?: string;
    offset?: string;
  },
  sorting: {
    sort?: string,
    order?: string
  },
  filters: {
    fieldFilters?: { fieldName: string, values: string[] }[];
    query?: string,
  }
};

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private assetsEndpoint = 'api/v1/assets';

  constructor(private httpClient: HttpService,
    private config: ConfigService, private toastService: ToastService, private router: Router) {}

  public getList(pagination?: Pagination, filters?: Filters, ordering?: Ordering): Observable<RemoteData<Paginated<AssetListItem>>> {
    const endpoint =`${this.config.apiUrl}/${this.assetsEndpoint}`;

    return this.httpClient.post<PaginatedResponse<AssetResponse>, GetAssetsBody>({
      endpoint,
      body: {
        pagination: {
          limit: pagination?.limit.toString() ?? '',
          offset: pagination?.offset.toString() ?? '',
        },
        filters: {
          query: filters?.query ?? '',
          fieldFilters: filters?.fields.map(filter => ({ fieldName: filter.fieldName, values: filter.keywords })),
        },
        sorting: {
          sort: ordering?.sort ?? '',
          order: ordering?.order ?? '',
        },
      },
    })
      .pipe(
        mapSuccess((data) => (Paginated(data.data.map(AssetMapper.toAsset), data.pagination.total, data.pagination.offset))),
        onFailure(_ => this.toastService.add(Toast.error('assetsList.error'))),
      );
  }

  public createAsset(asset: AssetCreation): Observable<RemoteData<unknown, AssetCreationError>> {
    return this.httpClient
      .post({ endpoint: `${this.config.apiUrl}/${this.assetsEndpoint}/create`, body: asset })
      .pipe(mapFailure((e) => {
        if (e.errorType === 'DuplicateException') {
          return 'DuplicateAsset';
        }

        return 'Unknown';
      }));
  }

  public getById(id: string): Observable<RemoteData<AssetDetail, ClientError >> {
    return this.httpClient
      .get<AssetResponse>({ endpoint: `${this.config.apiUrl}/${this.assetsEndpoint}/${id}` })
      .pipe(mapSuccess(AssetMapper.toAssetDetails))
      .pipe(onFailure(_ => this.router.navigate(['details'])));
  }

  public edit(id: string, editAsset: AssetEdit): Observable<RemoteData> {
    return this.httpClient
      .put({ endpoint: `${this.config.apiUrl}/${this.assetsEndpoint}/${id}`, body: editAsset })
      .pipe(startWith(Loading()));
  }
}
