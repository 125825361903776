<div class="side-panel right" [@slideIn] [ngStyle]="{ width }">
    <div class="panel-header">
        <div (click)="close()" class="close">
          <icon iconName='keyboard_double_arrow_right'/>
        </div>
        <ng-content select="[header]"></ng-content>
    </div>
    <ng-content select="[content]"></ng-content>

    <ng-content select="[footer]"></ng-content>
</div>
