import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { PageViewport, PDFPageProxy } from 'pdfjs-dist';

const PDFJS = require('pdfjs-dist/build/pdf');

export interface IPdfDocumentLoad {
  pageCount: number;
}

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  standalone: true,
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PDFViewerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public pdfSrc: string;

  @Input() public pageNumber = 1;

  @Input() public bgColor = 'rgba(0,0,0,0)'; // Default background color is white

  @Output() public readonly PdfDocumentLoad = new EventEmitter<IPdfDocumentLoad>();

  private _pdfDocument;

  constructor() {
    PDFJS.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${
      PDFJS.version
    }/pdf.worker.min.js`;
  }

  private getPageCount() {
    return this._pdfDocument._pdfInfo.numPages;
  }

  private afterPageLoad(): IPdfDocumentLoad {
    return {
      pageCount: this.getPageCount(),
    };
  }

  public async ngOnInit(): Promise<void> {
    this._pdfDocument = await this.getDocument();
    await this.createRenderTask();
    this.PdfDocumentLoad.emit(this.afterPageLoad());
  }

  public async ngOnChanges(changes: SimpleChanges) {
    if (!this._pdfDocument) {
      return;
    }

    if (
      changes.pageNumber
      && !this.isValidPageNumberRequest(changes.pageNumber.currentValue)
    ) {
      return;
    }

    await this.createRenderTask();
  }

  public async ngOnDestroy() {
    if (this._pdfDocument) {
      this._pdfDocument.destroy();
      this._pdfDocument = null;
    }
  }

  private isValidPageNumberRequest(requestedPage: number) {
    return requestedPage > 0 && requestedPage <= this.getPageCount();
  }

  private async getDocument() {
    const loadingTask = PDFJS.getDocument(this.pdfSrc);

    return loadingTask.promise.then(pdfDocument => new Promise(resolve => resolve(pdfDocument)));
  }

  private async getPage(page: number): Promise<PDFPageProxy> {
    return await this._pdfDocument.getPage(page);
  }

  private getCanvas(viewport: PageViewport): HTMLCanvasElement {
    const canvas = document.getElementById('pdf-canvas') as HTMLCanvasElement;
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    return canvas;
  }

  private async createRenderTask() {
    const page: PDFPageProxy = await this.getPage(this.pageNumber);
    const viewport = page.getViewport({ scale: 1 });
    const canvas: HTMLCanvasElement = this.getCanvas(viewport);

    const context = canvas.getContext('2d');

    if (context == null) {
      return;
    }

    return page.render({
      canvasContext: context,
      viewport,
      background: this.bgColor,
    });
  }
}
