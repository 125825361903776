import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentComponent } from '@shared/components/attachment/attachment.component';
import { AttachmentProgress, toAttachmentProgress } from '@core/models/attachments/attachment-progress.model';
import { Attachment } from '@core/models/attachments/attachment.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'utt-result-files',
  standalone: true,
  templateUrl: './utt-result-files.component.html',
  styleUrls: ['./utt-result-files.component.scss'],
  imports: [CommonModule, AttachmentComponent, TranslateModule],
})
export class UttResultFilesComponent implements OnInit {
  @Input() public files: Attachment[] = [];

  public downloadableFile: AttachmentProgress[] = [];

  public ngOnInit(): void {
    this.downloadableFile = this.files.map(toAttachmentProgress);
  }
}
