import { Pipe, PipeTransform } from '@angular/core';
import { isFailure, isLoading, isSuccess, RemoteData } from '@core/models/remote-data/remote-data.model';

@Pipe({
  name: 'loading',
  standalone: true,
})
export class LoadingRemoteDataPipe implements PipeTransform {
  public transform<T, E>(value: RemoteData<T, E> | null): boolean {
    if (value == null) {
      return true;
    }

    return isLoading(value);
  }
}

@Pipe({
  name: 'failure',
  standalone: true,
})
export class FailureRemoteDataPipe implements PipeTransform {
  public transform<T, E>(value: RemoteData<T, E> | null): E | true| null {
    return value && isFailure(value) ? (value.error ?? true): null;
  }
}

@Pipe({
  name: 'success',
  standalone: true,
})
export class SuccessRemoteDataPipe implements PipeTransform {
  public transform<T, E>(value: RemoteData<T, E> | null): T | null {
    return value && isSuccess(value) ? value.data : null;
  }
}

@Pipe({
  name: 'empty',
  standalone: true,
})
export class empty implements PipeTransform {
  public transform<T>(value?: T[] | null): boolean {
    return value?.length === 0;
  }
}

@Pipe({
  name: 'notEmpty',
  standalone: true,
})
export class notEmpty implements PipeTransform {
  public transform<T>(value: T[] | null): T[] | null {
    if (value == null) {
      return null;
    }

    return value.length !== 0 ? value : null;
  }
}
