<div class="select-container" [id]="selectFieldID">
    <div
        class="value-container"
        [class.opened]="isOpen"
        [class.closed]="!isOpen"
        [class.empty]="displayedValue === placeholder"
        (click)="toggleDropdown()"
        [id]="selectFieldID"
        tabindex="0"
        (keydown)="onKeydown($event)">
        <span>{{ displayedValue | translate }}</span>
    </div>
    <div class="options-container" *ngIf="isOpen"  >
        <div *ngFor="let option of displayedOptions; let i = index"
            class="option"
            (click)="onOptionClicked(option.value)"
            [class.empty]="option.value === null"
            [class.active]="isActive(option, i)"
            >
            {{ option.label | translate }}
        </div>
    </div>
</div>