<div class="header">
    <div class="expand-buttons">
        <div class="expand-btn" (click)="expandAll()" *ngIf="notEmpty()">
            {{ "ultrasonic.expandAll" | translate }}
        </div>
        <div class="expand-btn" (click)="collapseAll()" *ngIf="notEmpty()">
            {{ "ultrasonic.collapseAll" | translate }}
        </div>
    </div>
    <div class="right">
        <button class="btn-primary" (click)="onAddCML()">
            <icon iconName='add'/>
            {{ "ultrasonic.addCML" | translate}}
        </button>
    </div>
</div>

<div class="cml-list">
    <inline-input *ngFor="let control of addCmlControl.controls; let index = index"
        [state]="inputStates[index]"
        [control]="control"
        label="CML - "
        placeholder="ultrasonic.addCMLPlaceholder"
        error="ultrasonic.error.duplicateCML"
        (valueConfirmed)="onConfirmCML($event, index)"
        (remove)="onRemove(index)"/>
    <cml *ngFor="let cml of cmls | success" [cml]="cml"
     [isExpanded]="isExpanded(cml.location)"
     [assetId]="assetId"
     [sectionId]="sectionId"
     (expanded)="onExpandedOrCollapse(cml.location, $event)"
     (deleteCML)="confirmDeleteCML(cml.id, cml.location)"
     />
    <spinner *ngIf="cmls | loading" class="spinner"></spinner>

    <div class="empty-cml-state" *ngIf="isEmpty()">
        <empty-state-message
        [title]="'ultrasonic.emptyCml.title'"
        [subTitle]="'ultrasonic.emptyCml.message'"
        />
    </div>
</div>
