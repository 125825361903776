import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';

const slideIn = trigger('slideIn', [
  state('void', style({ transform: 'translateX(-100%)' })),
  state('*', style({ transform: 'translateX(0)' })),
  transition('void => *', animate('300ms ease-in-out')),
]);

@Component({
  selector: 'side-panel',
  standalone: true,
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  animations: [
    slideIn,
  ],
  imports: [CommonModule, TranslateModule, IconComponent],
})
export class SidePanelComponent {
  @Input() public onClose: () => void;
  @Input() public title: string;
  @Input() public loadingOverlay: boolean = false;

  protected readonly slideIn = slideIn;
}
