<div class="select-container" [id]="selectFieldID">
    <label *ngIf="!!label" [for]="selectFieldID" class="label">{{ label | translate }} {{ isRequired ? '*' : ''}}</label>
    <input
        [class.opened]="isOpen"
        [class.closed]="!isOpen"
        class="input-field" 
        (click)="toggleDropdown()" 
        [formControl]="control" 
        [id]="selectFieldID"
        tabindex="0"
        [placeholder]="placeholder | translate"
        (keydown)="onKeydown($event)"
        [readOnly]="true"/>  
        <span class="error" *ngIf="isInvalid" >{{ firstError | translate }}</span>  
        <div class="options-container" *ngIf="isOpen"  >
            <div *ngFor="let option of displayedOptions; let i = index"
                class="option"
                [class.empty]="option.value === null"
                (click)="onOptionClicked(option.value)"
                [class.active]="isActive(option, i)"
                >{{option.label | translate}}</div>
        </div>
</div>