import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UnitSystem } from '@core/units/units';
import { LocalStorageService } from '../local-storage/local-storage.service';

const UnitSystemKey = 'unit_system';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  private unitSystem$ = new BehaviorSubject<UnitSystem>(this.getUnitSystem());

  constructor(private localStorageService: LocalStorageService) {}

  private getUnitSystem(): UnitSystem {
    return this.localStorageService.getItem(UnitSystemKey)
      .map(toUnitSystem)
      .withDefault(UnitSystem.IMPERIAL);
  }

  public setUnitSystem(unitSystem: UnitSystem) {
    this.unitSystem$.next(unitSystem);

    return this.localStorageService.setItem(UnitSystemKey, unitSystem);
  }

  public watchUnitSystem(): Observable<UnitSystem> {
    return this.unitSystem$.asObservable();
  }
}

const toUnitSystem = (text: string): UnitSystem => text as UnitSystem;
