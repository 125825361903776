import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../icon/icon.component';
import { DropdownComponent } from '../dropdown.component';

export type Option<T> = { label: string, value: T };

@Component({
  selector: 'select-field',
  standalone: true,
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, IconComponent, TranslateModule],
})
export class SelectFieldComponent<T> extends DropdownComponent<T> {
  @Input() public label: string | null = null;

  constructor() {
    super();
  }

  public get isInvalid() {
    return (this.control.invalid && this.control.touched && !this.isOpen)
    || (this.control.invalid && this.control.dirty && !this.isOpen);
  }

  public get isRequired() {
    return this.control.hasValidator(Validators.required);
  }

  public get firstError() {
    return this.control.errors?.required ? 'form.required': '';
  }
}
