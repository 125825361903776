export const DEFAULT_NUMBER_OF_DECIMAL = 4;

export enum UnitSystem {
  IMPERIAL = 'imperial',
  INTERNATIONAL = 'metric',
}

export type ImperialUnit = 'in';
export type MetricUnit = 'mm';

export type Unit = ImperialUnit | MetricUnit;

export type Measure = { _type: Unit, value: number };

export const Measure = (x: number, _type: Unit) => ({
  value: x,
  _type,
});

const mmToIn = (x: Measure): Measure => ({ _type: 'in', value: x.value / 25.4 });
const inToMm = (x: Measure): Measure => ({ _type: 'mm', value: x.value * 25.4 });

export const Convert = {
  mmToIn,
  inToMm,
};
