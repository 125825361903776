<div class="content">
  <input-field
    [label]="'inspectionRequest.name' | translate"
    [id]="'name'"
    [control]="getControl('name')"
    [required]="true"
    [focusOnRender]="true"
    [placeholder]="'inspectionRequest.namePlaceholder' | translate"
  />

  <date-picker
    [label]="'inspectionRequest.startDate' | translate"
    [id]="'startDate'"
    [control]="getControl('startDate')"
    [required]="true"
    [minDate]="minDate"
  />

  <date-picker
    [label]="'inspectionRequest.dueDate' | translate"
    [control]="getControl('dueDate')"
    [id]="'dueDate'"
    [required]="true"
    [errorMapper]="errorMapper"
    [minDate]="minDate"
  />

  <div class="inspection-type-section">
    <label class="inspection-type-label">{{'assetsList.inspectionForm.inspectionTypes' | translate}}</label>

    <div class="header-row row">
      <div class="col">{{'assetsList.inspectionForm.unit' | translate}}</div>
      <div class="col">{{'assetsList.inspectionForm.asset' | translate}}</div>
      <div class="col">{{'assetsList.inspectionForm.section' | translate}}</div>
      <div class="labeled-checkbox col"><input type="checkbox" checked (change)="toggleAll($event, 'utt')">
        <span>{{'assetsList.inspectionForm.utt' | translate}}</span>
      </div>
      <div class="labeled-checkbox col"><input type="checkbox" checked (change)="toggleAll($event, 'vt')">
        <span>{{'assetsList.inspectionForm.vt' | translate}}</span>
      </div>
    </div>

    <div class="inspection-type-content">
      <div class="content-row row" *ngFor="let item of getInspectionTaskValues() | keyvalue">
        <div class="col">{{item.key.unitName}}</div>
        <div class="col">{{item.key.equipmentId}}</div>
        <div class="col">{{item.key.sectionName}}</div>
        <div class="col"><input type="checkbox" [checked]="item.value.utt" (change)="toggleCheckbox($event, item.key, 'utt')" /></div>
        <div class="col"><input type="checkbox" [checked]="item.value.vt" (change)="toggleCheckbox($event, item.key, 'vt')"/></div>
      </div>
    </div>
  <span *ngIf="this.getControl('inspectionTypes').hasError('noInspectionType')" class="error">
    {{ "inspectionRequest.errors.noInspectionType" | translate }}
  </span>

  </div>

</div>
