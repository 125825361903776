import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentProgress, UploadedAttachment } from '@core/models/attachments/attachment-progress.model';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ProgressCircleComponent } from '@shared/components/progress-circle/progress-circle.component';
import { FormatDatePipe, FormatDateStringPipe } from '@shared/pipes/format-date/format-date.pipe';
import { FileDownloadService } from '../../../core/services/file/file-download.service';

type Action = 'delete' | 'download';

@Component({
  selector: 'attachment',
  standalone: true,
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  imports: [CommonModule, IconComponent, ProgressCircleComponent, FormatDateStringPipe, TranslateModule, FormatDatePipe],
})
export class AttachmentComponent {
  @Input() public attachment: AttachmentProgress;
  @Output() public readonly deleteAttachment = new EventEmitter<UploadedAttachment>();
  @Input() public last = false;
  @Input() public actions: Action[] = ['delete', 'download'];

  constructor(private fileDownloadService: FileDownloadService) {}

  public onDownload(attachment: UploadedAttachment) {
    this.fileDownloadService
      .downloadFileLocally(attachment.filePath, attachment.fileName)
      .subscribe(progress => this.attachment = { ...this.attachment, progress });
  }

  public onDeleteAttachment(attachment: UploadedAttachment) {
    this.deleteAttachment.emit(attachment);
  }

  public hasAction(action: Action) {
    return !!this.actions.find(a => a === action);
  }
}
