import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Asset } from '@core/models/asset/asset.model';
import { Loading, RemoteData } from '@core/models/remote-data/remote-data.model';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyStateMessageComponent } from '@shared/components/empty-state-message/empty-state-message.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { FailureRemoteDataPipe, LoadingRemoteDataPipe, SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { Observable, of } from 'rxjs';
import { AssetStateService } from '../services/asset-state.service';
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { SectionsConfigurationComponent } from './sections-configuration/sections-configuration.component';
import { PanelComponent } from '../../shared/components/panel/panel.component';

@Component({
  selector: 'asset-configuration',
  standalone: true,
  templateUrl: './asset-configuration.component.html',
  styleUrls: ['./asset-configuration.component.scss'],
  imports: [
    CommonModule,
    AssetDetailsComponent,
    TranslateModule,
    IconComponent,
    EmptyStateMessageComponent,
    SpinnerComponent,
    FailureRemoteDataPipe,
    LoadingRemoteDataPipe,
    SuccessRemoteDataPipe,
    SectionsConfigurationComponent,
    PanelComponent,
  ],
})
export class AssetConfigurationComponent implements OnInit {
  public editMode = false;
  public assetId: string;
  public asset$: Observable<RemoteData<Asset>> = of(Loading());

  constructor(private assetState: AssetStateService, private activatedRoute: ActivatedRoute) {
    this.assetId = this.activatedRoute.snapshot.params.id;
    this.asset$ = this.assetState.asset$;
  }

  public ngOnInit(): void {
    this.assetState.fetchAndUpdate(this.assetId);
  }

  public editAsset() {
    this.editMode = !this.editMode;
  }
}
