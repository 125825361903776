import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'inspection-card-header',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './inspection-card-header.component.html',
  styleUrls: ['../inspection-card/inspection-card.component.scss', './inspection-card-header.component.scss'],
})
export class InspectionCardHeaderComponent {

}
