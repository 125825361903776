export type Filters = { query: string, fields: FieldFilter[] };

export type FieldFilter = { fieldName: string, keywords: string[] };

export const appendFilter = (fieldFilters: FieldFilter[], fieldFilter: FieldFilter) => {
  const existingKey = fieldFilters.find(el => el.fieldName === fieldFilter.fieldName);

  return existingKey
    ? fieldFilters.map(el => el === existingKey ? { ...el, keywords: fieldFilter.keywords }: el)
    : fieldFilters.concat([fieldFilter]);
};

export const hasActiveFilter = (filter: Filters) => filter.query !== '' || filter.fields.length !== 0;
