<div class="page-header">
  <span class="breadcrumb active">{{ "assetsList.title" | translate }}</span>
  <div class="page-header-actions">
    <button (click)="openRequestInspectionModal()" [disabled]="!isRequestInspectionEnabled || selectedAssets.length === 0" id="create-inspection-request-btn" class="btn-primary icon-button define-model-btn">
      <icon iconName='add'/>
      {{ selectedAssets.length === 0 ? ('assetsList.emptyRequestInspection' | translate)
      : ('assetsList.requestInspection' | translate:{assetCount: selectedAssets.length}) }}
    </button>
    <action-menu [actions]="actions" menuPositioning="bottom-end"/>
  </div>
</div>

<asset-model-creation *ngIf="showDefineModelModal" [onClose]="toggleDefineModelModal.bind(this)" class="side-content"/>
<asset-creation  *ngIf="showCreateAsset" [onClose]="toggleCreateAssetModal.bind(this)" class="side-content"/>
<import-data  *ngIf="openImportAssets"  [onClose]="toggleImportAssets.bind(this)" class="side-content"/>
<import-pcms *ngIf="openImportPcms" [onClose]="toggleImportPcms.bind(this)" class="side-content"/>
<request-inspection-modal/>
<export-data  *ngIf="openExportToPCMS" [onClose]="toggleExportToPCMS.bind(this)"/>
