import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Attachment } from '@core/models/attachments/attachment.model';
import { TranslateModule } from '@ngx-translate/core';
import { AttachmentComponent } from '@shared/components/attachment/attachment.component';
import { AttachmentProgress, toAttachmentProgress } from '../../../core/models/attachments/attachment-progress.model';

@Component({
  selector: 'vt-result-files',
  standalone: true,
  templateUrl: './vt-result-files.component.html',
  styleUrls: ['./vt-result-files.component.scss'],
  imports: [CommonModule, AttachmentComponent, TranslateModule],
})
export class VtResultFilesComponent implements OnInit {
  @Input() public attachments: Attachment[] = [];
  @Input() public report: Attachment | undefined;

  public pictureProgress: AttachmentProgress[] = [];
  public reportProgress: AttachmentProgress | undefined;

  public ngOnInit(): void {
    this.pictureProgress = this.attachments.map(toAttachmentProgress);
    this.reportProgress = this.report ? toAttachmentProgress(this.report) : undefined;
  }
}
