import { notNull } from '@core/maybe-model';
import { InspectionHistoryItem } from '@core/models/inspections/inspection.model';

export const getHistorySectionsByInspectionList = (inspectionHistory: InspectionHistoryItem[]) => {
  const completed = inspectionHistory
    .filter(i => i.status === 'Completed')
    .sort((a, b) => (b.completedDate?.getTime() ?? 0) - (a.completedDate?.getTime() ?? 0));

  const utt = completed.find(v => v.inspectionType === 'Utt');
  const vt = completed.find(v => v.inspectionType === 'Vt');

  const others = inspectionHistory
    .filter((i) => i !== utt)
    .filter((i) => i !== vt);

  return {
    latestResult: [
      utt,
      vt,
    ].filter(notNull),
    others,
  };
};
