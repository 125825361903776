import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AssetModel } from '@core/models/assetModel/asset-model.model';
import { CustomField } from '@core/models/asset/custom-field.model';
import { AssetDetail } from '@core/models/asset/asset.model';

export type AssetForm = { controlName: string, control: FormControl }[];

const uniques = (array: CustomField[]) => [...new Map(array.map(item =>
  [item.name, item])).values()];

const toCustomField = (assetForm: AssetForm): CustomField[] =>
  uniques(assetForm.map(field => ({ name: field.controlName, value: field.control.value })));

const isInvalid = (assetForm: AssetForm) => !!assetForm.find(field => field.control.invalid);

const buildCreationForm =(assetModel: AssetModel, formBuilder: FormBuilder): AssetForm =>
  AssetModel(assetModel).requiredModelFields
    .map(field => ({ controlName: field, control: formBuilder.control('', [Validators.required]) }))
    .concat(AssetModel(assetModel).notRequiredModelFields().map(f => ({ controlName: f.name, control: formBuilder.control('') })));

const buildEditForm = (asset: AssetDetail, assetModel: AssetModel, formBuilder: FormBuilder): AssetForm => {
  const disabledFields: AssetForm = [
    { controlName: assetModel.equipmentId, control: formBuilder.control({ value: asset.equipmentId, disabled: true }) },
    { controlName: assetModel.equipmentType, control: formBuilder.control({ value: asset.equipmentType, disabled: true }) },
    { controlName: assetModel.unitName, control: formBuilder.control({ value: asset.unitName, disabled: true }) },
  ];

  const otherFields = AssetModel(assetModel).notRequiredFields(asset.customFields)
    .map(f => ({ controlName: f.name, control: formBuilder.control(f.value) }));

  return disabledFields.concat(otherFields);
};

export const AssetForm = {
  toCustomField,
  isInvalid,
  buildEditForm,
  buildCreationForm,
};
