import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

@Component({
  selector: 'custom-checkbox-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './custom-checkbox-header.component.html',
})
export class CustomCheckboxHeaderComponent implements IHeaderAngularComp {
  private params: IHeaderParams;

  public isChecked: boolean = false;

  public agInit(params: IHeaderParams,
  ): void {
    this.params = params;
  }

  private selectAllRows() {
    this.params.api.forEachNode(node => {
      node.setSelected(true);
    });
  }

  public onChange(event: Event) {
    const checkbox: HTMLInputElement = event.target as HTMLInputElement;
    this.isChecked = checkbox.checked;

    if (checkbox.checked) {
      this.selectAllRows();
    } else {
      this.params.api.deselectAll();
    }
  }

  public refresh(): boolean {
    return false;
  }
}
