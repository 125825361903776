import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map, Subscription } from 'rxjs';
import { getRouterUrl } from '../core/store/router';
import { IconComponent, IconName } from '../shared/components/icon/icon.component';
import { LocalStorageService } from '../shared/services/local-storage/local-storage.service';

type NavId = '_assets' | '_inspections';
type NavItem = {
  id: NavId
  label: string,
  icon: IconName,
  navPath: string,
};

@Component({
  selector: 'side-bar-nav',
  standalone: true,
  templateUrl: './side-bar-nav.component.html',
  styleUrls: ['./side-bar-nav.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        width: '4.7rem',
      })),
      state('out', style({
        width: '15.5rem',
      })),
      transition('in => out', animate('300ms ease-in-out')),
      transition('out => in', animate('300ms ease-in-out')),
    ]),
  ],
  imports: [CommonModule, IconComponent, MatTooltipModule, TranslateModule],
})
export class SideBarNavComponent implements OnDestroy {
  private subscription: Subscription;

  public menuState: 'in' | 'out' = 'out';
  public activeNavItem: NavId | undefined = undefined;

  private MENU_STATE_STORAGE_KEY = 'SIDE_MENU_STATE';

  public navItem: NavItem[] = [
    {
      id: '_assets',
      label: 'sideBarNav.assets',
      icon: 'storage',
      navPath: 'assets',
    },
    {
      id: '_inspections',
      label: 'sideBarNav.inspections',
      icon: 'timeline',
      navPath: 'inspections',
    },
  ];

  constructor(public router: Router, private store: Store, public localStorageService: LocalStorageService) {
    this.subscribeToRouteChange();

    this.menuState = localStorageService
      .getItem(this.MENU_STATE_STORAGE_KEY)
      .map((s: string) => s === 'in' ? 'in' : 'out')
      .withDefault('out');
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public toggleMenu() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    this.localStorageService.setItem(this.MENU_STATE_STORAGE_KEY, this.menuState);
  }

  private subscribeToRouteChange() {
    this.subscription = this.store.select(getRouterUrl)
      .pipe(filter(route => !!route))
      .pipe(map(decodeRoute))
      .subscribe(route => this.activeNavItem = route);
  }
}

const decodeRoute = (route: string) => {
  if (!route) {
    return undefined;
  }

  if (route.endsWith('/inspections')) {
    return '_inspections';
  }

  if (route === '/') {
    return '_assets';
  }

  return undefined;
};
