
 <form> 
    <div *ngFor="let item of form">
        <input-field
        [label]="item.controlName"
        [id]="id(item.controlName)"
        [required]="isRequired(item.controlName)"
        [control]="item.control"
        [errorMapper]="errorMapper"
        class="input-field"
        />
    </div> 
</form>   

    
