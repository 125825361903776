<div class="side-panel left" [@slideIn]="slideIn">
  <div *ngIf="loadingOverlay" class="loading-overlay"></div>
    <div class="panel-header">
      <h1>{{title}}</h1>
      <div (click)="onClose()" class="close">
        <icon iconName='keyboard_double_arrow_left'/>
      </div>
    </div>
    <ng-content select="[content]"></ng-content>
   <ng-content select="[footer]" class="footer"></ng-content>
</div>
