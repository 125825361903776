<div class="file" [class.last]="last" *ngIf="attachment">
    <div class="right">
        <icon iconName="description" size="large" class="file-icon"/>
        <div class="info">
            <div class="file-name">{{ attachment.fileName }}</div>

            <div class="date">{{ attachment.lastModifiedDate | formatDate }}</div>
        </div>
    </div>
    <div *ngIf="attachment.progress.state === 'COMPLETED' && attachment._type === 'uploaded'" class="button-container">
        <div *ngIf="hasAction('delete')" (click)="onDeleteAttachment(attachment)" class="action-btn">{{ "assetDetails.remove" | translate }}</div>
        <div *ngIf="hasAction('download')" (click)="onDownload(attachment)" class="action-btn">
            {{ "assetDetails.download" | translate }}
        </div>
    </div>
    <div *ngIf="attachment.progress.state === 'IN_PROGRESS'" id="progress">
        <progress-circle [percentageComplete]="attachment.progress.percentDone"/>
    </div>
    <div *ngIf="attachment.progress.state === 'FAILURE' && attachment._type === 'uploading'" class="error">
        {{ "assetDetails.uploadFailed" | translate}}
        <icon [iconName]="'error'" size="small"/> 
    </div>
</div>