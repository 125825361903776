import { Routes } from '@angular/router';
import { AssetPageComponent } from './asset-page/asset-page.component';
import { AssetsListComponent } from './assets-list-page/assets-list.component';
import { InspectionsListComponent } from './inspections-list/inspections-list.component';
import { ResourceNotFoundComponent } from './resource-not-found/resource-not-found.component';
import { authenticationGuard } from './shared/auth/authentication.guard';

export const routes: Routes = [
  {
    path: '',
    component: AssetsListComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'details/:id',
    component: AssetPageComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'details',
    component: ResourceNotFoundComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'inspections',
    component: InspectionsListComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: '**',
    redirectTo: '',
    component: AssetsListComponent, // fallback to home page in case no match
    canActivate: [authenticationGuard],
  },
];
