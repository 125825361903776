<modal [onClose]="dismiss.bind(this)" *ngIf="confirmationProps$ | async as props" size='small' [withSplitter]="false">
    <h2  header>{{ props.title | translate }}</h2>
    <div content class="content"
    [translate]="props.message"
    [translateParams]="props.messageParameters"
    ></div>
    <div class="buttons" footer>
        <button class="btn-secondary" (click)="dismiss()" [disabled]="loading$ | async">{{"common.cancel" | translate}}</button>
        <button id="confirm-btn" class="btn-primary" (click)="props.onConfirm()" [disabled]="loading$ | async">
            <spinner size="small" *ngIf="loading$ | async"/>
            {{"common.confirm" | translate}}
        </button>
    </div>
</modal>
