import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { mapFailure, mapSuccess, onFailure, RemoteData } from '@core/models/remote-data/remote-data.model';
import { ConfigService } from '@shared/services';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { Observable } from 'rxjs';
import { AssetModelCreationMapper } from '../../mappers/asset-model-creation.mapper';
import { AssetModelResponse } from '../../mappers/asset-model-response';
import { AssetModelCreationRequest } from '../../models/assetModel/asset-model-creation.model';
import { JobId } from '@core/services/job-status/job';
import { AssetModel } from '../../models/assetModel/asset-model.model';

export enum AssetModelError {
  NotFound = 'NotFound',
  Unknown = 'Unknown',
  BadRequest = 'BadRequest',
}

@Injectable({
  providedIn: 'root',
})
export class AssetModelService {
  private assetModelEndpoint = 'api/v1/assetModel';

  constructor(
    private httpClient: HttpService,
    private config: ConfigService,
    private toastService: ToastService,
  ) {}

  public createAssetModel(form: AssetModelCreationRequest):
  Observable<RemoteData<unknown, AssetModelError>> {
    return this.httpClient
      .post({ endpoint: `${this.config.apiUrl}/${this.assetModelEndpoint}`, body: form })
      .pipe(mapFailure(e => e.status === 400 ? AssetModelError.BadRequest : AssetModelError.Unknown));
  }

  public getAssetModel(): Observable<RemoteData<AssetModel, AssetModelError>> {
    return this.httpClient
      .get<AssetModelResponse>({ endpoint: `${this.config.apiUrl}/${this.assetModelEndpoint}` })
      .pipe(mapSuccess(AssetModelCreationMapper.fromResponse))
      .pipe(mapFailure(e => e.status === 404 ? AssetModelError.NotFound : AssetModelError.Unknown))
      .pipe(onFailure(e => e === AssetModelError.Unknown ? this.toastService.add(Toast.error('common.error')) : null));
  }

  public deleteAssetModel(): Observable<RemoteData<JobId>> {
    return this.httpClient
      .delete({ endpoint: `${this.config.apiUrl}/${this.assetModelEndpoint}` });
  }
}
