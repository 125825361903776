<div class="input">
    {{ label }}
    <input-field
        [id]="'inline-input'"
        [control]="control"
        (keyDown)="onKeyDown($event)"
        (inputChange)="resetErrorState()"
        [focusOnRender]="true"
        [placeholder]="placeholder | translate"/>
</div>
<div class="action-buttons" >
    <div *ngIf="state === 'error'" class="error">
        {{ error | translate}}
        <icon  [iconName]="'error'" size="small"/>
    </div>
    <spinner class="loading-indicator" size="small" *ngIf="state === 'loading'"></spinner>
    <button *ngIf="state !== 'loading'"  [disabled]="control.value?.trim() === '' || state === 'error'" class="round-icon-button check-icon" (click)="onConfirm()" >
        <icon iconName='check' size="small"/>
    </button>
    <button *ngIf="state !== 'loading'"  class="round-icon-button remove-icon" size="small" (click)="onRemove()" >
        <icon iconName='close'/>
    </button>
</div>
