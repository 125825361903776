import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { mapSuccess, RemoteData } from '@core/models/remote-data/remote-data.model';
import { ConfigService } from '@shared/services';
import { Observable } from 'rxjs';
import { InspectionCreationRequest } from './inspection-request.model';
import { InspectionHistoryResponse } from './inspection-history.response';
import { toDateOnly } from '../../mappers/date-only/date-only.mapper';
import { InspectedSection, InspectionHistoryGroupItem, InspectionHistoryItem } from '../../models/inspections/inspection.model';
import { toInspectionHistoryGroupItem, toInspectionHistoryItem } from '../../mappers/inspection.mapper';
import { Paginated, PaginatedResponse, Pagination } from '../../models/pagination.type';

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  private inspectionRequestEndpoint = 'api/v1/inspections';
  private inspectionHistoryEndpoint = (assetId: string) => `/api/v1/assets/${assetId}/inspections`;

  constructor(
    private httpClient: HttpService,
    private config: ConfigService,
  ) { }

  public createInspectionRequest(form: InspectionCreationRequest): Observable<RemoteData> {
    const body = { ...form, startDate: toDateOnly(form.startDate), endDate: toDateOnly(form.endDate) };

    return this.httpClient
      .post({ endpoint: `${this.config.apiUrl}/${this.inspectionRequestEndpoint}`, body });
  }

  public getInspectedSections(assetIds: string[]): Observable<RemoteData<InspectedSection[]>> {
    const body = { assetIds };

    return this.httpClient
      .post({ endpoint: `${this.config.apiUrl}/${this.inspectionRequestEndpoint}/sections`, body });
  }

  public getAssetInspectionHistory(assetId: string): Observable<RemoteData<InspectionHistoryItem[]>> {
    return this.httpClient
      .get<InspectionHistoryResponse[]>({ endpoint: `${this.config.apiUrl}${this.inspectionHistoryEndpoint(assetId)}` })
      .pipe(mapSuccess((data) => data.flatMap(toInspectionHistoryItem)));
  }

  public getAllInspectionHistory(pagination: Pagination): Observable<RemoteData<Paginated<InspectionHistoryGroupItem>>> {
    return this.httpClient
      .get<PaginatedResponse<InspectionHistoryResponse>>({ endpoint: `${this.config.apiUrl}/api/v1/inspections`, params: {
      limit: pagination.limit,
      offset: pagination.offset,
    } })
      .pipe(mapSuccess((paginated) =>
        (Paginated(paginated.data.map(toInspectionHistoryGroupItem), paginated.pagination.total, paginated.pagination.offset))));
  }

  public deleteInspectionTask(assetId: string, inspectionId: string, inspectionTaskId: string): Observable<RemoteData> {
    return this.httpClient
      .delete({ endpoint: `${this.config.apiUrl}${this.inspectionHistoryEndpoint(assetId)}/${inspectionId}/tasks/${inspectionTaskId}` });
  }
}
