import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyStateMessageComponent } from '@shared/components/empty-state-message/empty-state-message.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';
import { ImportPcmsComponent } from '../import-pcms/import-pcms.component';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent, EmptyStateMessageComponent, ImportPcmsComponent],
})
export class EmptyStateComponent implements INoRowsOverlayAngularComp {
  public params!: INoRowsOverlayParams;
  public hasFilteringApplied: boolean = false;
  public importDataModal = false;

  public agInit(params: INoRowsOverlayParams & { hasFilterApplied: boolean }): void {
    this.params = params;
    this.hasFilteringApplied = params.hasFilterApplied;
  }

  public get title() {
    return this.hasFilteringApplied ? 'assetsList.emptyState.filteredTitle' : 'assetsList.emptyState.title';
  }

  public get message() {
    return this.hasFilteringApplied ? 'assetsList.emptyState.filteredMessage' : 'assetsList.emptyState.message';
  }

  public toggleImportData() {
    this.importDataModal = !this.importDataModal;
  }
}
