import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CustomRouterStateSerializer } from '@core/custom-state.serializer';
import { httpInterceptors } from '@core/interceptors';
import { environment } from '@env';
import { I18nService } from '@shared/services';
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LocalErrorHandlerService } from './core/services/monitoring/error-handler.local.service';
import { ErrorHandlerService } from './core/services/monitoring/error-handler.service';
import { authReducer } from './core/store';
import { initializeLogin } from './core/store/auth/auth.action';
import { AuthEffects } from './core/store/auth/auth.effect';
import { ConfirmationModalComponent } from './shared/components/confirmation-modal/confirmation-modal.component';
import { ToastComponent } from './shared/components/toast/toast.component';
import { SideBarNavComponent } from './side-bar-nav/side-bar-nav.component';
import { CookieModule } from 'ngx-cookie';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  providers: [
    ...httpInterceptors,
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store) => (): void => store.dispatch(initializeLogin()),
      deps: [Store],
      multi: true,
    },
    { provide: ErrorHandler, useClass: environment.hmr ? LocalErrorHandlerService : ErrorHandlerService },
    I18nService,
  ],
  bootstrap: [AppComponent],
  imports: [
    // Angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // Internal modules
    RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // Store modules
    CookieModule.withOptions(),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer,
      stateKey: 'router',
    }),
    StoreModule.forRoot({ auth: authReducer, router: routerReducer }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([AuthEffects]),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    MatIconModule,
    ToastComponent,
    ConfirmationModalComponent,
    NgCircleProgressModule.forRoot({}),
    SideBarNavComponent,
  ],
})
export class AppModule {}
