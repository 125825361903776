import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssetModelCreationRequest } from '@core/models/assetModel/asset-model-creation.model';
import { andThen, EmptySuccess, Idle, isLoading, isSuccess, Loading, mapFailure, onFailure, onSuccess, RemoteData } from '@core/models/remote-data/remote-data.model';
import { AssetModelStateService } from '@core/services/assetModel/asset-model-state.service';
import { AssetModelError, AssetModelService } from '@core/services/assetModel/asset-model.service';
import { JobStatusService } from '@core/services/job-status/job-status.service';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { LoadingRemoteDataPipe, SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { of, Subscription } from 'rxjs';
import { AssetListStateService } from '../../services/asset-list-state.service';
import { BindFieldFormComponent } from './bind-field-form/bind-field-form.component';
import { DataUploadFormComponent } from './data-upload-form/data-upload-form.component';
import { ImportCompletedWarningsComponent } from './import-completed-warnings/import-completed-warnings.component';
import { ImportDataService, UploadError } from './services/import-data.service';
import { FileChunkUploadService } from '../../../core/services/file/file-chunk-upload.service';

export type UploadForm = {
  assetModelName: FormControl<string | null>;
  file: FormControl<File | null>;
  attachment: FormControl<File | null>;
};

export type FieldBindingForm = {
  equipmentId: FormControl<string|null>;
  unitName: FormControl<string|null>;
  equipmentType: FormControl<string|null>;
};

type ImportDataError = { errorType: string, details?: string };

type Step = 'dataUpload' | 'fieldsBinding' | 'completedWithWarnings';
type StepInfo = {
  buttonLabel: (model: boolean) => string
  previous: Step | null,
  onClick: (model: boolean) => void;
  number: number | null
};

@Component({
  selector: 'import-data',
  standalone: true,
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss'],
  imports: [
    CommonModule,
    IconComponent,
    ModalComponent,
    DataUploadFormComponent,
    BindFieldFormComponent,
    LoadingRemoteDataPipe,
    TranslateModule,
    SuccessRemoteDataPipe,
    SpinnerComponent,
    ImportCompletedWarningsComponent,
  ],
})
export class ImportDataComponent implements OnDestroy {
  @Input() public onClose: () => void = () => {};
  public fieldsState: RemoteData<string[]> = Idle();
  public hasModelDefined = false;
  private isUploading = false;
  public step: Step = 'dataUpload';
  public errors: ImportDataError[] = [];

  public uploadFileForm: FormGroup<UploadForm>;

  public fieldBindingForm = new FormGroup<FieldBindingForm>({
    equipmentId: new FormControl('', [Validators.required]),
    unitName: new FormControl('', [Validators.required]),
    equipmentType: new FormControl('', [Validators.required]),
  });

  public steps: { [step in Step]: StepInfo } = {
    ['dataUpload']: {
      buttonLabel: (hasModelDefined: boolean) => hasModelDefined ? 'common.import': 'assetImport.next',
      previous: null,
      onClick: (hasModelDefined: boolean) => hasModelDefined ? this.onComplete() : this.onNext(),
      number: 1,
    },

    ['fieldsBinding']: {
      buttonLabel: (_: boolean) => 'common.import',
      previous: 'dataUpload',
      onClick: (_: boolean) => this.onComplete(),
      number: 2,
    },

    ['completedWithWarnings']: {
      buttonLabel: (_: boolean) => 'common.close',
      previous: null,
      onClick: (_: boolean) => this.closeModalAndNavigate(),
      number: null,
    },
  };

  private modelStateSubscription: Subscription;

  constructor(
    private importDataService: ImportDataService,
    private modelStateService: AssetModelStateService,
    private assetsStateService: AssetListStateService,
    private assetModelService: AssetModelService,
    private jobService: JobStatusService,
    private fileChunkUploadService: FileChunkUploadService,
    private toastService: ToastService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.modelStateSubscription = this.modelStateService.state$.subscribe(model => {
      this.hasModelDefined = !!isSuccess(model);
      this.uploadFileForm = new FormGroup<UploadForm>({
        assetModelName: this.hasModelDefined ? new FormControl('') : new FormControl('', [Validators.required]),
        file: new FormControl(null),
        attachment: new FormControl(null),
      }, { validators: this.AtLeastOneFileValidation() });
    });
  }

  public ngOnDestroy() {
    this.modelStateSubscription.unsubscribe();
  }

  private AtLeastOneFileValidation() {
    return (form: FormGroup) => {
      const IsCsvPresentWhenNoModel = (!this.hasModelDefined && form.get('file')?.value === null);
      const AtLeastOneFileWithModel = form.get('file')?.value === null && form.get('attachment')?.value === null;

      const error = IsCsvPresentWhenNoModel || AtLeastOneFileWithModel
        ? { invalid: true }
        : null;

      form.setErrors(error);

      return error;
    };
  }

  private onNext() {
    if (!this.uploadFileForm.dirty && isSuccess(this.fieldsState)) {
      return this.step = 'fieldsBinding';
    }

    this.fieldsState = Loading();
    this.importDataService
      .extractFieldsFromCsv(this.fileControl.value)
      .pipe(
        onSuccess(() => {
          this.step = 'fieldsBinding';
          this.fieldBindingForm.reset();
          this.uploadFileForm.markAsPristine();
        }),
        onFailure(() => this.toastService.add(Toast.error('common.error'))),
      )
      .subscribe(fields => this.fieldsState = fields);
  }

  private onComplete() {
    this.isUploading = true;

    this.createModelIfNotExist()
      .pipe(
        andThen(() => this.fileChunkUploadService.uploadZipFile(this.attachmentControl.value)),
        andThen(() => this.importDataService.importData(this.fileControl.value, this.attachmentControl.value?.name)),
        mapFailure((e) => ([{ errorType: e, details: '' }])),
        onFailure(e => this.onFailure(e)),
        onSuccess(job => {
          this.jobService.startPolling(job,
            { onFailure: (errors) => this.onFailure(errors),
              onSuccess: () => this.onSuccess(),
              finalize: () => this.isUploading = false,
            });
        }),
      )
      .subscribe();
  }

  private createModelIfNotExist() {
    if (!this.hasModelDefined && isSuccess(this.fieldsState)) {
      return this.assetModelService.createAssetModel(this.getModelCreationRequest(this.fieldsState.data));
    }

    return of(EmptySuccess());
  }

  private onFailure(e: ImportDataError[]) {
    this.isUploading = false;
    switch (e[0]?.errorType) {
      case UploadError.INVALID_DATA:
        this.fileControl.setErrors({ incorrect: true });
        break;
      case UploadError.INVALID_ATTACHMENT:
        this.attachmentControl.setErrors({ incorrect: true });
        break;
      case UploadError.DUPLICATE_EQUIPMENT_ID:
        this.toastService.add(Toast.error('assetImport.errors.duplicates'));
        this.closeModalAndNavigate();
        break;
      case AssetModelError.BadRequest:
        this.fieldBindingForm.setErrors({ incorrect: true });
        break;
      case UploadError.INVALID_FTP:
        this.step = 'completedWithWarnings';
        this.errors = e;
        break;
      default:
        this.toastService.add(Toast.error('common.error'));
        this.closeModalAndNavigate();
    }
  }

  private closeModalAndNavigate() {
    this.assetsStateService.fetchAndUpdate();
    this.modelStateService.fetchAndUpdate();
    this.router.navigate(['/']);
    this.onClose();
  }

  private onSuccess() {
    this.toastService.add(Toast.success('assetImport.success'));
    this.closeModalAndNavigate();
  }

  private getModelCreationRequest(assetModelFields: string[]): AssetModelCreationRequest {
    return {
      modelName: this.uploadFileForm.get('assetModelName')?.value ?? '',
      assetModelFields,
      equipmentId: this.fieldBindingForm.get('equipmentId')?.value ?? '',
      equipmentType: this.fieldBindingForm.get('equipmentType')?.value ?? '',
      unitName: this.fieldBindingForm.get('unitName')?.value ?? '',
    };
  }

  public isFormInvalid() {
    if (this.step === 'dataUpload') {
      return this.uploadFileForm.invalid;
    }

    if (this.step === 'fieldsBinding') {
      return this.fieldBindingForm.invalid;
    }

    return false;
  }

  public get isLoading() {
    return isLoading(this.fieldsState) || this.isUploading;
  }

  public get fileControl() {
    return this.uploadFileForm.get('file') as FormControl<File | null>;
  }

  public get attachmentControl() {
    return this.uploadFileForm.get('attachment') as FormControl<File | null>;
  }

  public goToPrevious(previous: Step | null) {
    if (previous) {
      this.step = previous;
      this.changeDetector.detectChanges();
    }
  }

  public get toast() {
    if (this.fileControl.hasError('incorrect')) {
      return Toast.error('assetImport.errors.invalidData');
    }

    if (this.attachmentControl.hasError('incorrect')) {
      return Toast.error('assetImport.errors.invalidAttachments');
    }
  }
}
