import { Injectable } from '@angular/core';
import { mapFailure, RemoteData } from '@core/models/remote-data/remote-data.model';
import { HttpService } from '@core/services/http/http.service';
import { JobId } from '@core/services/job-status/job';
import { ConfigService } from '@shared/services';
import { Observable } from 'rxjs';

export enum UploadError {
  INVALID_DATA = 'invalidData',
  INVALID_ATTACHMENT = 'InvalidAttachmentException',
  UNKNOWN = 'unknown',
  DUPLICATE_EQUIPMENT_ID = 'duplicate',
  INVALID_FTP = 'FtpParsingException',
}

@Injectable({
  providedIn: 'root',
})
export class ImportDataService {
  private extractFieldsEndpoint = 'api/v1/assetModel/extractFields';
  private importDataEndpoint = 'api/v1/assets/import';

  private assetsDataKey = 'assetsDataFile';
  private attachmentsZipKey = 'zipName';
  private extractFieldFileKey = 'assetsDataFile';

  constructor(private httpClient: HttpService, private config: ConfigService) { }

  public extractFieldsFromCsv(csvFile: File | null): Observable<RemoteData<string[]>> {
    const formData = new FormData();
    if (csvFile) {
      formData.append(this.extractFieldFileKey, csvFile);
    }

    return this.httpClient.post<string[], FormData>({
      endpoint: `${this.config.apiUrl}/${this.extractFieldsEndpoint}`,
      body: formData,
    });
  }

  public importData(csvFile: File | null, attachmentsZipName?: string): Observable<RemoteData<JobId, UploadError>> {
    const formData = new FormData();
    if (csvFile) {
      formData.append(this.assetsDataKey, csvFile);
    }

    if (attachmentsZipName) {
      formData.append(this.attachmentsZipKey, attachmentsZipName);
    }

    return this.httpClient.post<JobId, FormData>({
      endpoint: `${this.config.apiUrl}/${this.importDataEndpoint}`,
      body: formData,
    }).pipe(
      mapFailure(e => {
        switch (e.errorType) {
          case 'AssetInvalidException':
            return UploadError.INVALID_DATA;
          case 'DuplicateException':
            return UploadError.DUPLICATE_EQUIPMENT_ID;
          default:
            return UploadError.UNKNOWN;
        }
      }));
  }
}
