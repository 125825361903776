import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { IconComponent } from '../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'collapsible',
  standalone: true,
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate('300ms ease-in')),
      transition('true => false', animate('300ms ease-out')),
    ]),
  ],
  imports: [CommonModule, IconComponent, TranslateModule],
})
export class CollapsibleComponent implements OnInit {
  @Input() public defaultOpen = false;
  @Input() public id = 'collapsible';
  @Output() public readonly expanded = new EventEmitter<boolean>();
  @Input() public expandable = true;
  @Input() public headerColor: 'light' | 'dark' = 'light';

  public collapsed = true;

  public ngOnInit(): void {
    this.collapsed = !this.defaultOpen;
  }

  public toggleCollapse() {
    this.collapsed = !this.collapsed;
    this.expanded.emit(!this.collapsed);
  }

  public open() {
    this.collapsed = false;
    this.expanded.emit(true);
  }

  public close() {
    this.collapsed = true;
    this.expanded.emit(false);
  }
}
