import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { DragDrop } from '../../../assets-list-page/components/import-data/data-upload-form/drag-drop';

@Component({
  selector: 'file-dropzone',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent, MatTooltipModule],
  templateUrl: './file-dropzone.component.html',
  styleUrls: ['./file-dropzone.component.scss'],
})
export class FileDropzoneComponent implements OnInit {
  @Input() public dropzoneText: string;
  @Input() public errorText: string;
  @Input() public hasError: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public fileType: string;
  @Input() public tooltip: string | null = null;
  @Output() public readonly file = new EventEmitter<File | null>();
  @Input() public id = 'file-dropzone';

  @Input() public fileName: string | null = null;
  public fileDragDrop: DragDrop;

  constructor(private toastService: ToastService) {}

  public ngOnInit() {
    this.fileDragDrop = new DragDrop(this.fileType,
      (currentFile) => {
        this.file.emit(currentFile);
      },
      () => this.toastService.add(Toast.error('common.invalidFileType')),
    );
  }

  public onFilePick(event: Event) {
    const currentFile = (event.target as HTMLInputElement)?.files?.[0];
    this.file.emit(currentFile);
    (event.target as HTMLInputElement).value = '';
  }

  public removeFile() {
    this.file.emit(null);
  }
}
