import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { appendFilter, FieldFilter, Filters } from '@core/models/filters.type';

@Injectable({
  providedIn: 'root',
})
export class FilterStateService {
  private filterSubject = new BehaviorSubject<Filters>({ fields: [], query: '' });

  public state$ = this.filterSubject.asObservable();

  public updateFilters(filters: Partial<Filters>) {
    this.filterSubject.next({ ...this.filterSubject.value, ...filters });
  }

  public addFieldFilters(field: FieldFilter) {
    this.updateFilters({ fields: appendFilter((this.filterSubject.value.fields ?? []), field) });
  }

  public remoteFieldFilters(fieldName: string) {
    this.updateFilters({ fields: this.filterSubject.value.fields?.filter(f => f.fieldName !== fieldName) });
  }
}
