import { Injectable } from '@angular/core';
import { Maybe, toMaybe } from '@core/maybe-model';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() { }

  public setItem(key: string, item: string) {
    localStorage.setItem(key, item);
  }

  public getItem(key: string): Maybe<string> {
    const item = localStorage.getItem(key);

    return toMaybe(item);
  }
}
