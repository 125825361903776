import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';

export function authenticationGuard(): CanActivateFn {
  return () => {
    const authService: AuthService = inject(AuthService);

    if (authService.isUserLogged()) {
      return true;
    }

    authService.authenticate();

    return false;
  };
}
