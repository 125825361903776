export type CustomField = { name: string, value: string };

const toObject = (list: CustomField[]) => list.reduce((result, { name, value }) => {
  result[name] = value;

  return result;
}, {});

export const CustomField = {
  toObject,
};
