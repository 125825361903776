<modal [onClose]="onClose.bind(this)" [customSize]="{ height: 20, width: 40 }">
    <h2 header>{{ "export.exportData" | translate }}</h2>
    <div content class="content">
        <input-field
        class="input-field"
        [label]="'export.fileName' | translate"
        [placeholder]="'export.placeholder' | translate"
        [control]="fileNameControl"
        [id]="'file-name'"/>
    </div>
    <div class="buttons" footer>
        <button class="btn-secondary" (click)="onClose()" [disabled]="isLoading">{{ "common.cancel" | translate}}</button>
        <button id="confirm-btn" class="btn-primary" (click)="export()" [disabled]="isLoading || !fileNameControl.valid">
            <spinner size="small" *ngIf="isLoading"/>
            {{ "export.exportButton" | translate}}
        </button>
    </div>
</modal>
