import { createSelector } from '@ngrx/store';

import { AdmState, AuthState } from '@core/store';

export const getAuthState = (state: AdmState) => state.auth;

export const isAuthLoading = createSelector(
  getAuthState,
  (authState: AuthState) => !!authState && authState.loading,
);

export const isAuthenticated = createSelector(
  getAuthState,
  (authState: AuthState) => !!authState && authState.loggedIn,
);
