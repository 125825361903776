<div class="card" [class.active]="active">
    <span class="field medium">{{inspectionHistory.name}}</span>
    <span class="field small">{{inspectionHistory.sectionName}}</span>
    <span class="field small">{{inspectionHistory.unitName}}</span>
    <span class="field small">{{inspectionHistory.startDate | formatDate: 'MMM d, yyyy' }}</span>
    <span class="field small">{{inspectionHistory.dueDate | formatDate: 'MMM d, yyyy'}}</span>
    <span class="field medium">{{ "inspectionHistory." + inspectionHistory.inspectionType | translate}}</span>
    <div  class="field small"><status-indicator [status]="inspectionHistory.status"/></div>
    <span class="field small">{{ inspectionHistory.completedDate | formatDate: 'MMM d, yyyy'}}</span>
    <div class="button-container field small">
        <button (click)="onViewResult()" [ngClass]="{visible: inspectionHistory.status === 'Completed'}" class="btn-secondary">{{"inspectionHistory.viewResults" | translate }}</button>
    </div>
    <action-menu class="action-menu" [actions]="actions" [actionMenuId]="inspectionHistory.inspectionTaskId"/>
</div>