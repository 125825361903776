import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FailureRemoteDataPipe, LoadingRemoteDataPipe, SuccessRemoteDataPipe } from '../../pipes/remote-data.pipe';
import { IconComponent } from '../icon/icon.component';
import { InputFieldComponent } from '../input-field/input-field.component';
import { SpinnerComponent } from '../spinner/spinner.component';

export type InlineInputState = 'loading'| 'error'| 'idle';

@Component({
  selector: 'inline-input',
  standalone: true,
  templateUrl: './inline-input.component.html',
  styleUrls: ['./inline-input.component.scss'],
  imports: [CommonModule, InputFieldComponent, IconComponent, TranslateModule,
    SpinnerComponent, ReactiveFormsModule, LoadingRemoteDataPipe, SuccessRemoteDataPipe, FailureRemoteDataPipe],
})
export class InlineInputComponent {
  @Input() public label: string = '';
  @Input() public control = new FormControl<string | null>('');
  @Input() public state: InlineInputState = 'idle';
  @Input() public error: string = '';
  @Input() public placeholder: string = '';

  @Output() public readonly valueConfirmed = new EventEmitter<string>();
  @Output() public readonly remove = new EventEmitter();

  public onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onConfirm();
    }
  }

  public resetErrorState() {
    this.state = 'idle';
  }

  public onConfirm() {
    if (!this.control.value) {
      return;
    }

    this.valueConfirmed.emit(this.control.value);
  }

  public onRemove() {
    this.remove.emit();
  }
}
