/* eslint-disable @typescript-eslint/no-unused-expressions */
export class DragDrop {
  public hover = false;
  public counter = 0;
  public fileType = '';
  private readonly onSuccess: (file: File) => void;
  private readonly onError: () => void;

  constructor(filetype: string, onSuccess: (file: File) => void, onError: () => void) {
    this.fileType = filetype;
    this.onSuccess = onSuccess;
    this.onError = onError;
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  public onDragLeave() {
    this.counter--;
    if (this.counter === 0) {
      this.hover = false;
    }
  }

  public onDragEnter() {
    this.counter++;
    this.hover = true;
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer?.files[0];

    if (!file?.type.includes(this.fileType)) {
      this.onError();
    } else {
      this.onSuccess(file);
    }

    this.hover = false;
    this.counter = 0;
  };
}
