import { Component, OnInit } from '@angular/core';

import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { I18nService } from '@shared/services';
import { of } from 'rxjs';
import { MonitoringService } from './core/services/monitoring/monitoring.service';
import { isAuthenticated } from './core/store/auth/auth.selector';

@Component({
  selector: 'ndt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public control = new FormControl<number| null>(null, [Validators.required]);
  public isAuthenticated$ = of(false);

  constructor(
    private readonly i18nService: I18nService,
    private readonly store: Store,
    private monitoringService: MonitoringService,

  ) {}

  public ngOnInit() {
    this.monitoringService.init();
    this.i18nService.init();
    this.isAuthenticated$ = this.store.select(isAuthenticated);
  }
}
