import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

const modalAnimation = trigger('modalAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('100ms', style({ opacity: 1 })),
  ]),
]);

@Component({
  selector: 'modal',
  standalone: true,
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [modalAnimation],
  imports: [CommonModule, IconComponent],
})
export class ModalComponent {
  @Input() public onClose: () => void = () => {};
  @Input() public size: 'small' | 'medium' = 'medium';
  @Input() public customSize: { height: number, width: number } | undefined = undefined;
  @Input() public isLoading = false;
  @Input() public withSplitter = true;
  @Input() public inline = false;

  public get customSizeStyle() {
    return this.customSize ? `height: ${this.customSize.height}rem; width: ${this.customSize.width}rem`: '';
  }

  public get classes() {
    return `${this.size} ${this.inline ? 'inline': ''}`;
  }
}
