    <div class="content">
        <panel title="assetConfigurationPage.details" >
            <button [class.btn-primary]="!editMode" [class.btn-secondary]="editMode" (click)="editAsset()" header>
                <icon iconName="edit"/>
                {{ "assetDetails.edit" | translate }}
            </button>    
            <ng-container content>
                <empty-state-message *ngIf="asset$ | async | failure" [title]="'assetConfigurationPage.error.title'" [subTitle]="'assetConfigurationPage.error.subTitle'"/>

                <spinner *ngIf="asset$ | async | loading" class="spinner"/>

                <asset-details *ngIf="asset$ | async | success as asset"  [editMode]="editMode" [asset]="asset" (modeChanged)="editAsset()" />   
            </ng-container>   
        </panel>
    
       <sections-configuration [assetId]="assetId" class="sections-panel"/>
    </div>



