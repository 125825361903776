import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InspectionHistoryItem } from '@core/models/inspections/inspection.model';
import { andThen, isSuccess, Loading, onFailure, RemoteData, Success } from '@core/models/remote-data/remote-data.model';
import { FileDownloadService } from '@core/services/file/file-download.service';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyStateMessageComponent } from '@shared/components/empty-state-message/empty-state-message.component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { IPdfDocumentLoad, PDFViewerComponent } from '@shared/components/pdf-viewer/pdf-viewer.component';
import { RightSidePanelComponent } from '@shared/components/right-side-panel/right-side-panel.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { TabGroupComponent } from '@shared/components/tab-group/tab-group.component';
import { TabComponent } from '@shared/components/tab-group/tab/tab.component';
import { FormatDatePipe } from '@shared/pipes/format-date/format-date.pipe';
import { LoadingRemoteDataPipe, SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { of, tap } from 'rxjs';
import { VtInspectionResult } from '../models/vt-inspection-result.model';
import { InspectionResultService } from '../services/inspection-result.service';
import { VtResultFilesComponent } from './vt-result-files/vt-result-files.component';

@Component({
  selector: 'vt-inspection-result',
  standalone: true,
  templateUrl: './vt-inspection-result.component.html',
  styleUrls: ['./vt-inspection-result.component.scss'],
  imports: [CommonModule, RightSidePanelComponent, SuccessRemoteDataPipe,
    SpinnerComponent, LoadingRemoteDataPipe, PDFViewerComponent, FormatDatePipe,
    TranslateModule, TabGroupComponent, TabComponent, VtResultFilesComponent,
    PaginationComponent, EmptyStateMessageComponent],
})
export class VtInspectionResultComponent implements OnInit {
  @Output() public readonly closeViewer = new EventEmitter();
  @Input() public inspectionTask: InspectionHistoryItem;

  public vtInspectionResult$: RemoteData<VtInspectionResult> = Loading();
  public pdfSrc$: RemoteData<string | undefined> = Loading();

  public pageCount = 1;
  public currentPage = 1;
  public activeTab: string;

  public reportTabId = 'report';

  constructor(private inspectionResultService: InspectionResultService,
    private fileDownloadService: FileDownloadService,
    private toastService: ToastService) {}

  public ngOnInit(): void {
    this.inspectionResultService
      .getVtInspectionResult(this.inspectionTask.inspectionTaskId)
      .pipe(
        tap(r => this.vtInspectionResult$ = r),
        andThen(result => this.getPdfSrc(result?.pdfReport?.filePath)),
        onFailure(() => this.toastService.add(Toast.error('vtResult.errors.getResult'))),
      )
      .subscribe(pdf => {
        this.pdfSrc$ = pdf;
      });
  }

  private getPdfSrc(path: string | undefined) {
    return path ? this.fileDownloadService.downloadFile(path) : of(Success(undefined));
  }

  public onDocumentLoad = (value: IPdfDocumentLoad) => {
    this.pageCount = value.pageCount;
    this.currentPage = 1;
  };

  public onClose() {
    this.closeViewer.emit();
  }

  public onPageChange(pageIncrement: number) {
    this.currentPage = this.currentPage + pageIncrement;
  }

  public onActiveTabChange(tabId: string) {
    this.activeTab = tabId;
  }

  public showPagination() {
    return this.activeTab === this.reportTabId && isSuccess(this.pdfSrc$) && !!this.pdfSrc$.data;
  }
}
