import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Asset } from '@core/models/asset/asset.model';
import { isLoading, onFailure, onSuccess } from '@core/models/remote-data/remote-data.model';
import { AssetsService } from '@core/services/assets/assets.service';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { InputFieldComponent } from '@shared/components/input-field/input-field.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { AssetFormComponent } from '../../../../asset-form/asset-form.component';
import { AssetForm } from '../../../../asset-form/asset-form.model';
import { AssetStateService } from '../../../services/asset-state.service';

@Component({
  selector: 'edit-asset',
  standalone: true,
  templateUrl: './edit-asset.component.html',
  styleUrls: ['./edit-asset.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldComponent,
    TranslateModule,
    SpinnerComponent,
    AssetFormComponent,
    SuccessRemoteDataPipe,
  ],
})
export class EditAssetComponent implements OnInit {
  @Input() public asset: Asset;

  @Output() public readonly onclose = new EventEmitter();
  public isLoading = false;

  public form: AssetForm = [];

  constructor(
    private assetState: AssetStateService,
    private formBuilder: FormBuilder,
    private assetService: AssetsService,
    private toastService: ToastService,
  ) {}

  public ngOnInit(): void {
    this.form = AssetForm.buildEditForm(this.asset, this.asset.model, this.formBuilder);
  }

  public onSubmit() {
    this.assetService.edit(this.asset.id, { customFields: AssetForm.toCustomField(this.form) })
      .pipe(onSuccess(() => this.onSuccess()))
      .pipe(onFailure(() => this.onFailure()))
      .subscribe(state => this.isLoading = isLoading(state));
  }

  private onFailure() {
    this.toastService.add(Toast.error('editAsset.failure'));
    this.onclose.emit();
  }

  private onSuccess() {
    this.assetState.fetchAndUpdate(this.asset.id);
    this.toastService.add(Toast.success('editAsset.success'));
    this.onclose.emit();
  }

  public onCancel() {
    this.onclose.emit();
  }

  public get isInvalid() {
    return AssetForm.isInvalid(this.form);
  }
}
