import { CommonModule } from '@angular/common';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isFailureOf, isSuccess, onSuccess } from '@core/models/remote-data/remote-data.model';
import { AssetModelStateService } from '@core/services/assetModel/asset-model-state.service';
import { AssetModelError, AssetModelService } from '@core/services/assetModel/asset-model.service';
import { JobStatusService } from '@core/services/job-status/job-status.service';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { ActionMenuComponent } from '@shared/components/action-menu/action-menu.component';
import { Action } from '@shared/components/action-menu/action.model';
import { ConfirmationModalService } from '@shared/components/confirmation-modal/confirmation-modal.service';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Subscription } from 'rxjs';
import { ExportPCMSDataComponent } from '../../../export-data/export-pcms-data.component';
import { SelectedAsset } from '../../models/selected-asset.model';
import { AssetListStateService } from '../../services/asset-list-state.service';
import { InspectionRequestStateService } from '../../services/inspection-request-state.service';
import { AssetCreationComponent } from '../asset-creation/asset-creation.component';
import { AssetModelCreationComponent } from '../asset-model-creation/asset-model-creation.component';
import { ImportDataComponent } from '../import-data/import-data.component';
import { ImportPcmsComponent } from '../import-pcms/import-pcms.component';
import { RequestInspectionModalComponent } from '../request-inspection-modal/request-inspection-modal.component';

@Component({
  selector: 'assets-list-header',
  standalone: true,
  templateUrl: './assets-list-header.component.html',
  styleUrls: ['./assets-list-header.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    IconComponent,
    AssetModelCreationComponent,
    ModalComponent,
    AssetCreationComponent,
    ImportDataComponent,
    ActionMenuComponent,
    RequestInspectionModalComponent,
    ImportPcmsComponent,
    ExportPCMSDataComponent,
  ],
})
export class AssetsListHeaderComponent implements OnDestroy {
  private assetModelStateSubscription: Subscription;
  private inspectionRequestStateSubscription: Subscription;

  public showDefineModelModal = false;
  public showCreateAsset = false;
  public openImportAssets = false;
  public openImportPcms = false;
  public selectedAssets: SelectedAsset[] = [];
  public isRequestInspectionEnabled = true;
  public openExportToPCMS = false;

  public actions: Action[] = [];

  @ViewChild(RequestInspectionModalComponent) public requestInspectionModal: RequestInspectionModalComponent;

  constructor(
    private assetModelStateService: AssetModelStateService,
    private confirmationService: ConfirmationModalService,
    private assetModelService: AssetModelService,
    private assetStateService: AssetListStateService,
    private inspectionRequestStateService: InspectionRequestStateService,
    private jobService: JobStatusService,
    private toastService: ToastService,
    private router: Router,
  ) {
    this.inspectionRequestStateSubscription = this.inspectionRequestStateService.state$.subscribe(
      selectedAssets => {
        this.selectedAssets = selectedAssets;
      },
    );

    this.assetModelStateSubscription = this.assetModelStateService.state$.subscribe(assetModelState => {
      const isModelDefined = !isFailureOf(assetModelState, AssetModelError.NotFound);
      this.isRequestInspectionEnabled = isModelDefined && isSuccess(assetModelState);

      this.actions = [
        {
          title: 'assetsList.actions.importData',
          iconName: 'download',
          visible: false,
          callback: () => this.toggleImportAssets(),
        },
        {
          title: 'assetsList.actions.importPcms',
          iconName: 'download',
          visible: isSuccess(assetModelState) || !isModelDefined,
          callback: () => this.toggleImportPcms(),
        },
        {
          title: 'assetsList.actions.addData',
          iconName: 'add',
          disabled: !isSuccess(assetModelState),
          visible: isSuccess(assetModelState) || isFailureOf(assetModelState, AssetModelError.NotFound),
          callback: () => this.toggleCreateAssetModal(),
        }, {
          title: 'assetsList.actions.defineModel',
          iconName: 'edit',
          visible: isFailureOf(assetModelState, AssetModelError.NotFound),
          callback: () => this.toggleDefineModelModal(),
        },
        {
          title: 'assetsList.actions.exportPcms',
          iconName: 'upload',
          visible: isSuccess(assetModelState),
          callback: () => this.toggleExportToPCMS(),
        },
        {
          title: 'assetsList.actions.deleteTenantData',
          iconName: 'delete',
          visible: isSuccess(assetModelState),
          callback: () => this.confirmDeleteAction(),
        },
      ];
    });
  }

  public ngOnDestroy(): void {
    this.assetModelStateSubscription.unsubscribe();
    this.inspectionRequestStateSubscription.unsubscribe();
  }

  public toggleDefineModelModal(): void {
    this.showDefineModelModal = !this.showDefineModelModal;
  }

  public toggleCreateAssetModal(): void {
    this.showCreateAsset = !this.showCreateAsset;
  }

  public toggleImportAssets() {
    this.openImportAssets = !this.openImportAssets;
  }

  public toggleImportPcms() {
    this.openImportPcms = !this.openImportPcms;
  }

  public openRequestInspectionModal() {
    this.requestInspectionModal.open(this.selectedAssets);
  }

  public toggleExportToPCMS() {
    this.openExportToPCMS = !this.openExportToPCMS;
  }

  private deleteAssetModel() {
    this.confirmationService.loading();
    this.assetModelService
      .deleteAssetModel()
      .pipe(onSuccess(jobId => {
        this.jobService.startPolling(
          jobId,
          {
            onFailure: () => this.onDeleteFailure(),
            onSuccess: () => this.onDeleteSuccess(),
            finalize: () => this.router.navigate(['/']),
          },
        );
      }))
      .subscribe();
  }

  private onDeleteSuccess() {
    this.assetModelStateService.fetchAndUpdate();
    this.assetStateService.fetchAndUpdate(undefined, { onSuccess: async () => {
      this.toastService.add(Toast.success('assetsList.deleteSuccessMessage'));
      this.confirmationService.close();
    } });
  };

  private onDeleteFailure() {
    this.toastService.add(Toast.error('common.error'));
    this.confirmationService.close();
  }

  public confirmDeleteAction() {
    this.confirmationService.open({
      title: 'assetsList.deleteTitle',
      message: 'assetsList.deleteMessage',
      onConfirm: () => this.deleteAssetModel(),
    });
  }
}
