import { createAction } from '@ngrx/store';

export const INITIALIZE_LOGIN = '[Auth] Initialize login';
export const LOGIN = '[Auth] Login';
export const LOGOUT = '[Auth] Logout';
export const LOGIN_SUCCESS = '[Auth] Login Success';

export const initializeLogin = createAction(
  INITIALIZE_LOGIN,
);

export const login = createAction(
  LOGIN,
);

export const logout = createAction(
  LOGOUT,
);

export const loginSuccess = createAction(
  LOGIN_SUCCESS,
);
