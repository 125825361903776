import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Section } from '../core/models/sections/section.model';
import { AssetConfigurationComponent } from './asset-configuration/asset-configuration.component';
import { AssetPageHeaderComponent } from './asset-page-header/asset-page-header.component';
import { InspectionHistoryComponent } from './inspections/inspection-history/inspection-history.component';
import { InspectionHistoryStateService } from './inspections/services/inspection-history.state.service';
import { SectionPageComponent } from './section-page/section-page.component';
import { AssetStateService } from './services/asset-state.service';
import { AttachmentsStateService } from './section-page/services/attachments-state.service';

@Component({
  selector: 'asset-page',
  standalone: true,
  templateUrl: './asset-page.component.html',
  styleUrls: ['./asset-page.component.scss'],
  providers: [AssetStateService, InspectionHistoryStateService, AttachmentsStateService],
  imports: [
    CommonModule, AssetPageHeaderComponent, InspectionHistoryComponent,
    TranslateModule, AssetConfigurationComponent, SectionPageComponent,
  ],
})
export class AssetPageComponent implements OnInit {
  public assetId = '';
  public sectionId: string | null = null;
  public currentPage = 'Configuration';

  constructor(private route: ActivatedRoute, private assetState: AssetStateService) {}

  public ngOnInit(): void {
    this.assetId = this.route.snapshot.params.id;
    this.assetState.fetchAndUpdate(this.assetId);
  }

  public onSectionSelected($section: Section | null) {
    this.sectionId = $section?.id ?? null;
    if (this.sectionId) {
      this.currentPage = 'Configuration';
    }
  }

  public onPageChange($event: string) {
    this.currentPage = $event;
  }

  public get pagesOptions() {
    if (this.sectionId) {
      return ['Configuration'];
    }

    return ['Configuration', 'InspectionHistory'];
  }
}
