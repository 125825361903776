import { Injectable } from '@angular/core';
import { ConfigService } from '@shared/services/config/config.service';
import { filter, finalize, interval, Observable, Subscription, switchMap, takeWhile } from 'rxjs';
import { isSuccess, RemoteData } from '../../models/remote-data/remote-data.model';
import { ClientError, HttpService } from '../http/http.service';
import { JobError, JobId, JobResponse } from './job';

export type PollingOptions = {
  onSuccess?: () => void;
  onFailure?: (errorCode: JobError[]) => void;
  finalize?: () => void;
};

export const POLLING_DEFAULT_INTERVAL = 1000;

@Injectable({
  providedIn: 'root',
})
export class JobStatusService {
  private jobEndpoint = 'api/v1/jobs';
  private INTERVAL = POLLING_DEFAULT_INTERVAL;

  constructor(private httpClient: HttpService, private config: ConfigService) { }

  private getJobStatus(jobId: JobId) {
    return this.httpClient
      .get<JobResponse>({ endpoint: `${this.config.apiUrl}/${this.jobEndpoint}/${jobId.id}` });
  }

  /**
 * @deprecated use poll
 */
  public startPolling(job: JobId, options?: PollingOptions) {
    const pollSubscription: Subscription =
    interval(this.INTERVAL)
      .pipe(switchMap(() => this.getJobStatus(job)))
      .pipe(finalize(() => options?.finalize?.()))
      .subscribe(res => this.onResponse(res, pollSubscription, options));

    return pollSubscription;
  };

  /**
 * @description Poll on the job status endpoint and emit the result when the the job is completed.
 */
  public poll(job: JobId): Observable<RemoteData<JobResponse, ClientError>> {
    return interval(this.INTERVAL)
      .pipe(switchMap(() => this.getJobStatus(job)))
      .pipe(takeWhile(job => isSuccess(job) && job.data.status === 'Ongoing', true))
      .pipe(filter(job => isSuccess(job) && job.data.status !== 'Ongoing'));
  }

  private onResponse(response: RemoteData<JobResponse>, pollSubscription: Subscription, options?: PollingOptions) {
    if (!isSuccess(response)) {
      return;
    }

    if (response.data.status === 'Completed') {
      pollSubscription.unsubscribe();
      options?.onSuccess?.();
    }

    if (response.data.status === 'Error') {
      pollSubscription.unsubscribe();

      return options?.onFailure?.(response.data.errors);
    }
  }
}
