import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InspectionHistoryGroupItem, InspectionTask } from '@core/models/inspections/inspection.model';
import { TranslateModule } from '@ngx-translate/core';
import { CollapsibleComponent } from '@shared/components/collapsible/collapsible.component';
import { StatusIndicatorComponent } from '@shared/components/status-indicator/status-indicator.component';
import { FormatDatePipe } from '@shared/pipes/format-date/format-date.pipe';

@Component({
  selector: 'inspections-group',
  standalone: true,
  templateUrl: './inspections-group.component.html',
  styleUrls: ['./inspections-group.component.scss'],
  imports: [CommonModule, CollapsibleComponent, FormatDatePipe, TranslateModule, StatusIndicatorComponent],
})
export class InspectionsGroupComponent {
  @Input() public inspectionGroup: InspectionHistoryGroupItem;
  @Input() public activeTask: InspectionTask | undefined = undefined;

  @ViewChild(CollapsibleComponent) public collapsible: CollapsibleComponent;
  @Output() public readonly viewResult = new EventEmitter<InspectionTask>();

  constructor(public router: Router) {}

  public close() {
    this.collapsible.close();
  }

  public open() {
    this.collapsible.open();
  }

  public groupStatus(inspectionGroup: InspectionHistoryGroupItem) {
    return inspectionGroup.inspectionTasks.some(t => t.status === 'InProgress') ? 'InProgress': 'Completed';
  }

  public completedCount(inspectionGroup: InspectionHistoryGroupItem) {
    const completed = inspectionGroup.inspectionTasks.filter(t => t.status === 'Completed').length;
    const total = inspectionGroup.inspectionTasks.length;

    return `${completed}/${total}`;
  }

  public navigateToAsset(assetId: string) {
    this.router.navigate(['/details', assetId]);
  }

  public onViewResult(inspectionTask: InspectionTask) {
    this.viewResult.emit(inspectionTask);
  }
}
