import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pagination',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent],
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() public currentPage = 1;
  @Input() public pageCount = 1;

  @Output() public readonly pageChange = new EventEmitter<number>();
}
