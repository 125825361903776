import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalErrorHandlerService extends ErrorHandler {
  constructor() {
    super();
  }

  public handleError(error: Error) {
    console.error('ERROR HANDLER: ', error);
  }
}
