import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

type ErrorMapper = (e: ValidationErrors) => string;

const defaultErrorMapper = (e: ValidationErrors) => {
  if (e.required) {
    return 'form.required';
  }

  if (e.duplicate) {
    return 'form.duplicate';
  }

  return '';
};

@Component({
  selector: 'input-field',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule],
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements AfterViewInit {
  @Input() public control: FormControl;
  @Input() public placeholder: string = '';
  @Input() public label: string = '';
  @Input() public id: string = '';
  @Input() public required = false;
  @Input() public focusOnRender = false;
  @Input() public errorMapper: ErrorMapper = defaultErrorMapper;
  @Output() public readonly keyDown = new EventEmitter<KeyboardEvent>();
  @Output() public readonly inputChange = new EventEmitter<Event>();

  @ViewChild('inputEl') public myInputField: ElementRef;
  public ngAfterViewInit() {
    if (this.focusOnRender) {
      this.myInputField.nativeElement.focus();
    }
  }

  public getFirstError() {
    const err = this.control.errors;

    return err ? this.errorMapper(err): '';
  }

  public onKeyDown(event: KeyboardEvent) {
    this.keyDown.emit(event);
  }

  public isInvalid() {
    return (this.control.invalid && this.control.touched) || (this.control.invalid && this.control.dirty);
  }

  public onChange(event: Event) {
    this.inputChange.emit(event);
  }
}

export const CustomInputError = {
  duplicate: { duplicate: true },
  required: { required: true },
};
