import { FileProgress } from '@core/services/file/file-progress';
import { Attachment } from './attachment.model';

export type AttachmentProgress = UploadingAttachment | UploadedAttachment;

export type UploadedAttachment = Attachment & {
  _type: 'uploaded',
  progress: FileProgress,
};

export type UploadingAttachment = Attachment & {
  _type: 'uploading',
  progress: FileProgress,
};

export const toAttachmentProgress = (attachment: Attachment): AttachmentProgress => ({
  ...attachment,
  _type: 'uploaded',
  progress: FileProgress.COMPLETED(),
});

export const fileToAttachmentProgress = (file: File): AttachmentProgress => ({
  _type: 'uploading',
  fileName: file.name,
  filePath: '',
  progress: FileProgress.IN_PROGRESS(0),
  lastModifiedDate: new Date(file.lastModified),
});
