import { Injectable } from '@angular/core';
import { environment } from '@env';
import { mapNullable } from '@core/maybe-model';
const {
  i18n,
  api,
} = environment;

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public readonly tokenKey: string = 'id_token';
  public readonly languages: string[];
  public readonly locales: string[];
  public readonly defaultLocale: string;
  public readonly fallbackLocale: string;
  public readonly i18nStorageKey: string;
  public readonly apiUrl: string;
  public readonly oscUrl: string;
  public readonly origin: string;

  public get isDevMode(): boolean {
    return !environment.production && !environment.staging;
  }

  public get isLocalMode(): boolean {
    return this.isDevMode && environment.hmr;
  }

  constructor() {
    this.apiUrl = api.baseUrl;
    this.oscUrl = api.osc;
    this.origin = api.origin;

    this.defaultLocale = i18n.defaultLocale;
    this.fallbackLocale = i18n.fallback;
    this.languages = i18n.languages;
    this.locales = i18n.locales;
    this.i18nStorageKey = i18n.storageKey;
  }

  public getValue(key: string) {
    return localStorage.getItem(key);
  }

  public setValue(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public addValue(parent: string, key: string, value: unknown): void {
    const parentValue = mapNullable(this.getValue(parent), (v) => JSON.parse(v));
    localStorage.setItem(parent, JSON.stringify({ ...parentValue, [key]: value }));
  }

  public clear(): void {
    localStorage.clear();
  }
}
