import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Loading, RemoteData } from '../../models/remote-data/remote-data.model';
import { AssetModel } from '../../models/assetModel/asset-model.model';
import { AssetModelError, AssetModelService } from './asset-model.service';

@Injectable({
  providedIn: 'root',
})
export class AssetModelStateService {
  private stateSubject = new BehaviorSubject<RemoteData<AssetModel, AssetModelError>>(Loading());
  public state$ = this.stateSubject.asObservable();

  constructor(private assetModelService: AssetModelService) {
    this.fetchAndUpdate();
  };

  public fetchAndUpdate() {
    this.assetModelService
      .getAssetModel()
      .subscribe(data => this.stateSubject.next(data));
  }
}
