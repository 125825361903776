import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AttachmentProgress, fileToAttachmentProgress, toAttachmentProgress, UploadedAttachment } from '@core/models/attachments/attachment-progress.model';
import { onFailure, onSuccess } from '@core/models/remote-data/remote-data.model';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { AttachmentComponent } from '@shared/components/attachment/attachment.component';
import { ConfirmationModalService } from '@shared/components/confirmation-modal/confirmation-modal.service';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ProgressCircleComponent } from '@shared/components/progress-circle/progress-circle.component';
import { TabGroupComponent } from '@shared/components/tab-group/tab-group.component';
import { TabComponent } from '@shared/components/tab-group/tab/tab.component';
import { FormatDateStringPipe } from '@shared/pipes/format-date/format-date.pipe';
import { SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { map, Subscription } from 'rxjs';
import { AttachmentSections, Section } from '../models/attachment-sections.model';
import { AttachmentsStateService } from '../services/attachments-state.service';
import { AttachmentsService } from '../services/attachments.service';

@Component({
  selector: 'section-attachment',
  standalone: true,
  templateUrl: './section-attachments.component.html',
  styleUrls: ['./section-attachments.component.scss'],
  imports: [
    CommonModule, IconComponent, ProgressCircleComponent, FormatDateStringPipe, TranslateModule,
    AttachmentComponent, TabGroupComponent, TabComponent, SuccessRemoteDataPipe,
  ],
})
export class SectionAttachmentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public assetId: string;
  @Input() public sectionId: string;

  @Input() public attachments: AttachmentSections<AttachmentProgress> = AttachmentSections.CreateEmpty();

  private subscription: Subscription;

  constructor(
    private confirmationService: ConfirmationModalService,
    private attachmentsService: AttachmentsService,
    private toastService: ToastService,
    private attachmentStateService: AttachmentsStateService,
  ) {
    this.subscription = this.attachmentStateService.attachments$
      .pipe(map(a => a.map(toAttachmentProgress)))
      .subscribe(attachments => this.attachments = attachments);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(_: SimpleChanges): void {
    this.attachments = AttachmentSections.CreateEmpty();
    this.attachmentStateService.fetchAndUpdate(this.assetId, this.sectionId);
  }

  public ngOnInit(): void {
    this.attachmentStateService.fetchAndUpdate(this.assetId, this.sectionId);
  }

  public confirmDeleteAction(attachment: UploadedAttachment) {
    this.confirmationService.open({
      title: 'assetDetails.attachments.deleteTitle',
      message: 'assetDetails.attachments.deleteMessage',
      messageParameters: { fileName: attachment.fileName },
      onConfirm: () => this.deleteAttachment(attachment),
    });
  }

  public deleteAttachment(attachment: UploadedAttachment) {
    this.confirmationService.loading();
    this.attachmentsService
      .deleteAttachment(attachment.filePath)
      .pipe(
        onSuccess(() => {
          this.attachmentStateService.fetchAndUpdate(this.assetId, this.sectionId);
        }),
        onFailure(() => {
          this.toastService.add(Toast.error('common.error'));
        }),
      )
      .subscribe(() => this.confirmationService.close());
  }

  public attachFile(event: Event, section: Section) {
    const files = (event.target as HTMLInputElement)?.files ?? [];
    this.attachments = this.attachments.update(
      [...Array.from(files).map(fileToAttachmentProgress), ...this.attachments.from(section)], section);
    this.uploadAttachments(Array.from(files), section);
  }

  private uploadAttachments(files: File[], section: Section) {
    this.attachmentsService
      .uploadAttachment(this.assetId, this.sectionId, files, section)
      .subscribe((upload) => {
        this.attachments = this.attachments.update(
          this.attachments.from(section).map(a => a._type === 'uploading' ? { ...a, progress: upload } : a), section);

        if (upload.state === 'COMPLETED') {
          this.attachmentStateService.fetchAndUpdate(this.assetId, this.sectionId);
        }
      });
  }

  public attachDrawing(event: Event) {
    if (this.attachments.drawing) {
      this.confirmationService.open({
        title: 'assetDetails.drawing.warningTitle',
        message: 'assetDetails.drawing.warningMessage',
        onConfirm: () => {
          this.attachFile(event, 'drawing');
          this.confirmationService.close();
        },
      });
    } else {
      this.attachFile(event, 'drawing');
    }
  }
}
