import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'spinner',
  template: `<div class="spinner-container" >
    <div class="spinner" [ngClass]="size"></div>
  </div>`,
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SpinnerComponent {
  @Input() public size: 'small' | 'medium' | 'large' = 'large';
}
