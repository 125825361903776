<div [id]="'action-menu-' + actionMenuId" class="action-menu">
  <button #anchor class="round-icon-button" (click)="toggleMenu()" [class.active]="actionMenuActive" >
    <icon iconName="more_vert"/>
  </button>
  <div #dropdown class="action-dropdown" [class.show]="actionMenuActive">
    <div  *ngFor="let action of actions">
      <div *ngIf="action.visible !== false" [ngClass]="{'disabled' : action.disabled}" (click)="handleAction(action, $event)" class="action-item">
        <icon *ngIf="!!action.iconName" [iconName]="action.iconName"/>
        {{ action.title | translate }}
      </div>
    </div>
  </div>
</div>
