<collapsible [id]="'cml' + cml.location" (expanded)="expanded.emit($event)" [defaultOpen]="isExpanded" [headerColor]="'dark'">
    <div class="content">
        <div *ngIf="isEmpty()" class="empty-state-inspection-point">{{ "ultrasonic.emptyInspectionPoint" | translate }}</div>
        <inline-input *ngFor="let control of inspectionPointControl.controls; let index=index" class="inspection-point"
            [state]="inputStates[index]"
            [control]="control"
            placeholder="ultrasonic.addInspectionPointPlaceholder"
            error="ultrasonic.error.duplicateInspectionPoint"
            (valueConfirmed)="onConfirmInspectionPoint(index, $event)"
            (remove)="onRemove(index)"/>
        <div *ngFor="let point of cml.inspectionPoints" class="inspection-point">
            {{ point }}
            <div (click)="confirmDeleteInspectionPoint(point)" class="action-btn">{{ "common.remove" | translate }}</div>
        </div>
    </div>
  <div header-content class="cml-header">
    <div class="header-title">CML -{{ " " + cml.location}}</div>
    <action-menu class="action-menu"  [actions]="actions" [menuPositioning]="'bottom-end'" [actionMenuId]="cml.id"></action-menu>
  </div>
</collapsible>

