
<div class="header">
    <span class="field medium">{{ "inspectionHistory.inspectionName" | translate}}</span>
    <span class="field small">{{ "inspectionHistory.sectionName" | translate}}</span>
    <span class="field small">{{ "inspectionHistory.unitName" | translate}}</span>
    <span class="field small">{{"inspectionHistory.startDate" | translate}}</span>
    <span class="field small">{{"inspectionHistory.dueDate" | translate}}</span>
    <span class="field medium">{{"inspectionHistory.inspectionType" | translate}}</span>
    <span class="field small" >{{"inspectionHistory.status" | translate}}</span>
    <span class="field small">{{"inspectionHistory.completedDate" | translate}}</span>
    <div class="button-container field small"></div>
    <div class="action-menu"></div>
</div>