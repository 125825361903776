import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { groupBy } from '@core/list-extra';
import { Toast } from '@core/services/toast/toast-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { ToastInlineComponent } from '@shared/components/toast/inline-toast.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';

type Error = { errorType: string, details?: string };

@Component({
  selector: 'import-completed-warnings',
  standalone: true,
  templateUrl: './import-completed-warnings.component.html',
  styleUrls: ['./import-completed-warnings.component.scss'],
  imports: [CommonModule, ToastInlineComponent, TranslateModule, IconComponent],
})
export class ImportCompletedWarningsComponent {
  @Input() public errors: Error[] = [];
  @Input() public csvFileName: string | undefined = undefined;
  @Input() public zipFileName: string | undefined = undefined;

  public groupByError(errors: Error[]) {
    return groupBy(errors, (t) => t.errorType);
  }

  public toast(fileName: string) {
    return Toast.success(fileName);
  }

  public msg(errorType: string) {
    return `assetImport.errors.${errorType}`;
  }
}
