import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InspectionHistoryItem } from '@core/models/inspections/inspection.model';
import { TranslateModule } from '@ngx-translate/core';
import { ActionMenuComponent } from '@shared/components/action-menu/action-menu.component';
import { Action } from '@shared/components/action-menu/action.model';
import { StatusIndicatorComponent } from '@shared/components/status-indicator/status-indicator.component';
import { FormatDatePipe } from '@shared/pipes/format-date/format-date.pipe';
import { ConfirmationModalService } from '../../../../shared/components/confirmation-modal/confirmation-modal.service';

@Component({
  selector: 'inspection-card',
  standalone: true,
  templateUrl: './inspection-card.component.html',
  styleUrls: ['./inspection-card.component.scss'],
  imports: [CommonModule, FormatDatePipe, TranslateModule, StatusIndicatorComponent, ActionMenuComponent],
})
export class InspectionCardComponent {
  @Input() public inspectionHistory: InspectionHistoryItem;
  @Input() public equipmentId: string | null;
  @Input() public active = false;
  @Output() public readonly viewResult = new EventEmitter<void>();
  @Output() public readonly deleteInspection = new EventEmitter<void>();

  constructor(
    private confirmationModal: ConfirmationModalService,
  ) {};

  public onViewResult() {
    this.viewResult.emit();
  }

  public actions: Action[] = [{
    title: 'inspectionHistory.actions.delete',
    iconName: 'delete',
    callback: () => this.deleteInspectionTask(),
  }];

  public deleteInspectionTask(): void {
    this.confirmationModal.open({
      title: 'inspectionHistory.deleteInspectionTask.title',
      message: 'inspectionHistory.deleteInspectionTask.message',
      onConfirm: () => this.deleteInspection.emit(),
    });
  }
}
