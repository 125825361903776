export const parseDateOnly = (dateOnlyString: string) => {
  const date = dateOnlyString.split('-');

  const y = parseInt(date[0], 10);
  const m = parseInt(date[1], 10);
  const d = parseInt(date[2], 10);

  return new Date(y, Math.max(0, m-1), d, 0, 0, 0);
};

export const toDateOnly = (date: Date): string => {
  const y = date.getFullYear();
  const m = (`0${ date.getMonth() + 1}`).slice(-2);
  const d = (`0${ date.getDate()}`).slice(-2);

  return `${y}-${m}-${d}`;
};
