import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { Option, SelectFieldComponent } from '@shared/components/selects/select-field/select-field.component';
import { ToastInlineComponent } from '@shared/components/toast/inline-toast.component';
import { Toast } from '@core/services/toast/toast-service.service';
import { FieldBindingForm } from '../import-data.component';

const defaultForm = new FormGroup({
  equipmentId: new FormControl(''),
  unitName: new FormControl(''),
  equipmentType: new FormControl(''),
});

@Component({
  selector: 'bind-field-form',
  standalone: true,
  templateUrl: './bind-field-form.component.html',
  styleUrls: ['./bind-field-form.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, IconComponent, TranslateModule, ToastInlineComponent, SelectFieldComponent],
})
export class BindFieldFormComponent implements OnInit {
  public ngOnInit(): void {
    this.defaultOptions = this.fields.map(field => ({ label: field, value: field }));
  }

  @Input() public fields: string[] = [];

  @Input() public form: FormGroup<FieldBindingForm> = defaultForm;

  @Input() public toast: Toast | undefined = undefined;
  public defaultOptions: Option<string>[] = [];

  public infoToast = Toast.info('assetImport.bindFieldMessage');

  public errorToast = Toast.error('assetImport.bindFieldError');

  public getControl(controlName: string) {
    if (this.form) {
      return this.form.get(controlName) as FormControl;
    }
  }
}
