/* eslint-disable max-len */
import { combineLatest, map, mergeMap, Observable, of, OperatorFunction, tap } from 'rxjs';

export type RemoteData<T = unknown, E = unknown> = Success<T> | Loading | Failure<E> | Idle;

export type Success<T> = { data: T, status: 'success' };
type Loading = { status: 'loading' };
export type Failure<E> = { status: 'failure', error: E };
type Idle = { status: 'idle' };

export const Loading = (): Loading => ({
  status: 'loading',
});

export const Idle = (): Idle => ({
  status: 'idle',
});

export const Failure = <E = string>(e: E): Failure<E> => ({
  status: 'failure',
  error: e,
});

export const Success = <T>(data: T): Success<T> => ({
  data,
  status: 'success',
});

export const EmptyFailure = (): Failure<unknown> => ({
  status: 'failure',
  error: undefined,
});

export const EmptySuccess = (): Success<unknown> => ({
  data: {},
  status: 'success',
});

export const isSuccess = <T, E>(remoteData: RemoteData<T, E>): remoteData is Success<T> => remoteData.status === 'success';
export const isLoading = <T, E>(remoteData: RemoteData<T, E>): remoteData is Loading => remoteData.status === 'loading';
export const isFailure = <T, E>(remoteData: RemoteData<T, E>): remoteData is Failure<E> => remoteData.status === 'failure';
export const isFailureOf = <T, E>(remoteData: RemoteData<T, E>, e: E): remoteData is Failure<E> =>
  remoteData.status === 'failure' && e === remoteData.error;

export const andThen = <T, E1, E2, TB>(fct: (value: T) => Observable<RemoteData<TB, E2>>) => mergeMap((value: RemoteData<T, E1>) => {
  if (isSuccess(value)) {
    return fct(value.data);
  }

  return of(value);
});

export const onSuccess = <T, E>(fct: (data: T) => void) => tap((value: RemoteData<T, E>) => {
  if (isSuccess(value)) {
    fct(value.data);
  }
});

export const onFailure = <T, E>(fct: (err: E) => void) => tap((value: RemoteData<T, E>) => {
  if (isFailure(value)) {
    fct(value.error);
  }
});

export const mapFailure = <T, E, E2>(fct: (err: E) => E2): OperatorFunction<RemoteData<T, E>, RemoteData<T, E2>> => map((value: RemoteData<T, E>) => {
  if (isFailure(value)) {
    return Failure(fct(value.error));
  }

  return value;
});

export const mapSuccess = <T, E, T2>(fct: (err: T) => T2): OperatorFunction<RemoteData<T, E>, RemoteData<T2, E>> => map((value: RemoteData<T, E>) => {
  if (isSuccess(value)) {
    return Success(fct(value.data));
  }

  return value;
});

export const match = <T, E, T2>(successFct: (data: T) => T2, failureFct: () => T2): OperatorFunction<RemoteData<T, E>, T2> => map((value: RemoteData<T, E>) => {
  if (isSuccess(value)) {
    return successFct(value.data);
  }

  return failureFct();
});

export const combine = <T1, T2>([t1, t2]: [Observable<RemoteData<T1>>, Observable<RemoteData<T2>>]) => combineLatest([t1, t2]).pipe(map(combineObs));

const combineObs = <T1, T2>(dataList: [RemoteData<T1>, RemoteData<T2>]): RemoteData<[T1, T2]> => {
  if (isFailure(dataList[0]) || isFailure(dataList[1])) {
    return EmptyFailure();
  }

  if (isSuccess(dataList[0]) && isSuccess(dataList[1])) {
    return Success([dataList[0].data, dataList[1].data]);
  }

  return Loading();
};
