<side-panel
    [title]="'assetCreation.title' | translate"
    [onClose]="onClose"
    [loadingOverlay]="isLoading"
    *ngIf="model$ | success as model"
    >
    <div class="content" content>
        <inline-toast *ngIf="hasDuplicateError(model)" [toast]="errorToast()"></inline-toast>
        <div class="input-field">
            <label>{{ 'common.modelName' | translate }}</label><label class="model-name">{{model.modelName}}</label>
        </div>
        <asset-form [form]="form" [assetModel]="model"  [isLoading]="isLoading" />
    </div>

    <div footer class="theme-footer">
        <button class="btn-text-only" (click)="onClose()">{{ "common.cancel" | translate }}</button>
        <button class="btn-primary" (click)="onSubmit(model)" [disabled]="isInvalid || isLoading">
            <spinner size="small" *ngIf="isLoading"></spinner>
            {{ "common.save" | translate }}
        </button>
    </div>
</side-panel>
