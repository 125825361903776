<div class="sidebar" [@slideInOut]="menuState">
    <div class="header">
        <img src="/assets/icons/logo.png" alt="image"  class="logo" [class.visible]="menuState === 'in'" (click)="router.navigate(['/'])">
        <icon iconName="keyboard_double_arrow_left" size="medium" (click)="toggleMenu()" [class.visible]="menuState === 'in'" id='collapse'></icon>
        <icon iconName="keyboard_double_arrow_right"  size="medium" (click)="toggleMenu()" [class.visible]="menuState === 'out'"></icon>
    </div>
      <div *ngFor="let item of navItem" class="nav-item" [class.active]="item.id === activeNavItem" (click)="router.navigate([item.navPath])">
        <icon [iconName]="item.icon" [matTooltip]="item.label| translate" size="small" ></icon>
       <span [class.visible]="menuState === 'in'">{{item.label | translate}}</span>
      </div>
</div>
