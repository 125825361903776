import { Injectable } from '@angular/core';
import { Loading, onSuccess, RemoteData } from '@core/models/remote-data/remote-data.model';
import { BehaviorSubject } from 'rxjs';
import { InspectionCreationRequest } from '@core/services/inspections/inspection-request.model';
import { InspectionService } from '@core/services/inspections/inspection.service';
import { InspectionHistoryItem } from '@core/models/inspections/inspection.model';

@Injectable({
  providedIn: 'root',
})
export class InspectionHistoryStateService {
  private inspectionHistorySubject$ = new BehaviorSubject<RemoteData<InspectionHistoryItem[]>>(Loading());
  public inspectionHistory$ = this.inspectionHistorySubject$.asObservable();

  constructor(private inspectionService: InspectionService) {}

  public fetchAndUpdate(assetId: string) {
    this.inspectionService.getAssetInspectionHistory(assetId)
      .subscribe(data => {
        this.inspectionHistorySubject$.next(data);
      });
  }

  public createInspectionRequest(assetId: string, payload: InspectionCreationRequest) {
    return this.inspectionService
      .createInspectionRequest(payload)
      .pipe(
        onSuccess(() => this.fetchAndUpdate(assetId)),
      );
  }

  public deleteInspectionTask(assetId: string, inspectionId: string, inspectionTaskId: string) {
    return this.inspectionService
      .deleteInspectionTask(assetId, inspectionId, inspectionTaskId)
      .pipe(onSuccess(() => this.fetchAndUpdate(assetId)));
  }
}
