      <assets-list-header/>
      <assets-filter-bar/>
      <div class="table-wrapper"  >
         <ag-grid-angular
            class="ag-theme-alpine flex data-table"
            [columnDefs]="columns"
            [defaultColDef]="defaultColDef"
            [alwaysShowHorizontalScroll]="true"
            [pagination]="paginationEnabled"
            [paginationPageSize]="LIMIT"
            [cacheBlockSize]="LIMIT"
            [maxBlocksInCache]="3"
            [rowModelType]="'infinite'"
            [rowSelection]="'multiple'"
            [suppressRowClickSelection]="true"
            [getRowId]="getRowId"
            (selectionChanged)="onSelectionChange()"
            [blockLoadDebounceMillis]="0"
            [noRowsOverlayComponentParams]="noRowsOverlayParams"
            [noRowsOverlayComponent]="noRowsOverlayComponent"
            [overlayLoadingTemplate]="overlayLoading"
            [suppressFieldDotNotation]="true"
            (gridReady)="onGridReady($event)"
            (cellClicked)="onCellClicked($event)"
        ></ag-grid-angular>
      </div>
