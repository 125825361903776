import { InspectionHistoryGroupItem, InspectionHistoryItem, InspectionType } from '../models/inspections/inspection.model';
import { InspectionHistoryResponse } from '../services/inspections/inspection-history.response';
import { parseDateOnly } from './date-only/date-only.mapper';

export const toInspectionHistoryItem = (response: InspectionHistoryResponse): InspectionHistoryItem[] =>
  response.inspectionTasks.map(i => ({
    inspectionTaskId: i.id,
    inspectionId: response.id,
    name: response.name,
    startDate: response.startDate ? parseDateOnly(response.startDate) : undefined,
    dueDate: response.dueDate ? parseDateOnly(response.dueDate) : undefined,
    status: i.status === 'Sent' ? 'InProgress': 'Completed',
    inspectionType: i.inspectionType as InspectionType,
    completedDate: i.completedDate ? new Date(i.completedDate) : undefined,
    assetId: i.assetId,
    unitName: i.unitName,
    sectionName: i.sectionName,
    equipmentId: i.equipmentId,
  }));

export const toInspectionHistoryGroupItem = (response: InspectionHistoryResponse): InspectionHistoryGroupItem => ({
  ...response,
  inspectionId: response.id,
  startDate: response.startDate ? parseDateOnly(response.startDate) : undefined,
  dueDate: response.dueDate ? parseDateOnly(response.dueDate): undefined,
  inspectionTasks: response.inspectionTasks.map(i => ({
    inspectionTaskId: i.id,
    status: i.status === 'Sent' ? 'InProgress': 'Completed',
    inspectionType: i.inspectionType as InspectionType,
    assetId: i.assetId,
    equipmentId: i.equipmentId,
    unitName: i.unitName,
    sectionName: i.sectionName,
    completedDate: i.completedDate ? new Date(i.completedDate) : undefined,
  })),
});
