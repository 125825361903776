import { Injectable } from '@angular/core';
import { FileProgress } from '@core/services/file/file-progress';
import { HttpService } from '@core/services/http/http.service';
import { ConfigService } from '@shared/services';
import { catchError, of, scan } from 'rxjs';
import { Section } from '../models/attachment-sections.model';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  private attachmentsEndpoint = 'api/v1/attachments';

  constructor(private httpClient: HttpService, private config: ConfigService) {}

  public uploadAttachment(assetId: string, sectionId: string, files: File[], section: Section) {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file, file.name));
    formData.append('section', toRequestSection(section));

    return this.httpClient.upload(`${this.config.apiUrl}/api/v1/assets/${assetId}/sections/${sectionId}/attachments`, formData)
      .pipe(scan(FileProgress.calculateFileProgress, FileProgress.IN_PROGRESS(0)))
      .pipe(catchError(_ => of(FileProgress.FAILURE)));
  }

  public deleteAttachment(filePath: string) {
    return this.httpClient.delete({ endpoint: `${this.config.apiUrl}/${this.attachmentsEndpoint}`, body: { filePath } });
  }
}

type SectionRequest = 'Common' | 'Drawing' | 'UTT' | 'VT';

const toRequestSection = (section: Section): SectionRequest => {
  switch (section) {
    case 'common': return 'Common';
    case 'utt': return 'UTT';
    case 'vt': return 'VT';
    case 'drawing': return 'Drawing';
  }
};
