import { Injectable } from '@angular/core';

import { of, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AuthService } from '@core/services/auth/auth.service';
import { AuthActions } from '.';

@Injectable()
export class AuthEffects {
  public initializeLogin$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.initializeLogin),
    switchMap(() =>
      this.authService.isUserLogged().pipe(
        map((isAuthenticated: boolean) => isAuthenticated ? AuthActions.loginSuccess() : AuthActions.login()),
        catchError(() => of(AuthActions.login())),
      ),
    ),
  ));

  public login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.login),
    tap(() => this.authService.authenticate()),
  ), { dispatch: false });

  public logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    tap(() => this.authService.logout()),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
  ) { }
}
