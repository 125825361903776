import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

const OPTIONS_CONTAINER_CLASS = '.options-container';
const OPTION_CLASS = '.option';

export type Option<T> = { label: string, value: T, iconLeft?: string };

@Component({
  standalone: true,
  template: '',
})
export class DropdownComponent<T> implements OnInit, OnDestroy, OnChanges {
  @Input() public control: FormControl<T | null> = new FormControl();
  @Input() public options: Option<T | null>[] = [];
  public displayedOptions: Option<T | null>[] = [];
  @Output() public readonly optionSelected = new EventEmitter<T | null>();

  @Input() public canBeEmpty = false;
  @Input() public placeholder: string = 'select a value..';
  @Input() public selectFieldID = 'select-field';

  public isOpen = false;
  public selectedOptionIndex: number | null = null;

  private listenToOutsideClick = (event: MouseEvent) => {
    const select = document.querySelector(`#${this.selectFieldID}`);
    if (this.isOpen && !select?.contains(event.target as Element)) {
      this.toggleDropdown();
    }
  };

  public ngOnChanges(_: SimpleChanges): void {
    this.displayedOptions = this.canBeEmpty ? [{ label: this.placeholder, value: null }, ...this.options]: this.options;
  }

  public ngOnInit(): void {
    document.addEventListener('click', this.listenToOutsideClick);
  }

  public ngOnDestroy(): void {
    document.removeEventListener('click', this.listenToOutsideClick);
  }

  public toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  public get displayedValue() {
    return this.options.find(opt => opt.value === this.control.value)?.label ?? this.placeholder;
  }

  private updateValue(value: T | null) {
    if (value !== this.control.value) {
      this.control.patchValue(value);
      this.optionSelected.emit(value);
    }
  }

  public onOptionClicked(value: T | null) {
    if (value !== this.control.value) {
      this.updateValue(value);
    }
    this.toggleDropdown();
  }

  public selectCurrentOption(): void {
    if (this.selectedOptionIndex !== null) {
      const value = this.options[this.selectedOptionIndex].value;
      this.updateValue(value);
    }
    this.toggleDropdown();
  }

  public onKeydown(event: KeyboardEvent) {
    const optionsList = document.querySelector(OPTIONS_CONTAINER_CLASS);
    const optionsEl = optionsList?.querySelectorAll(OPTION_CLASS);
    const currentIndex = this.selectedOptionIndex == null ? -1 : this.selectedOptionIndex;

    if (event.key === 'Enter') {
      return this.selectCurrentOption();
    }

    if (optionsEl === undefined || optionsList === undefined) {
      return;
    }

    if (/[a-zA-Z]/.test(event.key)) {
      this.selectedOptionIndex = this.options.findIndex(el =>
        el.label.startsWith(event.key.toUpperCase()) || el.label.startsWith(event.key));
      this.scrollIntoView(optionsEl[this.selectedOptionIndex]);
      event.preventDefault();
    }

    if (event.key === 'ArrowUp' && optionsEl.length > 0) {
      this.selectedOptionIndex = Math.max(0, currentIndex - 1);

      this.scrollIntoView(optionsEl[this.selectedOptionIndex]);
      event.preventDefault();
    }

    if (event.key === 'ArrowDown' && optionsEl.length > 0) {
      this.selectedOptionIndex = Math.min(optionsEl.length - 1, currentIndex + 1);
      this.scrollIntoView(optionsEl[this.selectedOptionIndex]);
      event.preventDefault();
    }
  }

  public isActive(option: Option<T | null>, index: number) {
    return index === this.selectedOptionIndex || option.value === this.control.value;
  }

  private scrollIntoView(element?: Element) {
    try {
      element?.scrollIntoView({ block: 'nearest' });
    } catch (_) {}
  }
}
