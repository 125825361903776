// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging: false,
  hmr: false,
  i18n: {
    locales: ['fr-CA', 'en-US'],
    languages: ['fr', 'en'],
    fallback: 'fr-CA',
    defaultLocale: 'en-US',
    storageKey: 'adm_user_lang',
    header: 'X-Lang',
  },
  api: {
    baseUrl: 'https://adm-api.olympus-ims-dev.com',
    origin: 'https://adm.olympus-ims-dev.com',
    osc: 'https://app.olympus-ims-dev.com',
  },
  appInsights: {
    instrumentationKey: '6bcae040-8081-4a61-9da0-1c79e150eefe',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
