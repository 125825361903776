import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SelectedAsset } from '../models/selected-asset.model';

@Injectable({
  providedIn: 'root',
})
export class InspectionRequestStateService {
  private inspectionRequestSubject = new BehaviorSubject<SelectedAsset[]>([]);

  public state$ = this.inspectionRequestSubject.asObservable();

  public setInspectedAssets(assets: SelectedAsset[]) {
    this.inspectionRequestSubject.next(assets);
  }
}
