import { Params } from '@angular/router';

import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import * as AuthReducer from './auth/auth.reducer';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params?: Params;
  data: { [key: string]: string };
}

export interface AdmState {
  auth: AuthReducer.AuthState;
  router: RouterReducerState<RouterStateUrl>;
}

export const AdmReducers = {
  auth: AuthReducer.authReducer,
  router: routerReducer,
};

export * from './auth/auth.reducer';
