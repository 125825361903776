import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { onFailure, onSuccess } from '@core/models/remote-data/remote-data.model';

import { Toast, ToastService } from '@core/services/toast/toast-service.service';

import { DateExt } from '@core/date';
import { InspectionCreationRequest } from '@core/services/inspections/inspection-request.model';
import { InspectionService } from '@core/services/inspections/inspection.service';
import { SelectedAsset } from '../../models/selected-asset.model';
import { CreateInspectionMultipleForm, InspectionTask, InspectionTypeForm } from '../../models/create-inspection-multiple.model';
import { InspectionRequestStateService } from '../../services/inspection-request-state.service';
import { toInspectionTaskRequest } from './inspection-task.mapper';
import { RequestInspectionFormComponent } from './request-inspection-form/request-inspection-form.component';
import { InspectedSection } from '@core/models/inspections/inspection.model';

@Component({
  selector: 'request-inspection-modal',
  standalone: true,
  imports: [
    CommonModule, ModalComponent, SpinnerComponent, TranslateModule,
    RequestInspectionFormComponent,
  ],
  templateUrl: './request-inspection-modal.component.html',
  styleUrls: ['./request-inspection-modal.component.scss'],
})
export class RequestInspectionModalComponent {
  public form: FormGroup<CreateInspectionMultipleForm>;
  public isOpen = false;
  public isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private inspectionRequestService: InspectionService,
    private toastService: ToastService,
    private inspectionRequestStateService: InspectionRequestStateService,
  ) { }

  public initForm(selectedAssets: SelectedAsset[]) {
    this.setForm(new Map());
    this.inspectionRequestService.getInspectedSections(selectedAssets.map(a => a.id))
      .pipe(onSuccess(sections => this.setForm(this.defaultInspectionTypeValue(sections))))
      .subscribe();
  }

  private setForm(inspectionTypes: Map<InspectedSection, InspectionTask>) {
    this.form = this.formBuilder.group({
      name: this.formBuilder.control('', { validators: Validators.required, updateOn: 'blur' }),
      startDate: new FormControl<Date| null>(null, { validators: Validators.required, updateOn: 'blur' }),
      dueDate: new FormControl<Date| null>(null, { validators: Validators.required, updateOn: 'blur' }),
      inspectionTypes: new FormControl<InspectionTypeForm>(inspectionTypes),
    });
  }

  private MissingInspectionTask(values: InspectionTypeForm) {
    return [...values].find(([_, tasks]) => !tasks.utt && !tasks.vt);
  }

  private defaultInspectionTypeValue(inspectedSections: InspectedSection[]) {
    const map = new Map<InspectedSection, InspectionTask>();
    inspectedSections.forEach(a => map.set(a, { utt: true, vt: true }));

    return map;
  }

  public getControl(name: keyof CreateInspectionMultipleForm) {
    return this.form.get(name) as FormControl;
  };

  public open(selectedAssets: SelectedAsset[]) {
    this.initForm(selectedAssets);
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }

  public confirm() {
    const formValue = this.form.value;

    if (this.form.invalid || !formValue.startDate || !formValue.dueDate || !formValue.inspectionTypes) {
      return;
    }

    if (!DateExt.isBefore(formValue.startDate, formValue.dueDate)) {
      return this.getControl('dueDate').setErrors({ invalidDateRange: true });
    }

    if (this.MissingInspectionTask(formValue.inspectionTypes)) {
      return this.getControl('inspectionTypes').setErrors({ noInspectionType: true });
    }

    const request: InspectionCreationRequest = {
      inspectionTasks: toInspectionTaskRequest(formValue.inspectionTypes),
      name: formValue.name ?? '',
      startDate: formValue.startDate,
      endDate: formValue.dueDate,
    };

    this.sendRequest(request);
  }

  private sendRequest(request: InspectionCreationRequest) {
    this.isLoading = true;
    this.inspectionRequestService.createInspectionRequest(request)
      .pipe(
        onFailure(() => this.toastService.add(Toast.error('inspectionRequest.creationFailure'))),
        onSuccess(() => this.toastService.add(Toast.success('inspectionRequest.creationSuccess'))),
      )
      .subscribe(() => {
        this.close();
        this.inspectionRequestStateService.setInspectedAssets([]);
        this.isLoading = false;
      });
  }
}
