import { HttpClient, HttpContext, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConfigService } from '@shared/services';
import { Failure, RemoteData, Success } from '../../models/remote-data/remote-data.model';

export type QueryParams = HttpParams | {
  [param: string]: string | number | boolean | readonly (string | number | boolean)[];
} | undefined;

export type Body = Record<string, unknown> | FormData | Blob;

type Options = {
  withCredentials?: boolean,
  reportProgress?: boolean,
  responseType?: 'json' | 'blob',
  context?: HttpContext,
};

export enum HttpMethod {
  get = 'get',
  post = 'post',
  patch = 'patch',
  put = 'put',
  delete = 'delete',
}

export interface ClientError {
  errorType?: string,
  status?: number,
  message?: string
}

interface RequestConfig<B = Body> {
  body?: B;
  params?: QueryParams;
  headers?: HttpHeaders;
  endpoint: string;
  token?: string;
  options?: Options
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly configService: ConfigService,
  ) { }

  public get<TResponse>({ endpoint, params }: RequestConfig): Observable<RemoteData<TResponse, ClientError>> {
    return this.perform(HttpMethod.get, endpoint, null, params, {});
  }

  public post<TResponse, TBody extends Body>({
    body,
    params,
    endpoint,
    options,
  }: RequestConfig<TBody>): Observable<RemoteData<TResponse, ClientError>> {
    return this.perform(HttpMethod.post, endpoint, body, params, options);
  }

  public put<TResponse, TBody extends Body>({
    body,
    params,
    endpoint,
  }: RequestConfig<TBody>): Observable<RemoteData<TResponse, ClientError>> {
    return this.perform(HttpMethod.put, endpoint, body, params);
  }

  public delete<TResponse = unknown>({ body, params, endpoint }: RequestConfig): Observable<RemoteData<TResponse, ClientError>> {
    return this.perform(HttpMethod.delete, endpoint, body, params);
  }

  public patch<TResponse, TBody extends Body>({
    body,
    params,
    endpoint,
  }: RequestConfig<TBody>): Observable<RemoteData<TResponse, ClientError>> {
    return this.perform(HttpMethod.patch, endpoint, body, params);
  }

  public perform<TRequest, TResponse>(
    method: HttpMethod,
    url: string,
    body: TRequest | undefined = undefined,
    params: QueryParams | undefined = undefined,
    options: Options = {},
  ): Observable<RemoteData<TResponse, ClientError>> {
    return this.httpClient.request<TResponse>(method.toUpperCase(), url, {
      ...options,
      responseType: 'json',
      body,
      params,
      headers: this.getDefaultHeaders(),
    })
      .pipe(map(d => Success<TResponse>(d)))
      .pipe(catchError((error: HttpErrorResponse) => of(Failure<ClientError>({
        errorType: error?.error?.ErrorType || error?.error?.errorType,
        status: error.status,
        message: error?.error?.Title,
      }))),
      );
  }

  public download<TRequest>(
    method: HttpMethod,
    url: string,
    body: TRequest | undefined = undefined,
  ): Observable<RemoteData<ArrayBuffer, ClientError>> {
    return this.httpClient.request(method.toUpperCase(), url, {
      responseType: 'arraybuffer',
      headers: this.getDefaultHeaders(),
      body,
    })
      .pipe(map(d => Success<ArrayBuffer>(d)))
      .pipe(catchError((error: HttpErrorResponse) => of(Failure<ClientError>({
        errorType: arrayBufferToJson(error?.error)?.ErrorType,
        status: error.status,
        message: error.message,
      }))),
      );
  }

  public downloadWithProgress<TRequest>(
    url: string,
    body: TRequest | undefined = undefined,
  ): Observable<HttpEvent<Blob>> {
    return this.httpClient.request('POST', url, {
      responseType: 'blob',
      observe: 'events',
      body,
      reportProgress: true,
      headers: this.getDefaultHeaders(),
    });
  }

  public upload<TRequest>(
    url: string,
    body: TRequest | undefined = undefined,
  ): Observable<HttpEvent<unknown>> {
    return this.httpClient.request('POST', url, {
      responseType: 'blob',
      observe: 'events',
      body,
      reportProgress: true,
      headers: this.getDefaultHeaders(),
    });
  }

  public getDefaultHeaders(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
    });
  }
}

function arrayBufferToJson(buffer: ArrayBuffer) {
  const text = new TextDecoder().decode(buffer);

  return JSON.parse(text);
}
