import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

type ToggleState = 'right' | 'left';
export type TogglePropValue<T> = { text: string, value: T };

@Component({
  selector: 'toggle-switch',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent<L, R> implements OnInit {
  @Input() public right: { text: string, value: R };
  @Input() public left: { text: string, value: L };
  @Input() public initialValue: L | R;
  @Output() public readonly toggleChange = new EventEmitter<L | R>();

  public activeSide: ToggleState = 'right';

  public ngOnInit(): void {
    this.activeSide = this.initialValue === this.right.value ? 'right': 'left';
  }

  public rightClick() {
    this.activeSide = 'right';
    this.toggleChange.emit(this.right.value);
  }

  public leftClick() {
    this.activeSide = 'left';
    this.toggleChange.emit(this.left.value);
  }
}
