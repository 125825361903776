<modal [onClose]="onClose" [isLoading]="false" [customSize]="{height: 55, width: 60}">
  <div class="header" header>
    <h1 header>{{ "pcmsImport.title" | translate }}</h1>
  </div>

  <div content >
    <div *ngIf="!hasModelDefined">
      <inline-toast [toast]="infoToast"/>

      <input-field
        [id]="'asset-model-name'"
        [control]="assetModelName"
        [label]="'common.modelName' | translate"
        [placeholder]="'modelCreation.modelNamePlaceholder' | translate"/>
    </div>

    <file-dropzone
      dropzoneText="pcmsImport.equipDropzoneText"
      fileType="text/csv"
      [hasError]="importPcmsValidationState.equipmentFile.state === 'invalid'"
      [errorText]="ErrorCodeToText(importPcmsValidationState.equipmentFile.error)"
      [disabled]="importPcmsValidationState.equipmentFile.state === 'unprocessed'"
      tooltip="pcmsImport.tooltip.equipments"
      (file)="onEquipmentFileChange($event)"
      [matTooltipDisabled]="!unprocessedTooltipEnable(importPcmsValidationState.equipmentFile, equipmentFileControl)"
      [matTooltip]="'pcmsImport.unprocessedMessage' | translate"
      [fileName]="equipmentFileControl.value?.name ?? null"
      [id]="'Equipments'"
      />

    <file-dropzone
    dropzoneText="pcmsImport.circuitsDropzoneText"
    fileType="text/csv"
    [errorText]="ErrorCodeToText(importPcmsValidationState.circuitsFile.error)"
    [hasError]="importPcmsValidationState.circuitsFile.state === 'invalid'"
    [disabled]="importPcmsValidationState.circuitsFile.state === 'unprocessed'"
    tooltip="pcmsImport.tooltip.circuits"
    (file)="onCircuitFileChange($event)"
    [matTooltipDisabled]="!unprocessedTooltipEnable(importPcmsValidationState.circuitsFile, circuitsFileControl)"
    [matTooltip]="'pcmsImport.unprocessedMessage' | translate"
    [fileName]="circuitsFileControl.value?.name ?? null"
    [id]="'Circuits'"
    />

    <file-dropzone
      dropzoneText="pcmsImport.cmlDropzoneText"
      fileType="text/csv"
      tooltip="pcmsImport.tooltip.cmls"
      [hasError]="importPcmsValidationState.cmlFile.state === 'invalid'"
      [errorText]="ErrorCodeToText(importPcmsValidationState.cmlFile.error)"
      [disabled]="importPcmsValidationState.cmlFile.state === 'unprocessed'"
      (file)="onCmlFileChange($event)"
      [matTooltipDisabled]="!unprocessedTooltipEnable(importPcmsValidationState.cmlFile, cmlFileControl)"
      [matTooltip]="'pcmsImport.unprocessedMessage' | translate"
      [fileName]="cmlFileControl.value?.name ?? null"
      [id]="'Cmls'"
      />

    <file-dropzone
      fileType="application/zip"
      dropzoneText="pcmsImport.attachmentsDropzoneText"
      [hasError]="importPcmsValidationState.attachmentsZip.state === 'invalid'"
      [errorText]="ErrorCodeToText(importPcmsValidationState.attachmentsZip.error)"
      [disabled]="importPcmsValidationState.attachmentsZip.state === 'unprocessed'"
      (file)="onAttachmentPick($event)"
      [matTooltipDisabled]="!unprocessedTooltipEnable(importPcmsValidationState.attachmentsZip, attachmentsZipControl)"
      [matTooltip]="'pcmsImport.unprocessedMessage' | translate"
      [fileName]="attachmentsZipControl.value?.name ?? null"
      [id]="'Attachments'"
      />
  </div>

  <div class="footer" footer>
    <button class="btn-primary" [disabled]="importPcmsForm.invalid || isUploading" (click)="onImport()">
      <spinner *ngIf="isUploading" size='small'/>
      {{"pcmsImport.importBtn" | translate}}
    </button>
  </div>
</modal>
