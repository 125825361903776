import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { InspectionHistoryItem } from '@core/models/inspections/inspection.model';
import { RightSidePanelComponent } from '../shared/components/right-side-panel/right-side-panel.component';
import { UttInspectionResultComponent } from './utt-inspection-result/utt-inspection-result.component';
import { VtInspectionResultComponent } from './vt-inspection-result/vt-inspection-result.component';

@Component({
  selector: 'inspection-result-viewer',
  standalone: true,
  templateUrl: './inspection-result-viewer.component.html',
  imports: [CommonModule, VtInspectionResultComponent, RightSidePanelComponent, UttInspectionResultComponent],
})
export class InspectionResultViewerComponent {
  @ViewChild(VtInspectionResultComponent) public vtInspectionResult!: VtInspectionResultComponent;
  @Output() public readonly closeResult = new EventEmitter();

  public resultViewer: 'Utt' | 'Vt' | 'closed' = 'closed';

  public inspectionTask: InspectionHistoryItem | null;

  public onClose() {
    this.reset();
    this.closeResult.emit();
  }

  private reset() {
    this.inspectionTask = null;
    this.resultViewer = 'closed';
  }

  public viewResult(inspectionHistoryItem: InspectionHistoryItem) {
    this.reset();
    this.inspectionTask = inspectionHistoryItem;
    this.resultViewer = inspectionHistoryItem.inspectionType;
  }
}
