
<empty-state-message
[title]="title"
[subTitle]="message"
/>
<button  *ngIf="!hasFilteringApplied" (click)="toggleImportData()" class="btn-primary icon-button" >
<icon iconName="download"/>
    {{"assetsList.actions.importPcms"| translate}}
</button>

<import-pcms *ngIf="importDataModal" class="import-data" [onClose]="toggleImportData.bind(this)"/>