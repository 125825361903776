import { Injectable } from '@angular/core';
import { BehaviorSubject, startWith } from 'rxjs';
import { Loading, onSuccess, RemoteData } from '../../core/models/remote-data/remote-data.model';
import { Section } from '../../core/models/sections/section.model';
import { SectionsService } from './sections.service';

@Injectable({
  providedIn: 'root',
})
export class SectionsStateServiceService {
  private sectionsSubject$ = new BehaviorSubject<RemoteData<Section[]>>(Loading());
  public sections$ = this.sectionsSubject$.asObservable();

  constructor(private sectionsService: SectionsService) {}

  public fetchAndUpdate(assetId: string) {
    this.sectionsService
      .getSectionsByAsset(assetId)
      .pipe(startWith(Loading()))
      .subscribe(data => {
        this.sectionsSubject$.next(data);
      });
  }

  public addSection(name: string, assetId: string) {
    return this.sectionsService
      .addSection(name, assetId)
      .pipe(onSuccess(_ => this.fetchAndUpdate(assetId)));
  }
}
