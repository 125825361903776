import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@shared/components/icon/icon.component';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyStateMessageComponent } from '@shared/components/empty-state-message/empty-state-message.component';

@Component({
  selector: 'resource-not-found',
  standalone: true,
  templateUrl: './resource-not-found.component.html',
  styleUrls: ['./resource-not-found.component.scss'],
  imports: [CommonModule, IconComponent, TranslateModule, EmptyStateMessageComponent],
})
export class ResourceNotFoundComponent {
  constructor(private router: Router) {}

  public buttonProps = { label: 'homePage', callback: () => this.goBackToHome() };

  public goBackToHome() {
    this.router.navigate(['/']);
  }
}
