import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'tab',
  standalone: true,
  imports: [CommonModule],
  template: ' <ng-content *ngIf="active" class="tab"></ng-content>',
})
export class TabComponent {
  @Input() public tabName: string;
  @Input() public tabId: string = 'tab';

  public active = false;
}
