import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { DateExt } from '@core/date';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerComponent } from '@shared/components/date-picker/date-picker.component';
import { InputFieldComponent } from '@shared/components/input-field/input-field.component';
import { CreateInspectionMultipleForm, InspectionTask, InspectionTypeForm } from '../../../models/create-inspection-multiple.model';
import { InspectedSection } from '@core/models/inspections/inspection.model';

@Component({
  selector: 'request-inspection-form',
  standalone: true,
  imports: [
    CommonModule,
    DatePickerComponent,
    InputFieldComponent,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './request-inspection-form.component.html',
  styleUrls: ['./request-inspection-form.component.scss'],
})
export class RequestInspectionFormComponent {
  @Input() public form: FormGroup<CreateInspectionMultipleForm>;

  private defaultInspectionTask: InspectionTask = { vt: true, utt: true };

  public minDate = DateExt.today();

  public getControl(name: keyof CreateInspectionMultipleForm) {
    return this.form.get(name) as FormControl;
  };

  public getInspectionTaskValues() {
    const control = this.form.get('inspectionTypes') as FormControl<InspectionTypeForm>;

    return control.value;
  };

  public toggleAll(event: Event, type: 'vt' | 'utt') {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.getInspectionTaskValues().forEach(task => task[type] = isChecked);
    this.getControl('inspectionTypes').setErrors(null);
  }

  public toggleCheckbox(event: Event, inspectedSection: InspectedSection, type: 'vt' | 'utt') {
    const isChecked = (event.target as HTMLInputElement).checked;
    const currentValue = this.getInspectionTaskValues().get(inspectedSection) || this.defaultInspectionTask;
    this.getInspectionTaskValues().set(inspectedSection, { ...currentValue, [type]: isChecked });
    this.getControl('inspectionTypes').setErrors(null);
  }

  public errorMapper(e: ValidationErrors): string {
    if (e.required) {
      return 'form.required';
    }

    if (e.invalidDateRange) {
      return 'form.date.invalidDateRange';
    }

    return '';
  }
}
