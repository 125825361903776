<div class="page-header">
    <span class="breadcrumb active">{{ "Inspections" | translate }}</span>
</div>
<div class="content" [class.empty-state]="inspections$ | success | empty">
    <spinner *ngIf="inspections$ | loading" id="loading-indicator"/>
   
    <empty-state-message 
    *ngIf="inspections$ | success | empty"
    title="inspectionList.emptyState.title"
    subTitle="inspectionList.emptyState.message"
    />
   
    <div *ngIf="inspections$ | success | notEmpty as inspections " class="inspections">
        <div class="expand-buttons">
            <div class="expand-btn" (click)="expandAll()">
                {{ "common.expandAll" | translate }}
            </div>
            <div class="expand-btn" (click)="collapseAll()">
                {{ "common.collapseAll" | translate }}
            </div>
        </div>
        <div *ngFor="let inspectionGroup of inspections">
            <inspections-group  [inspectionGroup]="inspectionGroup" [activeTask]="activeTask" (viewResult)="onViewResult($event, inspectionGroup)"/>
        </div>
    </div>
</div>
<pagination class="pagination" *ngIf="pagination.total !== 0" [currentPage]="this.pagination.currentPage + 1" [pageCount]="pagination.pageCount()" (pageChange)="onPageChange($event)"/>

<inspection-result-viewer (closeResult)="resetActiveItem()">