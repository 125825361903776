import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { RouterStateSerializer } from '@ngrx/router-store';

import { RouterStateUrl } from '@core/store';

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route: ActivatedRouteSnapshot = routerState.root;
    let params = {};
    let data = {};

    while (route.firstChild) {
      route = route.firstChild;
      if (params && data) {
        params = { ...params, ...route.params };
        data = { ...data, ...(route.data || {}) };
      }
    }

    const { url, root: { queryParams } } = routerState;

    return { url, params, queryParams, data };
  }
}
