import { AfterContentInit, Component, ContentChildren, EventEmitter, Output, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabComponent } from './tab/tab.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'tab-group',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
})
export class TabGroupComponent implements AfterContentInit {
  @ContentChildren(TabComponent) public tabs: QueryList<TabComponent>;
  @Output() public readonly activeTab = new EventEmitter<string>();

  public ngAfterContentInit() {
    this.selectTab(this.tabs.first);
  }

  public selectTab(tab: TabComponent) {
    this.tabs.forEach(tabComponent => tabComponent.active = false);
    tab.active = true;
    this.activeTab.emit(tab.tabId);
  }
}
