import { ErrorCode } from './services/import-pcms.service';

export type PcmsFiles = 'Equipments' | 'Circuits' | 'Cmls' | 'Attachments';
export type ValidationState = { state: 'unprocessed' | 'idle' | 'invalid' | 'valid', error?: ErrorCode };

export type ImportPcmsValidationState = {
  equipmentFile: ValidationState
  cmlFile: ValidationState,
  circuitsFile: ValidationState,
  attachmentsZip: ValidationState
};

const initialState: ImportPcmsValidationState = {
  equipmentFile: { state: 'idle' },
  circuitsFile: { state: 'idle' },
  cmlFile: { state: 'idle' },
  attachmentsZip: { state: 'idle' },
};

const markAsInvalid = (file: PcmsFiles, error: ErrorCode): ImportPcmsValidationState => {
  switch (file) {
    case 'Equipments':
      return { ...initialState,
        equipmentFile: { state: 'invalid', error },
        circuitsFile: { state: 'unprocessed' },
        cmlFile: { state: 'unprocessed' },
        attachmentsZip: { state: 'unprocessed' },
      };

    case 'Circuits':
      return { ...initialState,
        equipmentFile: { state: 'valid' },
        circuitsFile: { state: 'invalid', error },
        cmlFile: { state: 'unprocessed' },
        attachmentsZip: { state: 'unprocessed' },
      };
    case 'Cmls':
      return { ...initialState,
        equipmentFile: { state: 'valid' },
        circuitsFile: { state: 'valid' },
        cmlFile: { state: 'invalid', error },
        attachmentsZip: { state: 'unprocessed' },
      };
    case 'Attachments':
      return { ...initialState,
        equipmentFile: { state: 'valid' },
        circuitsFile: { state: 'valid' },
        cmlFile: { state: 'valid' },
        attachmentsZip: { state: 'invalid', error },
      };
    default: return initialState;
  }
};

export const PCMSValidationState = {
  initialState,
  markAsInvalid,
};
