import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { catchError, concatMap, from, map, Observable, toArray } from 'rxjs';
import { ConfigService } from '@shared/services';
import { EmptySuccess, Failure, onFailure, RemoteData } from '../../models/remote-data/remote-data.model';

export enum FileUploadError {
  CHUNK_UPLOAD_FAIL = 'chunkUploadFail',
}

const CHUNK_SIZE_IN_BYTES = 100 * 1024 * 1024; // 100MB in bytes

@Injectable({
  providedIn: 'root',
})
export class FileChunkUploadService {
  constructor(private httpClient: HttpService, private config: ConfigService) { }

  private splitFileIntoChunks(file: File, chunkSize: number): Blob[] {
    const chunks: Blob[] = [];
    let offset = 0;

    while (offset < file.size) {
      const chunk = file.slice(offset, offset + chunkSize);
      chunks.push(chunk);
      offset += chunkSize;
    }

    return chunks;
  }

  public uploadZipFile(attachmentsZip: File | null): Observable<RemoteData<unknown, FileUploadError>> {
    const chunks = attachmentsZip ? this.splitFileIntoChunks(attachmentsZip, CHUNK_SIZE_IN_BYTES) : [];

    const sendHttpRequest = (chunk: Blob, index: number) => this.httpClient.post(
      { endpoint: `${this.config.apiUrl}/api/v1/assets/UploadChunks`,
        body: chunk,
        params: { fileName: attachmentsZip?.name ?? '', chunkSize: CHUNK_SIZE_IN_BYTES, chunkNumber: index },
      },
    );

    return from(chunks)
      .pipe(
        concatMap((value, index) => sendHttpRequest(value, index)),
        onFailure((_) => {
          // stop execution if any of the upload fails
          throw new Error();
        }),
        toArray(),
        map(_ => EmptySuccess()),
        catchError(async (_) => Failure(FileUploadError.CHUNK_UPLOAD_FAIL)),
      );
  }
}
