import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputFieldComponent } from '@shared/components/input-field/input-field.component';
import { SidePanelComponent } from '@shared/components/side-panel/side-panel.component';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { IconComponent } from '@shared/components/icon/icon.component';
import { onSuccess } from '@core/models/remote-data/remote-data.model';
import { AssetModelCreationMapper } from '@core/mappers/asset-model-creation.mapper';
import { AssetModelStateService } from '@core/services/assetModel/asset-model-state.service';
import { AssetModelService } from '@core/services/assetModel/asset-model.service';
import { EQUIPMENT_ID, EQUIPMENT_TYPE, UNIT_NAME } from '@core/models/assetModel/asset-model.model';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { ToastInlineComponent } from '@shared/components/toast/inline-toast.component';
import { notNull } from '@core/maybe-model';

type AssetModelField = { name: string };

export interface AssetModelCreationForm {
  assetModelName: string;
  assetModelFields: AssetModelField[];
}

@Component({
  selector: 'asset-model-creation',
  standalone: true,
  templateUrl: './asset-model-creation.component.html',
  styleUrls: ['./asset-model-creation.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    InputFieldComponent,
    SidePanelComponent,
    IconComponent,
    SpinnerComponent,
    ToastInlineComponent,
  ],
})
export class AssetModelCreationComponent {
  @Input() public onClose: () => void = () => {};

  public form: FormGroup;
  public requiredFields = [EQUIPMENT_TYPE, EQUIPMENT_ID, UNIT_NAME];
  public isLoading = false;
  public toast: Toast | undefined = undefined;

  constructor(
    private formBuilder: FormBuilder,
    private assetModelService: AssetModelService,
    private assetModelStateService: AssetModelStateService,
    private toastService: ToastService,
  ) {
    this.form = this.formBuilder.group(
      {
        assetModelName: ['', Validators.required],
        assetModelFields: this.formBuilder.array([]),
        newAssetModelField: [''],
      },
    );
  }

  public get assetModelFields() {
    return this.form.get('assetModelFields') as FormArray<FormControl<AssetModelField | null>>;
  }

  public get assetModelName() {
    return this.form.get('assetModelName') as FormControl;
  }

  public get newAssetModelField() {
    return this.form.get('newAssetModelField') as FormControl;
  }

  public onDelete(i: number) {
    this.assetModelFields.removeAt(i);
  }

  public handleKeyboardEvents(event: KeyboardEvent) {
    this.toast = undefined;
    if (event.key === 'Enter') {
      event.preventDefault();
      this.onAdd();
    }
  }

  public onAdd() {
    const newField: string = this.newAssetModelField.value.trim();
    if (newField === '') {
      return;
    }

    if (this.assetModelFields.value.filter(notNull).map(_ => _.name).includes(newField) || this.requiredFields.includes(newField)) {
      this.toast = Toast.error('modelCreation.duplicateFieldError');
    } else {
      this.assetModelFields.push(this.formBuilder.control<AssetModelField>({ name: newField }));
      this.form.patchValue({ newAssetModelField: '' });
    }
  }

  public onSubmit() {
    this.isLoading = true;
    const request = AssetModelCreationMapper.toRequest(this.form.value, UNIT_NAME, EQUIPMENT_ID, EQUIPMENT_TYPE);
    this.assetModelService
      .createAssetModel(request)
      .pipe(
        onSuccess(() => {
          this.toastService.add(Toast.success('modelCreation.success'));
          this.onClose();
        }))
      .subscribe(() => {
        this.assetModelStateService.fetchAndUpdate();
        this.isLoading = false;
      });
  }
}
