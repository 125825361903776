import { AttachmentResponse } from '@core/models/asset/assets.response';
import { AttachmentSections } from '../../asset-page/section-page/models/attachment-sections.model';
import { SectionDetail } from '@core/models/sections/section.model';
import { SectionDetailResponse } from '@core/models/sections/section.response';

export const toSectionDetails = (response: SectionDetailResponse): SectionDetail => (
  { ...response,
    attachments: AttachmentSections
      .Create<AttachmentResponse>(response.attachments)
      .map(a => ({ ...a, lastModifiedDate: new Date(a.lastModifiedDate) })),
  }
);
