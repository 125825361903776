import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { ModalComponent } from '../modal/modal.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ConfirmationModalService } from './confirmation-modal.service';

export type ConfirmationModalData = {
  title: string,
  message: string
  messageParameters?: object,
  onConfirm: () => void
};

@Component({
  selector: 'confirmation-modal',
  standalone: true,
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  imports: [CommonModule, ModalComponent, TranslateModule, SpinnerComponent],
})
export class ConfirmationModalComponent {
  public loading$ = of(false);
  public confirmationProps$: Observable<ConfirmationModalData | null> = of(null);

  constructor(private confirmationService: ConfirmationModalService) {
    this.confirmationProps$ = this.confirmationService.isOpen$;
    this.loading$ = this.confirmationService.isLoading$;
  }

  public dismiss() {
    this.confirmationService.close();
  }
}
