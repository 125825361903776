import { Injectable } from '@angular/core';
import { mapFailure, RemoteData } from '@core/models/remote-data/remote-data.model';
import { HttpService } from '@core/services/http/http.service';
import { ConfigService } from '@shared/services';
import { Observable } from 'rxjs';
import { CML, CMlCreationForm } from '../models/cml.model';

export type CMLUpdateForm = {
  inspectionPoints: string[]
};

export type CMLCreationError = 'duplicateCML' | 'unknown';
export enum CMLUpdateError {
  DuplicateInspectionPoint = 'CMLInvalidException',
  Unknown = 'addInspectionPoint',
}

@Injectable({
  providedIn: 'root',
})
export class CmlsService {
  private cmlsEndpoint = (assetId: string, sectionId: string) => `api/v2/assets/${assetId}/sections/${sectionId}/cmls`;

  constructor(
    private httpClient: HttpService,
    private config: ConfigService,
  ) { }

  public createCML(assetId: string, sectionId: string, form: CMlCreationForm): Observable<RemoteData<unknown, CMLCreationError>> {
    return this.httpClient
      .post({ endpoint: `${this.config.apiUrl}/${this.cmlsEndpoint(assetId, sectionId)}`, body: form })
      .pipe(mapFailure((e) => e.errorType === 'DuplicateException' ? 'duplicateCML' : 'unknown'));
  }

  public getCMLs(assetId: string, sectionId: string): Observable<RemoteData<CML[]>> {
    return this.httpClient
      .get({ endpoint: `${this.config.apiUrl}/${this.cmlsEndpoint(assetId, sectionId)}` });
  }

  public editCML(assetId: string, sectionId: string, cmlId: string, form: CMLUpdateForm): Observable<RemoteData<CML>> {
    return this.httpClient
      .put({ endpoint: `${this.config.apiUrl}/${this.cmlsEndpoint(assetId, sectionId)}/${cmlId}`, body: form });
  }

  public deleteCML(assetId: string, sectionId: string, cmlId: string): Observable<RemoteData> {
    return this.httpClient
      .delete({ endpoint: `${this.config.apiUrl}/${this.cmlsEndpoint(assetId, sectionId)}/${cmlId}` });
  }
}
