
<div class="toasts">
    <inline-toast  *ngIf="csvFileName" [toast]="toast(csvFileName)"/>
    <inline-toast  *ngIf="zipFileName" [toast]="toast(zipFileName)"/>
</div>

<div class="warning-container">
    <div class="warning-title">
        <icon iconName="warning" class="warning-icon" size="small"/><h2>{{"assetImport.completedWithWarnings.title" | translate:{count: errors.length} }}</h2>
    </div>
    <div class="warning-message">{{"assetImport.completedWithWarnings.message" | translate}}</div>
    <div *ngFor="let error of groupByError(errors) | keyvalue">
        <div class="error-type">{{ msg(error.key) | translate}}</div>
        <div class="fileName" *ngFor="let item of error.value">{{ item.details }}</div>
    </div>
</div>
