<right-side-panel (closePanel)="onClose()" width="80rem">
  <div header class="header">
    <h2>{{inspectionTask.name}}</h2>
  </div>
  <div content class="content" #content>
    <tab-group class="tab-group" (activeTab)="onActiveTabChange($event)">
      <tab [tabName]="'uttResult.report'" [tabId]="reportTabId">
        <spinner *ngIf="pdfSrc$ | loading" class="spinner"/>
        <div *ngIf="uttInspectionResult | success">
          <div class="canvas-container" *ngIf="pdfSrc$ | success as pdfSrc">
            <app-pdf-viewer
              [pdfSrc]="pdfSrc"
              [pageNumber]="currentPage"
              (PdfDocumentLoad)="onDocumentLoad($event)"
            />
          </div>
          <empty-state-message *ngIf="(pdfSrc$ | success) === undefined"
                               [title]="'uttResult.noReport.title'"
                               [subTitle]="'uttResult.noReport.subMessage'"
                               class="empty-state"
          />

        </div>
      </tab>
      <tab [tabName]="'uttResult.measurements'">
        <spinner *ngIf="uttInspectionResult | loading" class="spinner"/>

        <div *ngIf="uttInspectionResult | success as uttResult">
          <div class="buttons-container">
            <div class="expand-buttons">
              <div class="expand-btn" (click)="expandAll()">
                  {{ "common.expandAll" | translate }}
              </div>
              <div class="expand-btn" (click)="collapseAll()">
                  {{ "common.collapseAll" | translate }}
              </div>
            </div>
            <toggle-switch *ngIf="unitSystem$ | async as us"
            [right]="toggleRight"
            [left]="toggleLeft"
             [initialValue]="us"
             (toggleChange)="onUnitSystemChange($event)"
             />
          </div>
            <div class="cml-collapsible" *ngFor="let cml of uttResult.cmls">
              <collapsible [defaultOpen]="true" [headerColor]="'dark'">
                <div header-content class="inspection-row header-row" *ngIf="unit$ | async as unit">
                  <div class="row-item">{{"uttResult.cml" | translate}} {{cml.location}}</div>
                  <div class="row-item">{{"uttResult.previousValue" | translate : {unit} }}</div>
                  <div class="row-item">{{"uttResult.latestValue" | translate: {unit} }}</div>
                  <div class="row-item">{{"uttResult.delta" | translate : {unit} }}</div>
                  <div class="row-item">{{"uttResult.corrosionRate" | translate : {unit} }} <icon class="info-icon" [matTooltip]="'uttResult.tooltip' | translate" iconName="info_outlined" size="medium"/></div>
                </div>

                <div *ngFor="let point of cml.inspectionPoints" class="bordered-row">
                  <div class="inspection-row content-row">
                    <div class="row-item">{{point.name}}</div>
                    <div class="row-item">{{point.previousMeasurement | measure | async }}</div>
                    <div class="row-item">{{point.currentMeasurement | measure | async }}</div>
                    <div class="row-item">{{point.delta | measure | async }}</div>
                    <div class="row-item">{{point.shortTermCorrosionRate | measure | async}}</div>
                  </div>
                </div>
              </collapsible>
            </div>
        </div>
      </tab>
      <tab [tabName]="'uttResult.files'" >
          <utt-result-files *ngIf="uttInspectionResult | success as uttResult" [files]="uttResult.attachments"/>
      </tab>
  </tab-group>
  </div>
  <div footer class="pagination">
    <pagination *ngIf="showPagination()" [pageCount]="pageCount" [currentPage]="currentPage" (pageChange)="onPageChange($event)"/>
  </div>
</right-side-panel>
