<panel title="sectionsConfiguration.title">
    <button class="btn-primary" (click)="addSection()" header>
        <icon iconName="add"/>
        {{ "sectionsConfiguration.addSection" | translate }}
    </button>
   <div content class="content">
        <empty-state-message
        *ngIf="sections$ | async | failure"
        title="sections.errors.title"
        subTitle="sections.errors.subTitle"
        />

        <spinner *ngIf="sections$ | async | loading"/>

        <inline-input
        class="section-input"
        *ngIf="sectionsState"
        [state]="sectionsState"
        [control]="sectionsControls"
        placeholder="sectionsConfiguration.placeholder"
        error="sectionsConfiguration.errors.duplicate"
        (valueConfirmed)="onConfirmSection($event)"
        (remove)="removeSectionControl()"/>

        <div *ngIf="sections$ | async  | success as sections">
            <div *ngFor="let section of sections; let index=index" class="section">
                <span>{{section.name}}</span>
            </div>
        </div>
    </div>
</panel>
