<form [formGroup]="form" id="form">

  <ng-container *ngIf="!hasModelDefined">

    <inline-toast [toast]="infoToast"/>

    <input-field
      [id]="'asset-model-name'"
      [control]="assetModelName"
      [label]="'common.modelName' | translate"
      [placeholder]="'modelCreation.modelNamePlaceholder' | translate"/>

  </ng-container>

  <file-dropzone
    fileType="text/csv"
    dropzoneText="assetImport.uploadCSV"
    errorText="assetImport.dragAndDropErrorCsv"
    [hasError]="csvControl.hasError('incorrect')"
    (file)="onFilePick($event)"
    [fileName]="csvControl.value?.name"
  />

  <file-dropzone
    fileType="application/zip"
    dropzoneText="assetImport.uploadAttachment"
    errorText="assetImport.dragAndDropErrorZip"
    [hasError]="attachmentControl.hasError('incorrect')"
    (file)="onAttachmentPick($event)"
    [fileName]="attachmentControl.value?.name"
  />

  <inline-toast *ngIf="!!toast" [toast]="toast" class="error-message"/>

</form>
