type AttachmentSectionsProps<T> = {
  utt?: T[],
  vt?: T[],
  common?: T[],
  drawing?: T
};

export type Section = 'utt' | 'vt' | 'common' | 'drawing';

export class AttachmentSections<T> {
  public utt: T[] = [];
  public vt: T[] = [];
  public common: T[] = [];
  public drawing: T | undefined = undefined;

  constructor(props: AttachmentSectionsProps<T>) {
    this.common = props.common ?? [];
    this.utt = props.utt ?? [];
    this.vt = props.vt ?? [];
    this.drawing = props.drawing;
  };

  public static Create<T>(sections: AttachmentSectionsProps<T>) {
    return new AttachmentSections(sections);
  }

  public static CreateEmpty() {
    return new AttachmentSections({
      utt: [],
      vt: [],
      common: [],
    });
  }

  public map<B>(f: (t: T) => B) {
    return new AttachmentSections({
      utt: this.utt.map(f),
      vt: this.vt.map(f),
      common: this.common.map(f),
      drawing: this.drawing ? f(this.drawing): undefined,
    });
  }

  public from(section: Section) {
    switch (section) {
      case 'common':
        return this.common;
      case 'utt':
        return this.utt;
      case 'vt':
        return this.vt;
      case 'drawing':
        return this.drawing ? [this.drawing] : [];
    }
  }

  public update(attachments: T[], section: Section) {
    switch (section) {
      case 'common':
        return new AttachmentSections({
          utt: this.utt,
          vt: this.vt,
          common: attachments,
          drawing: this.drawing,
        });
      case 'utt':
        return new AttachmentSections({
          utt: attachments,
          vt: this.vt,
          common: this.common,
          drawing: this.drawing,
        });
      case 'vt':
        return new AttachmentSections({
          utt: this.utt,
          vt: attachments,
          common: this.common,
          drawing: this.drawing,
        });
      case 'drawing':
        return new AttachmentSections({
          utt: this.utt,
          vt: this.vt,
          common: this.common,
          drawing: attachments[0] ?? undefined,
        });
    }
  }
}
