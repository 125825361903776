<div [id]="id">
  <input type="file"
         [accept]="fileType"
         class="file-input"
         (change)="onFilePick($event)"
         #fileUpload >

  <div class="file-upload" *ngIf="fileName === null"
       [class.hover]="fileDragDrop.hover"
       (dragover)="fileDragDrop.onDragOver($event)"
       (dragenter)="fileDragDrop.onDragEnter()"
       (dragleave)="fileDragDrop.onDragLeave()"
       (drop)="fileDragDrop.onDrop($event)">
    <icon class="upload-icon" iconName='upload_file' size='xlarge'/>
    <div>
      <div class="bold label">
        {{dropzoneText | translate}}
        <icon *ngIf="tooltip" class="info-icon" [matTooltip]="tooltip | translate" iconName="info_outlined" size="medium"/>
      </div> 
      <span class="browse-file" (click)="fileUpload.click()">{{ "assetImport.browse" | translate }}</span><span>{{" "}} {{ "assetImport.dragAndDrop" | translate }}</span>
    </div>
  </div>
</div>

<div class="file-imported" *ngIf="fileName && !hasError" [class.disabled]="disabled" >
  <div class="left-container bold" >
    <icon class="file-icon" iconName='description' size='xlarge' [class.disabled]="disabled"/>
    {{ fileName }}
  </div>
  <div class="right-actions">
    <div  *ngIf="hasError" class="error">
      <span>{{ errorText | translate }}</span>
      <icon  iconName='error' size='medium'/>
    </div>
    <icon (click)="removeFile()" class="delete-button" iconName='close' size='medium'/>
  </div>
</div>


<div class="file-imported error" *ngIf="fileName && hasError" >
  <div class="left-container" >
    <icon class="file-icon" iconName='description' size='xlarge' [class.error]="hasError" [class.disabled]="disabled"/>
   <div>
      <span class="bold">{{ fileName }}</span>
      <div class="error-text">{{errorText | translate}}</div>
   </div>
  </div>
  <div class="right-actions">
    <span class="upload-new-file" (click)="fileUpload.click()">{{"pcmsImport.uploadNewFile" | translate }}</span>
    <icon (click)="removeFile()" class="delete-button" iconName='close' size='medium'/>
  </div>
</div>

