import { Injectable } from '@angular/core';
import { toSectionDetails } from '@core/mappers/sections.mapper';
import { mapFailure, mapSuccess, RemoteData } from '@core/models/remote-data/remote-data.model';
import { Section, SectionDetail } from '@core/models/sections/section.model';
import { SectionDetailResponse } from '@core/models/sections/section.response';
import { HttpService } from '@core/services/http/http.service';
import { ConfigService } from '@shared/services';
import { Observable } from 'rxjs';

export enum CreateSectionError {
  Duplicate = 'duplicate',
  Unknown = 'unknown',
}

@Injectable({
  providedIn: 'root',
})
export class SectionsService {
  private sectionsEndpoint = assetId => `api/v1/assets/${assetId}/sections`;

  constructor(private httpClient: HttpService, private config: ConfigService) { }

  public getSectionsByAsset(assetId: string): Observable<RemoteData<Section[]>> {
    return this.httpClient
      .get<Section[]>({ endpoint: `${this.config.apiUrl}/${this.sectionsEndpoint(assetId)}` });
  }

  public addSection(name: string, assetId: string): Observable<RemoteData<unknown, CreateSectionError>> {
    return this.httpClient
      .post({ endpoint: `${this.config.apiUrl}/${this.sectionsEndpoint(assetId)}`, body: { name } })
      .pipe(mapFailure(e => {
        switch (e.status) {
          case 409 : return CreateSectionError.Duplicate;
          default: return CreateSectionError.Unknown;
        }
      }));
  }

  public getSectionById(assetId: string, sectionId: string): Observable<RemoteData<SectionDetail>> {
    return this.httpClient
      .get<SectionDetailResponse
    >({ endpoint: `${this.config.apiUrl}/${this.sectionsEndpoint(assetId)}/${sectionId}` }).pipe(mapSuccess(toSectionDetails));
  }
}
