<div class="files-header">
  <h3>{{ "assetDetails.attachments.drawing" | translate }}</h3>
  <button class="btn-secondary icon-button" (click)="attachmentsUploadDrawing.click()">
    <icon iconName="attach_file"/>
    {{ attachments.drawing === undefined
    ? ("assetDetails.addDrawing" | translate)
    : ("assetDetails.replaceDrawing" | translate) }}
  </button>
</div>
<span *ngIf="!attachments.drawing"
      class="empty-attachments-message">{{ "assetDetails.emptyFileMessage" | translate }}</span>
<attachment *ngIf="attachments.drawing" [last]="true" #files [attachment]="attachments.drawing" class="drawing"
            (deleteAttachment)="confirmDeleteAction($event)"/>

<div class="divider-bar"></div>

<div class="files-header">
  <h3>{{ "assetDetails.attachments.utt" | translate }}</h3>
  <button class="btn-secondary icon-button" (click)="attachmentsUploadUtt.click()">
    <icon iconName="attach_file"/>
    {{ "assetDetails.addAttachment" | translate }}
  </button>
</div>
<span *ngIf="attachments.utt.length === 0 "
      class="empty-attachments-message">{{ "assetDetails.emptyFileMessage" | translate }}</span>
<attachment *ngFor="let attachment of attachments.utt; let last = last" [last]="last" #files [attachment]="attachment"
            (deleteAttachment)="confirmDeleteAction($event)"/>

<div class="divider-bar"></div>

<div class="files-header">
  <h3>{{ "assetDetails.attachments.vt" | translate }}</h3>
  <button class="btn-secondary icon-button" (click)="attachmentsUploadVt.click()">
    <icon iconName="attach_file"/>
    {{ "assetDetails.addAttachment" | translate }}
  </button>
</div>
<span *ngIf="attachments.vt.length === 0"
      class="empty-attachments-message">{{ "assetDetails.emptyFileMessage" | translate }}</span>
<attachment *ngFor="let attachment of attachments.vt; let last = last" [last]="last" #files [attachment]="attachment"
            (deleteAttachment)="confirmDeleteAction($event)"/>

<div class="divider-bar"></div>

<div class="files-header">
  <h3>{{ "assetDetails.attachments.common" | translate }}</h3>
  <button class="btn-secondary icon-button" (click)="attachmentsUploadCommon.click()">
    <icon iconName="attach_file"/>
    {{ "assetDetails.addAttachment" | translate }}
  </button>
</div>
<span *ngIf="attachments.common.length === 0"
      class="empty-attachments-message">{{ "assetDetails.emptyFileMessage" | translate }}</span>
<attachment *ngFor="let attachment of attachments.common; let last = last" [last]="last" #files [attachment]="attachment"
            (deleteAttachment)="confirmDeleteAction($event)"/>


<input
  type="file"
  multiple
  class="file-input"
  (change)="attachFile($event, 'common')"
  #attachmentsUploadCommon
  id="attachmentsUploadCommon"
>
<input
  type="file"
  multiple
  class="file-input"
  (change)="attachFile($event, 'utt')"
  #attachmentsUploadUtt
  id="attachmentsUploadUtt"
>
<input
  type="file"
  multiple
  class="file-input"
  (change)="attachFile($event, 'vt')"
  #attachmentsUploadVt
  id="attachmentsUploadVt"
>
<input
  type="file"
  class="file-input"
  (change)="attachDrawing($event)"
  #attachmentsUploadDrawing
  id="attachmentsUploadDrawing"
>

