export type PaginatedResponse<T> = {
  pagination: {
    limit: number,
    offset: number,
    total: number
  };
  data: T[]
};

export type Paginated<T> = {
  total: number,
  offset: number,
  data: T[]
};

export type Pagination = {
  limit: number,
  offset: number
};

export const Paginated = <T>(t: T[], total: number, offset: number = 0): Paginated<T> => ({
  data: t,
  total,
  offset,
});
