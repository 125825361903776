<form [formGroup]="form">

    <inline-toast  [toast]="infoToast" />

    <div class="fields">
        <select-field
        *ngIf="this.getControl('equipmentId') as control"
        [control]="control"
        [options]="defaultOptions"
        [placeholder]="'assetImport.selectPlaceholder'"
        label="assetImport.equipmentId"
        [selectFieldID]="'equipmentId'"
        />

        <select-field
        *ngIf="this.getControl('unitName') as control"
        [control]="control"
        [options]="defaultOptions"
        [placeholder]="'assetImport.selectPlaceholder'"
        label="assetImport.unitName"
        [selectFieldID]="'unit-name'"
        />

        <select-field
        *ngIf="this.getControl('equipmentType') as control"
        [control]="control"
        [options]="defaultOptions"
        [placeholder]="'assetImport.selectPlaceholder'"
        label="assetImport.equipmentType"
        [selectFieldID]="'equipment-type'"
        />
    </div>

    <inline-toast *ngIf="this.form.hasError('incorrect')" [toast]="errorToast"/>

    <inline-toast *ngIf="!!toast" [toast]="toast" />

</form>
