import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { onFailure, onSuccess } from '@core/models/remote-data/remote-data.model';
import { AgGridModule } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, ColumnApi, GridApi, GridReadyEvent, IDatasource, IGetRowsParams, IRowNode } from 'ag-grid-community';
import { Subscription, tap } from 'rxjs';
import { AssetModel } from '@core/models/assetModel/asset-model.model';
import { AssetModelStateService } from '@core/services/assetModel/asset-model-state.service';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { LoadingRemoteDataPipe, SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { AssetsFilterBarComponent } from './components/assets-filter-bar/assets-filter-bar.component';
import { AssetsListHeaderComponent } from './components/assets-list-header/assets-list-header.component';
import { CellRendererComponent } from './components/cell-renderer/cell-renderer.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { SelectedAsset } from './models/selected-asset.model';
import { CustomField } from '@core/models/asset/custom-field.model';
import { AssetListStateService, PAGINATION_LIMIT } from './services/asset-list-state.service';
import { FilterStateService } from './services/filters-state.service';
import { hasActiveFilter } from '@core/models/filters.type';
import { InspectionRequestStateService } from './services/inspection-request-state.service';
import { CustomCheckboxHeaderComponent } from './components/custom-checkbox-header/custom-checkbox-header.component';
import { AssetListItem } from '@core/models/asset/asset.model';
import { Paginated } from '../core/models/pagination.type';

const COLUMN_CHECKBOX = '__checkbox__';
const COLUMN_ID = '__id__';

@Component({
  selector: 'assets-list',
  templateUrl: './assets-list.component.html',
  styleUrls: ['./assets-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule, SpinnerComponent, AssetsListHeaderComponent,
    LoadingRemoteDataPipe, AgGridModule, SuccessRemoteDataPipe, AssetsFilterBarComponent, CellRendererComponent,
  ],
  providers: [FilterStateService, AssetListStateService, InspectionRequestStateService],
})
export class AssetsListComponent implements OnDestroy {
  private assetStateSubscription: Subscription;
  private modelStateSubscription: Subscription;
  private filtersSubscription: Subscription;
  private inspectionRequestSubscription: Subscription;

  private gridColumnApi: ColumnApi | undefined;
  public gridApi: GridApi | undefined;

  public columns: ColDef[] = [];
  private equipmentIdColumn: string;
  public LIMIT = PAGINATION_LIMIT;
  public noRowsOverlayComponent = EmptyStateComponent;
  public noRowsOverlayParams = { hasFilterApplied: false };
  public overlayLoading = '<div class="skeletons-overlay"></div>';

  public paginationEnabled = false;

  private selectedAssets: SelectedAsset[] = [];

  public defaultColDef: ColDef = {
    sortable: true,
    unSortIcon: true,
  };

  constructor(
    private assetModelStateService: AssetModelStateService,
    private assetsStateService: AssetListStateService,
    private inspectionRequestStateService: InspectionRequestStateService,
    private router: Router,
    private filtersState: FilterStateService,
  ) {
    this.modelStateSubscription = this.assetModelStateService.state$
      .pipe(onSuccess(model => {
        this.equipmentIdColumn = model.equipmentId;
        this.columns = this.computeColumns(model);
      },
      ))
      .pipe(onFailure(_ => this.columns = []))
      .subscribe(() => this.gridApi?.purgeInfiniteCache());

    this.assetStateSubscription = this.assetsStateService
      .state$
      .subscribe(() => this.gridApi?.purgeInfiniteCache());

    this.filtersSubscription = this.filtersState
      .state$
      .subscribe((filters) => {
        this.noRowsOverlayParams = { hasFilterApplied: hasActiveFilter(filters) };
        this.gridApi?.purgeInfiniteCache();
        this.gridApi?.paginationGoToFirstPage();
      });

    this.inspectionRequestSubscription = this.inspectionRequestStateService.state$
      .pipe(tap(selected => {
        if (selected.length === 0) {
          this.gridApi?.deselectAll();
        }
      }))
      .subscribe(state => this.selectedAssets = state);
  }

  public ngOnDestroy() {
    this.assetStateSubscription?.unsubscribe();
    this.modelStateSubscription?.unsubscribe();
    this.filtersSubscription?.unsubscribe();
    this.inspectionRequestSubscription?.unsubscribe();
  }

  public onSelectionChange() {
    let newSelectedAssets = this.selectedAssets;

    const AddOrRemoveNodeFromSelection = (node: IRowNode) => {
      const currentAsset = { id: node.data.__id__, equipmentId: node.data[this.equipmentIdColumn] };

      if (node.isSelected()) {
        if (!newSelectedAssets.find(a => a.id === currentAsset.id)) {
          newSelectedAssets.push(currentAsset);
        }
      } else {
        newSelectedAssets = newSelectedAssets.filter(a => a.id !== currentAsset.id);
      }
    };

    this.gridApi?.forEachNode(node => {
      AddOrRemoveNodeFromSelection(node);
    });

    this.inspectionRequestStateService.setInspectedAssets(newSelectedAssets);
  }

  // This is needed to assign a unique id to each rows instead of using the index
  public getRowId(params: { data: { __id__: string } }) {
    return params.data.__id__.toString();
  }

  public computeColumns(model: AssetModel): ColDef[] {
    if (model.assetModelFields.length === 0) {
      return [];
    }

    const requiredFields = [...new Set(AssetModel(model).requiredModelFields)].map(field => ({ field }));
    const customFields = [
      ...requiredFields,
      ...AssetModel(model).notRequiredModelFields().map(field => ({ field: field.name })),
    ];

    const customFieldsColumns = customFields.map((column: ColDef) =>
      ({ ...column, cellRenderer: CellRendererComponent }));

    return [
      {
        field: COLUMN_CHECKBOX,
        cellClass: 'first-column',
        sortable: false,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        headerComponent: CustomCheckboxHeaderComponent,
        maxWidth: 60,
      },
      { field: COLUMN_ID, hide: true },
      ...customFieldsColumns,
    ];
  }

  private computeRows(assets: AssetListItem[]) {
    return assets.map((data) => ({ __id__: data.id, ...CustomField.toObject(data.customFields) }));
  }

  public onGridReady(params: GridReadyEvent<AssetListItem[]>) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;

    this.gridApi.setDatasource(this.dataSource);
    this.autoSizeColumns();
  }

  private autoSizeColumns() {
    const allColumnIds = this.gridColumnApi?.getColumns()?.map(column => column.getId()).filter(id => id !== COLUMN_ID) ?? [];
    this.gridColumnApi?.autoSizeColumns(allColumnIds, false);
  }

  public onCellClicked(cell: CellClickedEvent) {
    const assetId = cell.data.__id__;
    if (cell.column.getColId() === '__checkbox__') {
      return;
    }
    this.router.navigate(['/details', assetId]);
  }

  public dataSource: IDatasource = {
    getRows: (params: IGetRowsParams) => {
      this.gridApi?.showLoadingOverlay();
      const tableOrdering = params.sortModel.length > 0 ? params.sortModel[0] : { colId: '', sort: '' };
      this.assetsStateService
        .fetch({ limit: this.LIMIT, offset: params.startRow }, { sort: tableOrdering.colId, order: tableOrdering.sort })
        .pipe(
          onSuccess((data) => {
            params.successCallback(this.computeRows(data.data), data.total);
            this.showPagination(data);
            this.showEmptyState(data);
          }),
          onFailure(_ => {
            this.gridApi?.hideOverlay();
            params.successCallback([], 0);
            params.failCallback();
          }),
        )
        .subscribe();
    },
  };

  private showPagination(data: Paginated<AssetListItem>) {
    this.paginationEnabled = data.total !== 0;
  }

  private showEmptyState(data: Paginated<AssetListItem>) {
    const noSearchResult = this.noRowsOverlayParams.hasFilterApplied && data.total === 0;
    const noData = data.total === 0 && this.columns.length === 0;

    return noSearchResult || noData ? this.gridApi?.showNoRowsOverlay() : this.gridApi?.hideOverlay();
  }
}
