import { Injectable } from '@angular/core';
import { Attachment } from '@core/models/attachments/attachment.model';
import { Loading, match } from '@core/models/remote-data/remote-data.model';
import { BehaviorSubject, map, startWith } from 'rxjs';
import { AttachmentSections } from '../models/attachment-sections.model';
import { SectionsService } from '../../services/sections.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsStateService {
  private attachmentsSubject$ = new BehaviorSubject<AttachmentSections<Attachment>>(AttachmentSections.CreateEmpty());

  public attachments$ = this.attachmentsSubject$.asObservable();
  public drawing$ = this.attachmentsSubject$
    .asObservable()
    .pipe(map(attachments => attachments.drawing));

  constructor(private sectionService: SectionsService) {}

  public fetchAndUpdate(assetId: string, sectionId: string) {
    this.sectionService
      .getSectionById(assetId, sectionId)
      .pipe(startWith(Loading()))
      .pipe(match(s => s.attachments, () => AttachmentSections.CreateEmpty()))
      .subscribe(data => this.attachmentsSubject$.next(data));
  }
}
