import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { uniquesBy } from '../../list-extra';

export type Toast = { message: string, type: 'success'| 'error'| 'warning' | 'default' | 'info', autoDismiss: boolean };

const success = (message: string): Toast => ({ message, type: 'success', autoDismiss: true });
const error = (message: string): Toast => ({ message, type: 'error', autoDismiss: false });
const warning = (message: string): Toast => ({ message, type: 'warning', autoDismiss: false });
const primary = (message: string): Toast => ({ message, type: 'default', autoDismiss: true });
const info = (message: string): Toast => ({ message, type: 'info', autoDismiss: true });

export const Toast = {
  success,
  error,
  primary,
  warning,
  info,
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastQueue = new BehaviorSubject<Toast[]>([]);
  public toasts$ = this.toastQueue.asObservable();

  public add(toast: Toast, duration: number = 7000) {
    this.toastQueue.next(uniquesBy([...this.toastQueue.value, toast], 'message'));

    if (!toast.autoDismiss) {
      return;
    }

    timer(duration).subscribe(() => {
      this.clear(toast);
    });
  }

  public clear(toast: Toast) {
    const updatedQueue = this.toastQueue.value.filter((t) => t.message !== toast.message);
    this.toastQueue.next(updatedQueue);
  }
}
