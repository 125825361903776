<spinner *ngIf="inspectionHistory$ | async | loading"/>

<ng-container *ngIf="inspectionHistory$ | async | success as sections" >
    <div *ngIf="!notEmpty(sections); else emptyView" class="content">
        <div class="section">
            <span class="section-label">{{ "inspectionHistory.latestResults" | translate }}</span>
            <span class="empty-section-message" *ngIf="sections.latestResult | empty">{{ "inspectionHistory.emptySection.latestResult" | translate}}</span>
            <inspection-card-header *ngIf="sections.latestResult | notEmpty"/>
            <inspection-card  
            *ngFor="let i of sections.latestResult" 
            [active]="activeItem === i" 
            [inspectionHistory]="i" 
            [equipmentId]="equipmentId | async" 
            (viewResult)="onViewResult(i)"
            (deleteInspection)="onDeleteInspectionTask(i.assetId, i.inspectionId, i.inspectionTaskId)"
            />
        </div>
        <br/>
        <div class="section" #latestResults>
            <span class="section-label">{{ "inspectionHistory.inspectionTasks"| translate }}</span> 
            <span class="empty-section-message" *ngIf="sections.others | empty">{{ "inspectionHistory.emptySection.others" | translate}}</span>
            <inspection-card-header  *ngIf="sections.others | notEmpty"/>
            <inspection-card  
            *ngFor="let i of sections.others" 
            [active]="activeItem === i" 
            [inspectionHistory]="i" 
            [equipmentId]="equipmentId | async"
             (viewResult)="onViewResult(i)"
             (deleteInspection)="onDeleteInspectionTask(i.assetId, i.inspectionId, i.inspectionTaskId)"
             />
        </div>
    </div>
</ng-container>

<div *ngIf="inspectionHistory$ | async | failure" class="empty-state">
    <h1>
        {{ "errors.unexpectedError" | translate }}</h1>
    <span>{{ "errors.unexpectedErrorMessage" | translate }}</span>
</div>

<ng-template #emptyView>
    <empty-state-message
    class="empty-state"
    [title]="'inspectionHistory.emptyHistory.title'"
    [subTitle]="'inspectionHistory.emptyHistory.message'"
    />
</ng-template>

<inspection-result-viewer (closeResult)="resetActiveItem()" />
