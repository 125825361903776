
<div class="box" [id]="id">
    <div class="collapsible-header"  [class.expanded]="!collapsed" [ngClass]="headerColor">
        <div (click)="toggleCollapse()" class="clickable left-container">
            <icon (click)="toggleCollapse()" class="clickable" iconName="expand_more" *ngIf="collapsed && expandable" size="small" (click)="toggleCollapse()"/>
            <icon (click)="toggleCollapse()" class="clickable" iconName="expand_less" *ngIf="!collapsed && expandable" size="small" (click)="toggleCollapse()"/>
        </div>
      <div class="header-content"><ng-content select="[header-content]"/></div>
    </div>
    <div class="collapsible-content" [@collapse]="collapsed">
        <div class="content">
            <ng-content></ng-content>
        </div>
    </div>
</div>
