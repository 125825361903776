import { AttachmentSections } from '../../asset-page/section-page/models/attachment-sections.model';
import { AssetDetail, AssetListItem } from '@core/models/asset/asset.model';
import { AssetDetailsResponse, AssetResponse, AttachmentResponse } from '../models/asset/assets.response';

const toAsset = (assetResponse: AssetResponse): AssetListItem => assetResponse;

const toAssetDetails = (assetResponse: AssetDetailsResponse): AssetDetail => (
  { ...assetResponse,
    attachments: AttachmentSections
      .Create<AttachmentResponse>(assetResponse.attachments)
      .map(a => ({ ...a, lastModifiedDate: new Date(a.lastModifiedDate) })),
  }
);

export const AssetMapper = {
  toAsset,
  toAssetDetails,
};
