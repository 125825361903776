import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@shared/components/icon/icon.component';
import { Action } from '@shared/components/action-menu/action.model';
import { createPopper, Instance, Placement } from '@popperjs/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'action-menu',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslateModule],
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
})
export class ActionMenuComponent implements OnInit, OnDestroy {
  @Input() public actions: Action[];
  @Input() public actionMenuId = 'action-menu';
  @Input() public menuPositioning: Placement = 'auto';
  @ViewChild('anchor', { static: true }) public handle!: ElementRef;
  @ViewChild('dropdown', { static: true }) public menu!: ElementRef;
  private popper: Instance | null = null;

  public actionMenuActive = false;

  public toggleMenu() {
    if (this.actionMenuActive) {
      this.hideMenu();
    } else {
      this.showMenu();
    }
  }

  private showMenu() {
    this.actionMenuActive = true;
    this.popper = createPopper(this.handle.nativeElement, this.menu.nativeElement, {
      placement: this.menuPositioning,
    });
  }

  private hideMenu() {
    this.actionMenuActive = false;
    this.popper?.destroy();
  }

  public handleAction(action: Action, event: Event) {
    if (action.disabled) {
      return;
    }

    event.preventDefault();
    this.hideMenu();
    action.callback();
  }

  private listenToOutsideClick = (event: MouseEvent) => {
    const actionMenu = document.querySelector(`#action-menu-${this.actionMenuId}`);
    if (this.actionMenuActive && !actionMenu?.contains(event.target as Element)) {
      this.hideMenu();
    }
  };

  public ngOnInit(): void {
    document.addEventListener('click', this.listenToOutsideClick);
  }

  public ngOnDestroy(): void {
    document.removeEventListener('click', this.listenToOutsideClick);
  }
}
