import { ConfigService } from './config/config.service';
import { I18nService } from './i18n/i18n.service';

export const sharedServices = [
  ConfigService,
  I18nService,
];

export * from './config/config.service';
export * from './i18n/i18n.service';
