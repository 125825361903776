<collapsible
[defaultOpen]="false"
>
<div header-content class="header">
   <div class="right">
       <div class="inspection-name">{{inspectionGroup.name}}</div>
       <status-indicator [status]="groupStatus(inspectionGroup)">
       <span>{{completedCount(inspectionGroup)}}</span>
       </status-indicator>
   </div>

    <div class="dates">
        <div class="date" *ngIf="inspectionGroup.dueDate">
            <span>{{ "inspectionList.startDate" | translate}}</span>
            <span>{{inspectionGroup.startDate | formatDate: 'MMM d, yyyy' }}</span>
        </div>
        <div class="date" *ngIf="inspectionGroup.dueDate">
            <span>{{ "inspectionList.dueDate" | translate}}</span>
            <span>{{inspectionGroup.dueDate | formatDate: 'MMM d, yyyy' }}</span>
        </div>
    </div>
</div>

<div class="row header">
    <span class="row-item">{{ "inspectionHistory.equipmentId" | translate}}</span>
    <span class="row-item">{{ "inspectionHistory.unitName" | translate}}</span>
    <span class="row-item">{{ "inspectionHistory.sectionName" | translate}}</span>
    <span class="row-item">{{ "inspectionHistory.inspectionType" | translate}}</span>
    <span class="row-item">{{ "inspectionHistory.status" | translate}}</span>
    <span class="row-item">{{ "inspectionHistory.completedDate" | translate}}</span>
    <span class="row-item"></span>
</div>
<div class="row" *ngFor="let task of inspectionGroup.inspectionTasks" [class.active]="task === activeTask">
    <span class="row-item link" (click)="navigateToAsset(task.assetId)">{{ task.equipmentId | translate}}</span>
    <span class="row-item">{{ task.unitName | translate}}</span>
    <span class="row-item">{{ task.sectionName | translate}}</span>
    <span class="row-item">{{ "inspectionHistory." + task.inspectionType | translate}}</span>
    <span class="row-item">
        {{ "common.status." + task.status | translate }}
    </span>
    <span class="row-item">{{ task.completedDate | formatDate: 'MMM d, yyyy'  }}</span>
    <div class="row-item results">
        <button (click)="onViewResult(task)" [ngClass]="{visible: task.status === 'Completed'}" class="btn-secondary">{{"inspectionHistory.viewResults" | translate }}</button>
    </div>
</div>
</collapsible>
