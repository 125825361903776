import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfirmationModalData } from './confirmation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalService {
  private isOpenSubject = new BehaviorSubject<ConfirmationModalData | null>(null);
  public isOpen$: Observable<ConfirmationModalData | null> = this.isOpenSubject.asObservable();

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();

  public open(props: ConfirmationModalData) {
    this.isLoadingSubject.next(false);
    this.isOpenSubject.next(props);
  }

  public close() {
    this.isOpenSubject.next(null);
    this.isLoadingSubject.next(false);
  }

  public loading() {
    this.isLoadingSubject.next(true);
  }
}
