import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { AsyncPipe, NgClass, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0, transform: 'translateX(100%)' })),
      state('*', style({ opacity: 1, transform: 'translateX(0)' })),
      transition(':enter, :leave', animate('300ms ease-in-out')),
    ]),
  ],
  imports: [NgClass, NgFor, AsyncPipe, MatIconModule, TranslateModule, IconComponent],
})
export class ToastComponent {
  constructor(public toastService: ToastService) {}

  public trackBy(_: number, toast: Toast) {
    return toast.message;
  }

  public iconName(toast: Toast) {
    switch (toast.type) {
      case 'success':
        return 'check_circle';
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      default:
        return 'help';
    }
  }
}
