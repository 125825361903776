<modal [onClose]="close.bind(this)" [isLoading]="isLoading" *ngIf="isOpen" [customSize]="{height:55, width: 70}">
  <div class="header" header>
        <h1 header>
            {{ "inspectionRequest.createTitle" | translate}}
        </h1>
  </div>

  <ng-container content >
    <request-inspection-form [form]="form"/>
  </ng-container>

  <div class="footer" footer>
    <button class="btn-primary" (click)="confirm()" [disabled]="form.invalid || isLoading" >
      <spinner *ngIf="isLoading" size='small'/>
      {{ "common.send" | translate }}
    </button>
  </div>
</modal>
