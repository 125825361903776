import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { AssetModel } from '@core/models/assetModel/asset-model.model';
import { TranslateModule } from '@ngx-translate/core';
import { InputFieldComponent } from '@shared/components/input-field/input-field.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { AssetForm } from './asset-form.model';

@Component({
  selector: 'asset-form',
  standalone: true,
  templateUrl: './asset-form.component.html',
  styleUrls: ['./asset-form.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldComponent,
    SuccessRemoteDataPipe,
    SpinnerComponent,
    TranslateModule,
  ],
})
export class AssetFormComponent {
  @Input() public assetModel: AssetModel;
  @Input() public isLoading = false;

  @Input() public form: AssetForm;

  public getControlFor(name: string): FormControl | undefined {
    return this.form.find(control => control.controlName === name)?.control;
  }

  public isRequired(name: string) {
    return AssetModel(this.assetModel).isRequiredField(name);
  }

  public id(key: string) {
    return key.replace(/\s/g, '');
  }

  public errorMapper(e: ValidationErrors): string {
    if (e.required) {
      return 'form.required';
    }

    return '';
  }
}
