import { CommonModule } from '@angular/common';
import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { InspectionHistoryGroupItem, InspectionHistoryItem, InspectionTask } from '../core/models/inspections/inspection.model';
import { Loading, mapSuccess, onFailure, onSuccess, RemoteData } from '../core/models/remote-data/remote-data.model';
import { InspectionService } from '../core/services/inspections/inspection.service';
import { Toast, ToastService } from '../core/services/toast/toast-service.service';
import { InspectionResultViewerComponent } from '../inspection-result-viewer/inspection-result-viewer.component';
import { EmptyStateMessageComponent } from '../shared/components/empty-state-message/empty-state-message.component';
import { PaginationComponent } from '../shared/components/pagination/pagination.component';
import { SpinnerComponent } from '../shared/components/spinner/spinner.component';
import { empty, LoadingRemoteDataPipe, notEmpty, SuccessRemoteDataPipe } from '../shared/pipes/remote-data.pipe';
import { InspectionsGroupComponent } from './inspections-group/inspections-group.component';

const LIMIT = 10;

@Component({
  selector: 'inspections-list',
  standalone: true,
  templateUrl: './inspections-list.component.html',
  styleUrls: ['./inspections-list.component.scss'],
  imports: [CommonModule, TranslateModule, SuccessRemoteDataPipe,
    InspectionsGroupComponent, SpinnerComponent, LoadingRemoteDataPipe,
    EmptyStateMessageComponent, empty, notEmpty, InspectionResultViewerComponent, PaginationComponent],
})
export class InspectionsListComponent {
  @ViewChildren(InspectionsGroupComponent) public inspectionGroups: QueryList<InspectionsGroupComponent>;
  @ViewChild(InspectionResultViewerComponent) public inspectionResultViewer!: InspectionResultViewerComponent;

  public inspections$: RemoteData<InspectionHistoryGroupItem[]>= Loading();
  public activeTask: InspectionTask | undefined = undefined;
  public pagination = {
    currentPage: 0,
    total: 0,
    pageCount: () => Math.ceil(this.pagination.total/LIMIT),
  };

  constructor(private inspectionService: InspectionService, private toastService: ToastService) {
    this.getInspection();
  }

  private getInspection() {
    this.inspections$ = Loading();
    this.inspectionService
      .getAllInspectionHistory({
        limit: LIMIT,
        offset: LIMIT * this.pagination.currentPage,
      }).pipe(
        onFailure(() => this.toastService.add(Toast.error('inspectionList.errors.getList'))),
        onSuccess(p => this.pagination = { ...this.pagination, total: p.total }),
        mapSuccess(p => p.data))
      .subscribe(data => this.inspections$ = data);
  }

  public onPageChange(pageOffset: number) {
    this.pagination = { ...this.pagination, currentPage: this.pagination.currentPage + pageOffset };
    this.getInspection();
  }

  public collapseAll() {
    this.inspectionGroups.forEach(c => c.close());
  }

  public expandAll() {
    this.inspectionGroups.forEach(c => c.open());
  }

  public resetActiveItem() {
    this.activeTask = undefined;
  }

  public onViewResult(inspectionTask: InspectionTask, inspectionGroupItem: InspectionHistoryGroupItem) {
    const item: InspectionHistoryItem = {
      ...inspectionGroupItem,
      ...inspectionTask,
    };
    this.inspectionResultViewer.viewResult(item);
    this.activeTask = inspectionTask;
  }
}
