import * as DateFns from 'date-fns';

export type DateFormat = typeof DateOnly | typeof DateTime;

const DateOnly = 'MMM d, yyyy';
const DateTime = 'PPpp';

const formatUTCDateString = (date: string, dateFormat: DateFormat = DateTime) => {
  try {
    return DateFns.format(DateFns.parseISO(date), dateFormat);
  } catch (e) {
    return '';
  }
};

const formatDate = (date: Date, dateFormat: DateFormat = DateTime) => {
  try {
    return DateFns.format(date, dateFormat);
  } catch (e) {
    return '';
  }
};

const today = () => new Date();

const isBefore = (startDate: Date, endDate: Date) => DateFns.isBefore(startDate, endDate);

export const DateExt = {
  formatDate,
  formatUTCDateString,
  isBefore,
  today,
};
