<div class="app">
  <ng-container *ngIf="isAuthenticated$ | async; else welcomePage">
  <main class="app__container">
    <side-bar-nav></side-bar-nav>
    <router-outlet/>
    <confirmation-modal/>
    <toast/>
  </main>
  </ng-container>
</div>


<ng-template #welcomePage>
  <div class="welcomepage">
    <img src="/assets/icons/logo.png" alt="image" >
  </div>
</ng-template>