import { Component, Input } from '@angular/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { Theme } from '../../../../scss/core/theme';

@Component({
  selector: 'progress-circle',
  template: `
    <circle-progress
        [percent]="percentageComplete"
        [radius]="10"
        [outerStrokeWidth]="3"
        [outerStrokeColor]="color"
        [showZeroOuterStroke]="true"
        [showSubtitle]="false"
        [showUnits]="false"
        [showTitle]="false"
        [animation]="false"
        [showInnerStroke]="false"

    ></circle-progress>
  `,
  imports: [NgCircleProgressModule],
  standalone: true,
})
export class ProgressCircleComponent {
  public color = Theme.colors.primary;
  @Input() public percentageComplete = 0;
}
