import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Asset } from '@core/models/asset/asset.model';
import { AssetModel } from '@core/models/assetModel/asset-model.model';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { RightSidePanelComponent } from '@shared/components/right-side-panel/right-side-panel.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { LoadingRemoteDataPipe, SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { SectionAttachmentsComponent } from '../../section-page/section-attachments/section-attachments.component';
import { EditAssetComponent } from './edit-asset/edit-asset.component';

@Component({
  selector: 'asset-details',
  standalone: true,
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss'],

  imports: [
    CommonModule, RightSidePanelComponent,
    SuccessRemoteDataPipe,
    LoadingRemoteDataPipe,
    SpinnerComponent,
    TranslateModule,
    IconComponent,
    SectionAttachmentsComponent,
    EditAssetComponent,
  ],
})
export class AssetDetailsComponent {
  @Input() public asset: Asset;
  @Input() public editMode = false;
  @Output() public readonly modeChanged = new EventEmitter();

  @ViewChild('endOfFiles') public endOfFiles!: ElementRef;

  public get notRequiredFields() {
    return AssetModel(this.asset.model).notRequiredFields(this.asset.customFields);
  }

  public get requiredFields() {
    return [
      { name: this.asset.model.equipmentId, value: this.asset.equipmentId, subText: 'assetDetails.equipmentId' },
      { name: this.asset.model.equipmentType, value: this.asset.equipmentType, subText: 'assetDetails.equipmentType' },
      { name: this.asset.model.unitName, value: this.asset.unitName, subText: 'assetDetails.unitName' },
    ];
  }

  public editAsset() {
    this.editMode = !this.editMode;
  }

  public onCloseEdit() {
    this.editMode = false;
    this.modeChanged.emit();
  }
}
