<edit-asset  class="edit-asset" [asset]="asset"  (onclose)="onCloseEdit()" *ngIf="editMode"/>

<div  *ngIf="!editMode" class="scrollable-content">
    <div  *ngFor="let item of requiredFields">
        <div class="field">
            <div class="field-label">
                {{item?.name}}
                <div class="required-field-subtext">{{ item.subText | translate}}</div>
            </div>
            <div class="field-value">{{item?.value}}</div>
        </div>
    </div>
    <div *ngFor="let item of notRequiredFields">
        <div class="field">
            <div class="field-label">{{item.name}}</div>
            <div class="field-value">{{item.value}}</div>
        </div>
    </div>
</div>
