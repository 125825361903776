import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InspectionHistoryItem } from '@core/models/inspections/inspection.model';
import { andThen, isSuccess, Loading, onFailure, onSuccess, RemoteData, Success } from '@core/models/remote-data/remote-data.model';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { CollapsibleComponent } from '@shared/components/collapsible/collapsible.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { RightSidePanelComponent } from '@shared/components/right-side-panel/right-side-panel.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { MeasureConverterPipe } from '@shared/pipes/measure-converter/measure-converter.pipe';
import { LoadingRemoteDataPipe, SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { map, of } from 'rxjs';
import { UnitSystem } from '../../core/units/units';
import { TabGroupComponent } from '../../shared/components/tab-group/tab-group.component';
import { TabComponent } from '../../shared/components/tab-group/tab/tab.component';
import { ToggleSwitchComponent } from '../../shared/components/toggle-switch/toggle-switch.component';
import { UserPreferenceService } from '../../shared/services/user-preferences/user-preference.service';
import { UttInspectionResult } from '../models/utt-inspection-result.model';
import { InspectionResultService } from '../services/inspection-result.service';
import { UttResultFilesComponent } from './utt-result-files/utt-result-files.component';
import { EmptyStateMessageComponent } from '@shared/components/empty-state-message/empty-state-message.component';
import { IPdfDocumentLoad, PDFViewerComponent } from '@shared/components/pdf-viewer/pdf-viewer.component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { FileDownloadService } from '@core/services/file/file-download.service';

@Component({
  selector: 'utt-inspection-result',
  standalone: true,
  templateUrl: './utt-inspection-result.component.html',
  styleUrls: ['./utt-inspection-result.component.scss'],
  imports: [
    CommonModule, LoadingRemoteDataPipe, RightSidePanelComponent, SpinnerComponent,
    SuccessRemoteDataPipe, CollapsibleComponent, TranslateModule,
    TabComponent,
    TabGroupComponent,
    UttResultFilesComponent, IconComponent, MatTooltipModule,
    MeasureConverterPipe,
    ToggleSwitchComponent, EmptyStateMessageComponent, PDFViewerComponent, PaginationComponent,
  ],
})
export class UttInspectionResultComponent implements OnInit {
  @Output() public readonly closeViewer = new EventEmitter();
  @Input() public inspectionTask: InspectionHistoryItem;
  @ViewChildren(CollapsibleComponent) public collapsiblesComponents: QueryList<CollapsibleComponent>;

  public uttInspectionResult: RemoteData<UttInspectionResult> = Loading();
  public unitSystem$ = this.userPreferenceService.watchUnitSystem();
  public unit$ = this.unitSystem$.pipe(map(u => u === UnitSystem.IMPERIAL ? 'in' : 'mm'));

  public toggleLeft = { text: 'common.metrics', value: UnitSystem.INTERNATIONAL };
  public toggleRight = { text: 'common.imperial', value: UnitSystem.IMPERIAL };

  public pdfSrc$: RemoteData<string | undefined> = Loading();

  public pageCount = 1;
  public currentPage = 1;
  public activeTab: string;

  public reportTabId = 'report';

  constructor(
    private inspectionResultService: InspectionResultService,
    private toastService: ToastService,
    private userPreferenceService: UserPreferenceService,
    private fileDownloadService: FileDownloadService,
  ) {}

  public ngOnInit(): void {
    this.inspectionResultService
      .getUttInspectionResult(this.inspectionTask.inspectionTaskId)
      .pipe(
        onFailure(() => this.toastService.add(Toast.error('vtResult.errors.getResult'))),
        onSuccess(result => this.uttInspectionResult = Success(result)),
        andThen(result => this.getPdfSrc(result?.pdfReport?.filePath)),
      ).subscribe(pdf => {
        this.pdfSrc$ = pdf;
      });
  }

  public onClose() {
    this.closeViewer.emit();
  }

  public collapseAll() {
    this.collapsiblesComponents.forEach(c => c.close());
  }

  public expandAll() {
    this.collapsiblesComponents.forEach(c => c.open());
  }

  public onUnitSystemChange(u: UnitSystem) {
    this.userPreferenceService.setUnitSystem(u);
  }

  public onPageChange(pageIncrement: number) {
    this.currentPage = this.currentPage + pageIncrement;
  }

  public onActiveTabChange(tabId: string) {
    this.activeTab = tabId;
  }

  public showPagination() {
    return this.activeTab === this.reportTabId && isSuccess(this.pdfSrc$) && !!this.pdfSrc$.data;
  }

  public onDocumentLoad = (value: IPdfDocumentLoad) => {
    this.pageCount = value.pageCount;
    this.currentPage = 1;
  };

  private getPdfSrc(path: string | undefined) {
    return path ? this.fileDownloadService.downloadFile(path) : of(Success(undefined));
  }
}
