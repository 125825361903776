import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Filters } from '@core/models/filters.type';
import { Paginated, Pagination } from '@core/models/pagination.type';
import { Loading, onFailure, onSuccess, RemoteData } from '@core/models/remote-data/remote-data.model';
import { AssetListItem } from '@core/models/asset/asset.model';
import { AssetsService } from '@core/services/assets/assets.service';
import { FilterStateService } from './filters-state.service';
import { Ordering } from '@core/models/ordering.type';

type Options = { onSuccess?: () => void, onFailure?: () => void };

export const PAGINATION_LIMIT = 20;
export const INITIAL_PAGINATION = { limit: PAGINATION_LIMIT, offset: 0 };
export const DEFAULT_FILTERS = { query: '', fields: [] };

export const DEFAULT_ORDERING = { order: '', sort: '' };

@Injectable({
  providedIn: 'root',
})
export class AssetListStateService {
  private stateSubject = new BehaviorSubject<RemoteData<Paginated<AssetListItem>>>(Loading());
  public state$ = this.stateSubject.asObservable();

  private currentFilters: Filters = DEFAULT_FILTERS;
  private currentPagination: Pagination = INITIAL_PAGINATION;
  private currentOrdering: Ordering = DEFAULT_ORDERING;

  constructor(private assetService: AssetsService, private filterState: FilterStateService) {
    this.filterState.state$.subscribe(filter => {
      this.currentFilters = filter;
    });
  };

  public fetchAndUpdate(pagination: Pagination = this.currentPagination, options?: Options) {
    this.currentPagination = pagination;

    return this.assetService
      .getList(pagination, this.currentFilters)
      .pipe(onSuccess(() => options?.onSuccess?.()))
      .pipe(onFailure(() => options?.onFailure?.()))
      .subscribe(data => {
        this.stateSubject.next(data);
      });
  }

  public fetch(pagination: Pagination = this.currentPagination, ordering: Ordering = this.currentOrdering) {
    this.currentPagination = pagination;
    this.currentOrdering = ordering;

    return this.assetService.getList(pagination, this.currentFilters, ordering);
  }
}
