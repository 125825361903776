import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TabGroupComponent } from '@shared/components/tab-group/tab-group.component';
import { TabComponent } from '@shared/components/tab-group/tab/tab.component';
import { SectionAttachmentsComponent } from './section-attachments/section-attachments.component';
import { DrawingComponent } from './drawing/drawing.component';
import { UltrasonicThicknessComponent } from './ultrasonic-thickness/ultrasonic-thickness.component';

@Component({
  selector: 'section-page',
  standalone: true,
  templateUrl: './section-page.component.html',
  styleUrls: ['./section-page.component.scss'],
  imports: [
    CommonModule, TabGroupComponent, TabComponent,
    SectionAttachmentsComponent, UltrasonicThicknessComponent, TranslateModule,
    DrawingComponent],
})
export class SectionPageComponent {
  @Input() public sectionId = '';
  @Input() public assetId = '';

  public activeTabId = 'files';

  public onActiveTabChange(tabId: string) {
    this.activeTabId = tabId;
  }
}
