<modal [onClose]="onClose" [isLoading]="isLoading">
        <div class="header" header>
            <h1 header>{{ "assetImport.title" | translate }}</h1>
            <span *ngIf="!hasModelDefined && steps[step].number" class="bold">
                {{ "assetImport.step" | translate}} {{steps[step].number}} {{"common.of"| translate}} 2 : {{ "assetImport.steps." + step | translate }}</span>
        </div>

    <ng-container content>
        
        <data-upload-form *ngIf="step === 'dataUpload'" [hasModelDefined]="hasModelDefined" [form]="uploadFileForm" [toast]="hasModelDefined ? toast : undefined"/>

        <bind-field-form *ngIf="step === 'fieldsBinding' && (fieldsState | success) as data" [fields]="data" [form]="fieldBindingForm" [toast]="toast"/>

        <import-completed-warnings *ngIf="step === 'completedWithWarnings'" [errors]="errors" [zipFileName]="attachmentControl.value?.name" [csvFileName]="fileControl.value?.name"/>

    </ng-container>

    <div class="footer" footer>
        <button  class="btn-primary" (click)="goToPrevious(steps[step].previous)" [disabled]="isLoading || !steps[step].previous"> {{ "common.back" | translate }}</button>
        <button class="btn-primary" (click)="steps[step].onClick(hasModelDefined)" [disabled]="isFormInvalid() || isLoading">
            <spinner *ngIf="isLoading" size='small'/>
            {{ steps[step].buttonLabel(hasModelDefined) | translate }}
        </button>
    </div>
</modal>