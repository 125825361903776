import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { onFailure, onSuccess } from '@core/models/remote-data/remote-data.model';
import { ClientError } from '@core/services/http/http.service';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { ActionMenuComponent } from '@shared/components/action-menu/action-menu.component';
import { Action } from '@shared/components/action-menu/action.model';
import { CollapsibleComponent } from '@shared/components/collapsible/collapsible.component';
import { ConfirmationModalService } from '@shared/components/confirmation-modal/confirmation-modal.service';
import { InlineInputComponent, InlineInputState } from '@shared/components/inline-input/inline-input.component';
import { CML } from '../../models/cml.model';
import { CmlsService, CMLUpdateError } from '../../services/cmls.service';

@Component({
  selector: 'cml',
  standalone: true,
  templateUrl: './cml.component.html',
  styleUrls: ['./cml.component.scss'],
  imports: [CommonModule, TranslateModule, CollapsibleComponent, ActionMenuComponent, InlineInputComponent],
})
export class CMLComponent implements OnInit {
  public inputStates: InlineInputState[] = [];
  public inspectionPointControl: FormArray<FormControl<string | null>>;
  public actions: Action[] = [
    {
      title: 'ultrasonic.addInspectionPoint',
      callback: () => this.addInspectionPoint(),
      iconName: 'add',
    },
    {
      title: 'ultrasonic.removeCML',
      callback: () => this.deleteCML.emit(),
      iconName: 'delete',
    },
  ];

  @ViewChild(CollapsibleComponent) public cmlCollapsibleContainer!: CollapsibleComponent;

  @Input() public cml: CML;
  @Input() public assetId: string;
  @Input() public sectionId: string;
  @Input() public isExpanded = false;
  @Output() public readonly expanded = new EventEmitter<boolean>();
  @Output() public readonly deleteCML = new EventEmitter();

  constructor(
    private cmlsService: CmlsService,
    private toastService: ToastService,
    private confirmationDialog: ConfirmationModalService) {}

  public ngOnInit(): void {
    this.inspectionPointControl = new FormArray<FormControl<string | null>>([]);
  }

  public onConfirmInspectionPoint(index: number, value: string) {
    this.inputStates[index] = 'loading';

    const updatedCML = {
      inspectionPoints: [value, ...this.cml.inspectionPoints],
    };

    this.cmlsService
      .editCML(this.assetId, this.sectionId, this.cml.id, updatedCML)
      .pipe(onFailure((e: ClientError) => {
        if (e.errorType === CMLUpdateError.DuplicateInspectionPoint) {
          this.inputStates[index] = 'error';
        } else {
          this.toastService.add(Toast.error('ultrasonic.error.addInspectionPoint'));
          this.inputStates[index] = 'idle';
        }
      },
      ))
      .pipe(onSuccess((cml) => {
        this.cml = cml;
        this.inputStates[index] = 'idle';
        this.inspectionPointControl.removeAt(index);
      }))
      .subscribe();
  }

  private addInspectionPoint() {
    this.inspectionPointControl.push(new FormControl(''));
    this.cmlCollapsibleContainer.open();
  }

  public onRemove(index: number) {
    this.inspectionPointControl.removeAt(index);
    this.inputStates[index] = 'idle';
  }

  public isEmpty() {
    return this.inspectionPointControl.controls.length === 0 && this.cml.inspectionPoints.length === 0;
  }

  public onToggle() {
    this.expanded.emit(this.cmlCollapsibleContainer.collapsed);
  }

  public confirmDeleteInspectionPoint(point: string) {
    this.confirmationDialog.open({
      title: 'ultrasonic.deleteInspectionPointConfirmation.title',
      message: 'ultrasonic.deleteInspectionPointConfirmation.message',
      messageParameters: { point },
      onConfirm: () => this.deleteInspectionPoint(point),
    });
  }

  private deleteInspectionPoint(point: string) {
    const inspectionPoints = this.cml.inspectionPoints.filter(ip => ip !== point);

    this.confirmationDialog.loading();
    this.cmlsService
      .editCML(this.assetId, this.sectionId, this.cml.id, { inspectionPoints })
      .pipe(onSuccess(cml => this.cml = cml))
      .pipe(onFailure(() => {
        this.toastService.add(Toast.error('ultrasonic.error.updateCMLFailed'));
      }))
      .subscribe(() => {
        this.confirmationDialog.close();
      });
  }
}
