<side-panel
    [title]="'modelCreation.title' | translate"
    [onClose]="onClose"
    [loadingOverlay]="isLoading"
    >
    <form [formGroup]="form" (ngSubmit)="onSubmit()" id="asset-model-form" content >
        <input-field
        [id]="'asset-model-name'"
        [control]="assetModelName"
        [label]="'common.modelName' | translate"
        [placeholder]="'modelCreation.modelNamePlaceholder' | translate"
        />

      <div>
        <h2>{{"modelCreation.mandatorySubtitle" | translate }}</h2>
        <div *ngFor="let item of requiredFields;" class="required-field">
          <span class="mandatory-field-name">{{ item }}</span>
          <span class="mandatory-warning">{{ "modelCreation.mandatoryWarning" | translate }}</span>
        </div>
      </div>

      <h2>{{"modelCreation.customFieldsSubtitle" | translate}}</h2>
        <div class="add-field-container">
            <input-field
                class="input-field"
                [id]="'asset-field'"
                [control]="newAssetModelField"
                [placeholder]="'modelCreation.newFieldPlaceholder' | translate"
                (keyDown)="handleKeyboardEvents($event)"
                />

            <button [disabled]="newAssetModelField.value.trim() === ''" class="btn-primary icon-button add-field-btn" type="button" (click)="onAdd()">
              <icon iconName='add'/>
                {{'modelCreation.addField' | translate}}
            </button>
        </div>
        <inline-toast *ngIf="toast" [toast]="toast"/>

        <div class="scrollable" formArrayName="assetModelFields">
            <div *ngFor="let item of assetModelFields.controls; let i=index" class="field-item">
                <span>{{ item.value?.name }}</span>
              <span (click)="onDelete(i)" class="remove-field">{{"modelCreation.remove" | translate}}</span>
            </div>
        </div>
    </form>

    <div footer class="theme-footer">
        <button class="btn-text-only" (click)="onClose()">{{ "common.cancel" | translate }}</button>
        <button class="btn-primary" type="submit" [attr.form]="'asset-model-form'" [disabled]="form.invalid || isLoading">
            <spinner size="small" *ngIf="isLoading"></spinner>
            {{ "common.save" | translate }}
        </button>
    </div>
</side-panel>
