import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

type Status = 'InProgress' | 'Completed';

@Component({
  selector: 'status-indicator',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
})
export class StatusIndicatorComponent {
  @Input() public status: Status;
}
