import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Loading, mapSuccess, match, onFailure, onSuccess, RemoteData } from '@core/models/remote-data/remote-data.model';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyStateMessageComponent } from '@shared/components/empty-state-message/empty-state-message.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { empty, FailureRemoteDataPipe, LoadingRemoteDataPipe, notEmpty, SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { Observable, of } from 'rxjs';
import { InspectionHistoryItem } from '@core/models/inspections/inspection.model';
import { InspectionResultViewerComponent } from '../../../inspection-result-viewer/inspection-result-viewer.component';
import { AssetStateService } from '../../services/asset-state.service';
import { InspectionHistoryStateService } from '../services/inspection-history.state.service';
import { InspectionCardComponent } from './inspection-card/inspection-card.component';
import { getHistorySectionsByInspectionList } from './inspection-sections';
import { InspectionCardHeaderComponent } from './inspection-card-header/inspection-card-header.component';
import { ConfirmationModalService } from '../../../shared/components/confirmation-modal/confirmation-modal.service';

type InspectionSections = {
  latestResult: InspectionHistoryItem[],
  others: InspectionHistoryItem[]
};

@Component({
  selector: 'inspection-history',
  standalone: true,
  templateUrl: './inspection-history.component.html',
  styleUrls: ['./inspection-history.component.scss'],
  imports: [CommonModule, TranslateModule,
    SuccessRemoteDataPipe, LoadingRemoteDataPipe, empty,
    notEmpty, SpinnerComponent, FailureRemoteDataPipe, InspectionCardComponent,
    EmptyStateMessageComponent, InspectionResultViewerComponent, InspectionCardHeaderComponent],
})
export class InspectionHistoryComponent implements OnInit {
  @Input() public assetId: string;
  public equipmentId = of('');
  @ViewChild(InspectionResultViewerComponent) public inspectionResultViewer!: InspectionResultViewerComponent;

  public activeItem: InspectionHistoryItem | undefined = undefined;
  public inspectionHistory$: Observable<RemoteData<InspectionSections>> = of(Loading());

  constructor(private inspectionService: InspectionHistoryStateService,
    private toastService: ToastService,
    private confirmationModal: ConfirmationModalService,
    private assetState: AssetStateService,
  ) {
    this.equipmentId = this.assetState.asset$.pipe(match(a => a.equipmentId, () => ''));
    this.inspectionHistory$ = this.inspectionService
      .inspectionHistory$
      .pipe(onFailure(() => this.toastService.add(Toast.error('inspectionHistory.error.getHistory'))))
      .pipe(mapSuccess(getHistorySectionsByInspectionList));
  }

  public notEmpty(sections: InspectionSections) {
    return sections.latestResult.length === 0 && sections.others.length === 0;
  }

  public onViewResult(inspectionHistoryItem: InspectionHistoryItem) {
    this.activeItem = inspectionHistoryItem;
    this.inspectionResultViewer.viewResult(inspectionHistoryItem);
  };

  public resetActiveItem() {
    this.activeItem = undefined;
  }

  public ngOnInit(): void {
    this.inspectionService
      .fetchAndUpdate(this.assetId);
  }

  public onDeleteInspectionTask(assetId: string, inspectionId: string, inspectionTaskId: string) {
    this.confirmationModal.loading();

    this.inspectionService.deleteInspectionTask(assetId, inspectionId, inspectionTaskId)
      .pipe(onFailure(_ => this.toastService.add(Toast.error('inspectionHistory.deleteInspectionTask.error'))))
      .pipe(onSuccess(_ => this.toastService.add(Toast.success('inspectionHistory.deleteInspectionTask.success'))))
      .subscribe(_ => this.confirmationModal.close());
  };
}
