<div [ngClass]="{overlay: !inline}" [@modalAnimation]>
    <div class="modalcontainer" [ngClass]="classes"[style]="customSizeStyle">
        <div *ngIf="isLoading" class="loading-overlay"></div>

        <div class="header">
            <ng-content select="[header]"></ng-content>
            <div (click)="onClose()" class="close">
                <icon iconName='close'/>
            </div>
        </div>
        <div class="splitter" *ngIf="withSplitter"></div>
        <div class="content">
            <ng-content select="[content]" ></ng-content>
        </div>
        <ng-content select="[footer]"></ng-content>
    </div>
</div>
