import { AssetModelCreationForm } from '../../assets-list-page/components/asset-model-creation/asset-model-creation.component';
import { AssetModelCreationRequest } from '../models/assetModel/asset-model-creation.model';
import { AssetModel } from '../models/assetModel/asset-model.model';
import { AssetModelResponse } from './asset-model-response';

const toRequest = (
  asset: AssetModelCreationForm,
  unitName: string,
  equipmentId: string,
  equipmentType: string): AssetModelCreationRequest => ({
  modelName: asset.assetModelName,
  assetModelFields: [unitName, equipmentId, equipmentType, ...asset.assetModelFields.map(_ => _.name)],
  unitName,
  equipmentId,
  equipmentType,
});

const fromResponse = (model: AssetModelResponse): AssetModel => ({ ...model });

export const AssetModelCreationMapper = {
  toRequest,
  fromResponse,
};
