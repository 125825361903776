<div class="container">
    <h3>{{ "vtResult.report" | translate }}</h3>
    <span *ngIf="!reportProgress" class="empty-attachments-message">{{ "vtResult.emptyReportMessage" | translate }}</span>
    <attachment *ngIf="reportProgress" #files [attachment]="reportProgress" [actions]="['download']" [last]="true"/>

    <div class="divider-bar"></div>

    <h3>{{ "vtResult.pictures" | translate }}</h3>
    <span *ngIf="pictureProgress.length === 0" class="empty-attachments-message">{{ "vtResult.emptyPictureMessage" | translate }}</span>
    <attachment *ngFor="let attachment of pictureProgress; let isLast = last" [last]="isLast" #files [attachment]="attachment" [actions]="['download']" />
</div>