import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from '../dropdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from '../../icon/icon.component';

@Component({
  selector: 'breadcrumb-select',
  standalone: true,
  templateUrl: './breadcrumb-select.component.html',
  styleUrls: ['./breadcrumb-select.component.scss'],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, IconComponent, FormsModule],
})
export class BreadcrumbSelectComponent<T> extends DropdownComponent<T> {
  constructor() {
    super();
  }
}
