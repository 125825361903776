<div class="content">
    <div class="left-panel" >
        <tab-group (activeTab)="onActiveTabChange($event)">
            <tab tabName="assetConfigurationPage.leftPanelTabs.files" class="tab" [tabId]="'files'" [class.active]="activeTabId === 'files'">
                <section-attachment [assetId]="assetId" [sectionId]="sectionId" />
            </tab>
            <tab tabName="assetConfigurationPage.leftPanelTabs.ultrasonic" class="tab" [tabId]="'utt'" [class.active]="activeTabId === 'utt'">
                <ultrasonic-thickness [assetId]="assetId" [sectionId]="sectionId"/>
            </tab>
        </tab-group>
    </div>

    <drawing class="drawing-viewer"/>
</div>
