import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { HttpService } from '@core/services/http/http.service';
import { ConfigService } from '@shared/services';
import { match } from '../../models/remote-data/remote-data.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly authPrefix: string = `${this.configService.oscUrl}/user`;
  private readonly profilePrefix: string = `${this.configService.oscUrl}/myaccount/api/users`;

  constructor(
    private readonly httpService: HttpService,
    private readonly configService: ConfigService,
  ) {}

  public isUserLogged(): Observable<boolean> {
    if (this.configService.isLocalMode) {
      return of(true);
    }

    return this.httpService
      .get<boolean>({ endpoint: `${this.authPrefix}/isAuthenticated` })
      .pipe(match((isLogged) => isLogged, () => false));
  }

  public authenticate(): void {
    if (this.configService.isLocalMode) {
      return;
    }

    window.location.href = `${this.configService.oscUrl}/login?callbackUrl=${encodeURIComponent(window.location.href)}`;
  }

  public logout(): void {
    this.configService.clear();

    // eslint-disable-next-line max-len
    window.location.href = `${this.configService.oscUrl}/logout?callbackUrl=${this.configService.oscUrl}/login?callbackUrl=${encodeURIComponent(window.location.href)}`;
  }
}
