import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Loading, onFailure, onSuccess, RemoteData } from '@core/models/remote-data/remote-data.model';
import { Section } from '@core/models/sections/section.model';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyStateMessageComponent } from '@shared/components/empty-state-message/empty-state-message.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { FailureRemoteDataPipe, LoadingRemoteDataPipe, SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { Observable, of } from 'rxjs';
import { IconComponent } from '@shared/components/icon/icon.component';
import { InlineInputComponent, InlineInputState } from '@shared/components/inline-input/inline-input.component';
import { PanelComponent } from '@shared/components/panel/panel.component';
import { SectionsStateServiceService } from '../../services/sections-state-service.service';
import { CreateSectionError } from '../../services/sections.service';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';

@Component({
  selector: 'sections-configuration',
  standalone: true,
  templateUrl: './sections-configuration.component.html',
  styleUrls: ['./sections-configuration.component.scss'],
  imports: [CommonModule, FailureRemoteDataPipe, TranslateModule,
    LoadingRemoteDataPipe, SuccessRemoteDataPipe, EmptyStateMessageComponent,
    SpinnerComponent, IconComponent, InlineInputComponent, PanelComponent],
})
export class SectionsConfigurationComponent implements OnInit {
  public sectionsControls = new FormControl<string>('');

  @Input() public assetId: string;

  public sections$: Observable<RemoteData<Section[]>> = of(Loading());
  public sectionsState: InlineInputState | null = null;

  constructor(private sectionsStateService: SectionsStateServiceService, private toastService: ToastService) {
    this.sections$ = this.sectionsStateService.sections$;
  }

  public ngOnInit(): void {
    this.sectionsStateService.fetchAndUpdate(this.assetId);
  }

  public addSection() {
    this.sectionsControls = new FormControl('');
    this.sectionsState = 'idle';
  }

  public onConfirmSection(value: string) {
    this.sectionsState = 'loading';
    this.sectionsStateService
      .addSection(value, this.assetId)
      .pipe(onFailure(e => this.onFailure(e)))
      .pipe(onSuccess(_ => this.removeSectionControl()))
      .subscribe();
  }

  private onFailure(e: CreateSectionError) {
    if (e === CreateSectionError.Unknown) {
      this.toastService.add(Toast.error('sectionsConfiguration.error.unknown'));
      this.sectionsState = 'idle';
    } else {
      this.sectionsState = 'error';
    }
  }

  public removeSectionControl(): void {
    this.sectionsControls = new FormControl<string>('');
    this.sectionsState = null;
  }
}
