import { Pipe, PipeTransform } from '@angular/core';
import { Convert, DEFAULT_NUMBER_OF_DECIMAL, Measure, UnitSystem } from '@core/units/units';
import { UserPreferenceService } from '../../services/user-preferences/user-preference.service';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'measure',
  standalone: true,
})
export class MeasureConverterPipe implements PipeTransform {
  constructor(private userPreference: UserPreferenceService) {}

  public transform(metricMeasure: Measure | undefined, numberOfDecimal: number = DEFAULT_NUMBER_OF_DECIMAL): Observable<string> {
    const convert = (unitSystem: UnitSystem) => {
      if (!metricMeasure) {
        return '';
      }

      const convertedMeasure = unitSystem === UnitSystem.IMPERIAL
        ? Convert.mmToIn(metricMeasure)
        : metricMeasure;

      return convertedMeasure.value.toFixed(numberOfDecimal);
    };

    return this.userPreference
      .watchUnitSystem()
      .pipe(map(unit => convert(unit)));
  }
}
