import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

type ButtonProps = {
  callback: () => void,
  label: string
};

@Component({
  selector: 'empty-state-message',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  template: `
  <h1>{{  title | translate }}</h1>
  <span>{{ subTitle | translate }}</span>
  <button  *ngIf="buttonProps" class="btn-primary" (click)="buttonProps.callback()">{{ buttonProps.label | translate }}</button>
  `,
  styleUrls: ['./empty-state-message.component.scss'],
})
export class EmptyStateMessageComponent {
  @Input() public title: string;
  @Input() public subTitle: string;
  @Input() public buttonProps?: ButtonProps;
}
