import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'cell-renderer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cell-renderer.component.html',
  styleUrls: ['./cell-renderer.component.scss'],
})
export class CellRendererComponent implements ICellRendererAngularComp {
  public value: string | null | undefined = null;
  public isLoading = true;

  public refresh(params: ICellRendererParams<unknown, string, unknown>): boolean {
    this.value = params.value;
    this.isLoading = params.data === undefined;

    return true;
  }

  public agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.isLoading = params.data === undefined;
  }
}
