import { CustomField } from '../asset/custom-field.model';

export type AssetModel = {
  id: string,
  modelName: string,
  assetModelFields: AssetModelField[],
  equipmentId: string,
  equipmentType: string,
  unitName: string
};

export type AssetModelField = { name: string, id: string };

export const AssetModel = (model: AssetModel) => ({
  isRequiredField: (name: string) => AssetModel(model).requiredModelFields.includes(name),

  requiredModelFields: [model.equipmentId, model.equipmentType, model.unitName],

  notRequiredModelFields: () => model.assetModelFields.filter(f => !AssetModel(model).isRequiredField(f.name)),

  notRequiredFields: (customFields: CustomField[]) => customFields.filter(f => !AssetModel(model).isRequiredField(f.name)),
});

export const EQUIPMENT_TYPE = 'Equipment Type';
export const EQUIPMENT_ID = 'Equipment ID';
export const UNIT_NAME = 'Unit Name';
