import { Measure } from '../../core/units/units';
import { InspectionPointResult, UttInspectionResult } from '../models/utt-inspection-result.model';
import { VtInspectionResult } from '../models/vt-inspection-result.model';
import { InspectionPointResultResponse, UttInspectionResultResponse } from './inspection-result.response';

export const toVtResultDomain = (result: VtInspectionResult) => {
  if (!result.pdfReport) {
    return result;
  }

  return { pdfReport: { ...result.pdfReport, lastModifiedDate: new Date(result.pdfReport.lastModifiedDate) },
    attachments: result.attachments.map(picture => ({ ...picture, lastModifiedDate: new Date(picture.lastModifiedDate) })) };
};

export const toUttInspectionResult = (response: UttInspectionResultResponse): UttInspectionResult => {
  const cmls = response.cmls.map(cml => (
    { ...cml, inspectionPoints: cml.inspectionPoints.map(inspectionPointToDomain) }));

  return <UttInspectionResult> { ...response, cmls };
};

const inspectionPointToDomain = (i: InspectionPointResultResponse): InspectionPointResult => ({
  ...i,
  currentMeasurement: Measure(i.currentMeasurement, 'mm'),
  previousMeasurement: i.previousMeasurement ? Measure(i.previousMeasurement, 'mm'): undefined,
  delta: i.delta ? Measure(i.delta, 'mm') : undefined,
  shortTermCorrosionRate: i.shortTermCorrosionRate ? Measure(i.shortTermCorrosionRate, 'mm'): undefined,
});
