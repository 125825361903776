<right-side-panel (closePanel)="onClose()">
  <div header class="header">
    <h2>{{inspectionTask.name}}</h2>
  </div>
  <div content class="content" #content>
    <tab-group class="tab-group" (activeTab)="onActiveTabChange($event)">
      <tab [tabName]="'vtResult.report'" [tabId]="reportTabId">
        <spinner *ngIf="pdfSrc$ | loading" class="spinner"/>
        <div *ngIf="vtInspectionResult$ | success as vtResult">
          <div class="canvas-container" *ngIf="pdfSrc$ | success as pdfSrc">
            <app-pdf-viewer
              [pdfSrc]="pdfSrc"
              [pageNumber]="currentPage"
              (PdfDocumentLoad)="onDocumentLoad($event)"
            />
          </div>
          <empty-state-message *ngIf="(pdfSrc$ | success) === undefined"
                               [title]="'uttResult.noReport.title'"
                               [subTitle]="'uttResult.noReport.subMessage'"
                               class="empty-state"
          />

        </div>
      </tab>
      <tab [tabName]="'vtResult.files'" [tabId]="'pictures'">
        <vt-result-files *ngIf="vtInspectionResult$ | success as vtResult" [attachments]="vtResult.attachments" [report]="vtResult.pdfReport"/>
      </tab>
    </tab-group>
  </div>
  <div footer>
    <pagination *ngIf="showPagination()" [pageCount]="pageCount" [currentPage]="currentPage" (pageChange)="onPageChange($event)"/>
  </div>
</right-side-panel>
