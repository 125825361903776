import { Injectable } from '@angular/core';
import { mapFailure, onSuccess, RemoteData } from '@core/models/remote-data/remote-data.model';
import { createLocalDownloadLink } from '@core/services/file/download-file';
import { HttpMethod, HttpService } from '@core/services/http/http.service';
import { ConfigService } from '@shared/services';
import { Observable } from 'rxjs';

export enum ExportPCMSDataError {
  InvalidPCMSData = 'InvalidPCMSData',
  Unknown = 'Unknown',
  NoAssetToExport = 'NoAssetToExport',
}

@Injectable({
  providedIn: 'root',
})
export class ExportPCMSDataService {
  private exportEndpoint = 'api/pcms/export';
  constructor(private httpService: HttpService, private config: ConfigService) {}

  public exportData(fileName: string): Observable<RemoteData<unknown, ExportPCMSDataError>> {
    return this.httpService.download(HttpMethod.get, `${this.config.apiUrl}/${this.exportEndpoint}`)
      .pipe(
        onSuccess(res => {
          const blob = new Blob([res], { type: 'application/zip' });
          createLocalDownloadLink(blob, fileName);
        }),
        mapFailure(e => {
          switch (e.errorType) {
            case 'InvalidPcmsDataException':
              return ExportPCMSDataError.InvalidPCMSData;
            case 'NotFoundException':
              return ExportPCMSDataError.NoAssetToExport;
            default:
              return ExportPCMSDataError.Unknown;
          }
        }));
  }
}
