import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { match } from '@core/models/remote-data/remote-data.model';
import { Section } from '@core/models/sections/section.model';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BreadcrumbSelectComponent } from '@shared/components/selects/breadcrumb-select/breadcrumb-select.component';
import { Option, SelectFieldComponent } from '@shared/components/selects/select-field/select-field.component';
import { filter, map, Observable, of } from 'rxjs';
import { AssetStateService } from '../services/asset-state.service';
import { SectionsStateServiceService as SectionsStateService } from '../services/sections-state-service.service';

@Component({
  selector: 'asset-page-header',
  standalone: true,
  templateUrl: './asset-page-header.component.html',
  styleUrls: ['./asset-page-header.component.scss'],
  imports: [CommonModule,
    IconComponent, TranslateModule, SelectFieldComponent,
    BreadcrumbSelectComponent],
})
export class AssetPageHeaderComponent implements OnInit {
  @Input({ required: true }) public assetId: string;
  @Input({ required: true }) public currentPage: string;
  @Input() public pages: string[] = [];
  @Output() public readonly sectionSelected = new EventEmitter<Section | null>();
  @Output() public readonly pageChange = new EventEmitter<string>();

  public equipmentId$: Observable<string | null> = of(null);
  public sectionControl = new FormControl<Section | null>(null);
  public sectionOptions: Observable<Option<Section | null>[]> = of([]);

  constructor(
    private assetState: AssetStateService,
    private sectionsService: SectionsStateService,
  ) {
    this.equipmentId$ = this.assetState.asset$
      .pipe(
        match((a) => a.equipmentId, () => null),
        filter(value => value !== null),
      );

    this.sectionOptions = this.sectionsService.sections$
      .pipe(match(s => s, () => []))
      .pipe(map(sections => sections.map(section => ({ label: section.name, value: section }))));
  }

  public ngOnInit(): void {
    this.sectionsService.fetchAndUpdate(this.assetId);
  }

  public goToAsset() {
    this.sectionControl.patchValue(null);
    this.sectionSelected.emit(null);
  }

  public isActive(page: string) {
    return page === this.currentPage;
  }

  public navigateTo(page: string) {
    this.pageChange.emit(page);
  }
}
