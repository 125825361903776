import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, map, Observable, of, Subject } from 'rxjs';
import { AssetModelField } from '@core/models/assetModel/asset-model.model';
import { AssetModelStateService } from '@core/services/assetModel/asset-model-state.service';
import { FieldFilter } from '@core/models/filters.type';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Option, SelectFieldComponent } from '@shared/components/selects/select-field/select-field.component';
import { match } from '@core/models/remote-data/remote-data.model';
import { FilterStateService } from '../../services/filters-state.service';

@Component({
  selector: 'assets-filter-bar',
  standalone: true,
  templateUrl: './assets-filter-bar.component.html',
  styleUrls: ['./assets-filter-bar.component.scss'],
  imports: [CommonModule, FormsModule, IconComponent, TranslateModule, ModalComponent, SelectFieldComponent, ReactiveFormsModule],
})
export class AssetsFilterBarComponent {
  public searchValue: string = '';
  private searchSubject = new Subject<string>();

  public addFieldFiltersModal = false;

  public filterInputValue = '';
  public filterKeywords: string[] = [];
  public selectControlField = new FormControl('');
  public options$: Observable<Option<string>[]> = of([]);

  public fieldsFilters$: Observable<FieldFilter[]> = of([]);

  constructor(
    private filtersState: FilterStateService,
    private modelState: AssetModelStateService,
  ) {
    this.options$ = this.modelState.state$
      .pipe(
        match(model => model.assetModelFields
          .map((fields: AssetModelField) => ({ label: fields.name, value: fields.name })), () => []),
      );

    this.fieldsFilters$ = this.filtersState.state$.pipe(map(filters => filters.fields ?? []));

    this.searchSubject
      .pipe(debounceTime(300))
      .subscribe((query) => {
        this.filtersState.updateFilters({ query });
      });
  }

  public onSearchChange(query: string) {
    this.searchSubject.next(query);
  }

  public onFilterChange(query: string) {
    this.filterInputValue = query;
  }

  public onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !!this.filterInputValue.trim()) {
      this.filterKeywords = [...this.filterKeywords, this.filterInputValue];
      this.filterInputValue = '';
    }
  }

  public toggleFilterModal() {
    this.addFieldFiltersModal = !this.addFieldFiltersModal;
  }

  private resetState() {
    this.filterKeywords = [];
    this.selectControlField.reset();
    this.filterInputValue = '';
  }

  public onCancel() {
    this.toggleFilterModal();
    this.resetState();
  }

  public onSave() {
    if (this.selectControlField.value && this.filterKeywords.length > 0) {
      this.filtersState.addFieldFilters({ fieldName: this.selectControlField.value, keywords: this.filterKeywords });
      this.toggleFilterModal();
      this.resetState();
    }
  }

  public removeFieldFilter(field: FieldFilter) {
    this.filtersState.remoteFieldFilters(field.fieldName);
  }

  public removeKeyword(keyword: string) {
    this.filterKeywords = this.filterKeywords.filter(k => k !== keyword);
  }

  public hasActiveFieldFilters(filters: Observable<FieldFilter[]>) {
    return filters.pipe(map(f => f.length > 0));
  }

  public clearAllFieldsFilter() {
    this.filtersState.updateFilters({ fields: [] });
  }
}
