import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { ConfigService } from '@shared/services';
import { FileProgress } from '@core/services/file/file-progress';
import { catchError, Observable, of } from 'rxjs';
import { map, scan, tap } from 'rxjs/operators';
import { Failure, Loading, RemoteData, Success } from '@core/models/remote-data/remote-data.model';
import { HttpEventType } from '@angular/common/http';
import { createLocalDownloadLink } from './download-file';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  private attachmentsEndpoint = 'api/v1/attachments';

  constructor(private httpClient: HttpService, private config: ConfigService) {}

  public downloadFile(filePath: string): Observable<RemoteData<string>> {
    return this.httpClient
      .downloadWithProgress(`${this.config.apiUrl}/${this.attachmentsEndpoint}/download`, { filePath })
      .pipe(map(state => {
        if (state.type === HttpEventType.Response && state.body instanceof Blob) {
          return Success(URL.createObjectURL(state.body));
        } else {
          return Loading();
        }
      }))
      .pipe(catchError(_ => of(Failure(FileProgress.FAILURE))));
  }

  public downloadFileLocally(filePath: string, fileName: string): Observable<FileProgress<unknown>> {
    return this.httpClient
      .downloadWithProgress(`${this.config.apiUrl}/${this.attachmentsEndpoint}/download`, { filePath })
      .pipe(scan(FileProgress.calculateFileProgress, FileProgress.IN_PROGRESS(0)))
      .pipe(catchError(_ => of(FileProgress.FAILURE)))
      .pipe(tap(result => {
        if (result.state === 'COMPLETED' && result.body instanceof Blob) {
          createLocalDownloadLink(result.body, fileName);
        }
      }));
  }
}
