import { InspectionTask } from '../../models/create-inspection-multiple.model';
import { InspectionTaskRequest, Utt, Vt } from '@core/services/inspections/inspection-request.model';
import { notNull } from '@core/maybe-model';
import { InspectedSection } from '@core/models/inspections/inspection.model';

export const toInspectionTaskRequest = (inspectionTasks: Map<InspectedSection, InspectionTask>): InspectionTaskRequest[] => {
  const result: InspectionTaskRequest[] = [];
  inspectionTasks.forEach((value, key) => {
    result.push(
      { assetId: key.assetId, sectionId: key.sectionId, inspectionTypes: [value.utt ? Utt : null, value.vt ? Vt : null].filter(notNull) });
  },
  );

  return result;
};
