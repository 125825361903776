<div class="toasts-container">
    <div 
    [@fade] 
    *ngFor="let toast of toastService.toasts$ | async; trackBy:trackBy" 
    class="toast" 
    [ngClass]="toast.type"
    > 
        <icon [iconName]="iconName(toast)" [size]="'small'"></icon>
        <span> {{toast.message | translate }} </span>
        <button type="button" class="clear"(click)="toastService.clear(toast)">
            <icon iconName='close' class="icon" [size]="'xsmall'"/>
        </button>
    </div>
</div>