<spinner *ngIf="$pdfSrc  | loading" class="spinner"/>

<div *ngIf="$pdfSrc | failure as pdfFailure" class="empty-drawing">
  <div *ngIf="pdfFailure === 'unknown' ; else unsupportedDrawing">
    <empty-state-message
      [title]="'drawing.emptyState.title'"
      [subTitle]="'drawing.emptyState.message'"
    />
  </div>
  <ng-template #unsupportedDrawing>
    <empty-state-message
    [title]="'drawing.noPdf.title'"
    [subTitle]="'drawing.noPdf.message'"
    />
  </ng-template>
</div>


<div class="viewer-section" *ngIf="$pdfSrc | success as pdfSrc">
  <div class="dropzone">
    <app-pdf-viewer
      [pdfSrc]="pdfSrc"
      [pageNumber]="currentPage"
      (PdfDocumentLoad)="onDocumentLoad($event)"
    ></app-pdf-viewer>
  </div>

 <pagination [pageCount]="pageCount" [currentPage]="currentPage" (pageChange)="onPageChange($event)"/>
</div>
