import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AssetFormComponent } from '../../../asset-form/asset-form.component';
import { AssetForm } from '../../../asset-form/asset-form.model';
import { AssetModel } from '@core/models/assetModel/asset-model.model';
import { AssetModelStateService } from '@core/services/assetModel/asset-model-state.service';
import { AssetModelError } from '@core/services/assetModel/asset-model.service';
import { AssetListStateService } from '../../services/asset-list-state.service';
import { AssetCreationError, AssetsService } from '@core/services/assets/assets.service';
import { InputFieldComponent } from '@shared/components/input-field/input-field.component';
import { SidePanelComponent } from '@shared/components/side-panel/side-panel.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { isSuccess, onFailure, onSuccess, RemoteData } from '@core/models/remote-data/remote-data.model';
import { SuccessRemoteDataPipe } from '@shared/pipes/remote-data.pipe';
import { Toast, ToastService } from '@core/services/toast/toast-service.service';
import { toCreationRequest } from './asset-creation.mapper';
import { AssetCreation } from '@core/models/asset/asset.model';
import { ToastInlineComponent } from '../../../shared/components/toast/inline-toast.component';

@Component({
  selector: 'asset-creation',
  standalone: true,
  templateUrl: './asset-creation.component.html',
  styleUrls: ['./asset-creation.component.scss'],
  imports: [CommonModule, SidePanelComponent, TranslateModule, ReactiveFormsModule, FormsModule,
    InputFieldComponent, SuccessRemoteDataPipe, SpinnerComponent, AssetFormComponent, ToastInlineComponent],
})
export class AssetCreationComponent implements OnDestroy {
  @Input() public onClose: () => void = () => {};

  public form: AssetForm = [];
  public model$: RemoteData<AssetModel, AssetModelError>;
  public subscription: Subscription;
  public isLoading = false;

  constructor(
    private assetModelState: AssetModelStateService,
    private assetListStateService: AssetListStateService,
    private assetService: AssetsService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
  ) {
    this.subscription = this.assetModelState
      .state$
      .subscribe(model => {
        if (isSuccess(model)) {
          this.form = AssetForm.buildCreationForm(model.data, this.formBuilder);
        }
        this.model$ = model;
      });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  };

  public onSubmit(model: AssetModel) {
    this.isLoading = true;

    const request: AssetCreation = toCreationRequest(this.form, model);

    this.assetService
      .createAsset(request)
      .pipe(
        onSuccess(() => this.onSuccess()),
        onFailure((e) => this.onFailure(e, model)),
      )
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  public get isInvalid() {
    return AssetForm.isInvalid(this.form);
  }

  public errorToast() {
    return Toast.error('assetCreation.error.duplicate');
  }

  public hasDuplicateError(model: AssetModel) {
    return this.form.find(c => c.controlName === model.equipmentId)?.control.hasError('duplicate');
  }

  private onSuccess() {
    this.toastService.add(Toast.success('assetCreation.success'));
    this.assetListStateService.fetchAndUpdate();
    this.onClose();
  }

  private onFailure(e: AssetCreationError, model: AssetModel) {
    if (e === 'DuplicateAsset') {
      this.form.find(c => c.controlName === model.equipmentId)?.control.setErrors({ duplicate: true });
      this.form.find(c => c.controlName === model.unitName)?.control.setErrors({ duplicate: true });
    }

    if (e === 'Unknown') {
      this.toastService.add(Toast.error('assetCreation.error.unknown'));
    }
  }
}
