import { Injectable } from '@angular/core';
import { andThen, EmptySuccess, Failure, mapFailure, RemoteData } from '@core/models/remote-data/remote-data.model';
import { ClientError, HttpService } from '@core/services/http/http.service';
import { ConfigService } from '@shared/services';
import { Observable, of } from 'rxjs';
import { JobId, JobResponse } from '@core/services/job-status/job';
import { JobStatusService } from '@core/services/job-status/job-status.service';
import { PcmsFiles } from '../import-pcms-validation-state';

export enum ErrorCode {
  AssetNotFound = 'AssetNotFound',
  SectionNotFound = 'SectionNotFound',
  DuplicateAsset = 'DuplicateAsset',
  MissingRequiredFields = 'MissingRequiredFields',
  InvalidModel = 'InvalidModel',
  Unknown = 'Unknown',
  InvalidAttachmentException = 'InvalidAttachmentException',
}
export type PCMSDataValidationError = { file?: PcmsFiles, errorCode: ErrorCode };

type ImportPcmsDataPayload = {
  equipmentsCSV: File | null,
  cmlsCSV: File | null,
  circuitsCSV: File | null,
  attachmentsZip?: string,
  modelName?: string,
};

@Injectable({
  providedIn: 'root',
})
export class ImportPcmsService {
  private importPcmsEndpoint = 'api/pcms/import';

  private assetsDataKey = 'AssetsDataFile';
  private cmlsDataKey = 'CmlsDataFile';
  private circuitDataKey = 'CircuitsDataFile';
  private modelNameKey = 'modelName';
  private attachmentsFileNameKey = 'ZipName';

  constructor(private httpClient: HttpService, private config: ConfigService, private jobService: JobStatusService) { }

  public importData(importDataPayload: ImportPcmsDataPayload): Observable<RemoteData<unknown, PCMSDataValidationError>> {
    const elements = [
      { data: importDataPayload.cmlsCSV, key: this.cmlsDataKey },
      { data: importDataPayload.equipmentsCSV, key: this.assetsDataKey },
      { data: importDataPayload.circuitsCSV, key: this.circuitDataKey },
      { data: importDataPayload.attachmentsZip, key: this.attachmentsFileNameKey },
      { data: importDataPayload.modelName, key: this.modelNameKey },
    ];

    const formData = new FormData();
    elements.forEach(el => appendIfNotNull(formData)(el.data, el.key));

    return this.httpClient.post<JobId, FormData>({
      endpoint: `${this.config.apiUrl}/${this.importPcmsEndpoint}`,
      body: formData,
    }).pipe(
      andThen(jobId => this.jobService.poll(jobId)),
      mapFailure(e => handleValidationError(e)),
      andThen(jobResponse => of(this.handleJobResponse(jobResponse))),
    );
  }

  private handleJobResponse(jobResponse: JobResponse): RemoteData<unknown, PCMSDataValidationError> {
    const toErrorCode = (e?: string) => e === ErrorCode.InvalidAttachmentException
      ? ErrorCode.InvalidAttachmentException : ErrorCode.Unknown;

    return jobResponse.status === 'Error'
      ? Failure({ errorCode: toErrorCode(jobResponse.errors?.[0]?.errorType), file: 'Attachments' })
      : EmptySuccess(); ;
  }
}

const appendIfNotNull = (formData: FormData) => (el: string | File | undefined | null, key: string) => {
  if (el !== undefined && el !== null) {
    formData.append(key, el);
  }
};

function handleValidationError(e: ClientError): PCMSDataValidationError {
  switch (e.errorType) {
    case 'EquipmentValidationException':
      return { file: 'Equipments', errorCode: parseToErrorCode(e.message) };

    case 'CircuitValidationException':
      return { file: 'Circuits', errorCode: parseToErrorCode(e.message) };

    case 'CmlsValidationException':
      return { file: 'Cmls', errorCode: parseToErrorCode(e.message) };

    case 'InvalidAttachmentException':
      return { file: 'Attachments', errorCode: ErrorCode.InvalidAttachmentException };
    default:
      return { errorCode: ErrorCode.Unknown };
  }
}

function parseToErrorCode(error: string = 'unknown'): ErrorCode {
  if (error in ErrorCode) {
    return ErrorCode[error as keyof typeof ErrorCode];
  } else {
    return ErrorCode.Unknown;
  }
}
